import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import PaymentCC from "../components/PaymentCC";
import PaymentCod from "../components/PaymentCod";
import PaymentTf from "../components/PaymentTf";
import PaymentVA from "../components/PaymentVA";
import { API } from "../helpers/api";
import "./Page.css";

interface PaymentProps
  extends RouteComponentProps<{ user: string; trx: string; method: string }> {}

const Payment: React.FC<PaymentProps> = ({ match, history }) => {
  const [method, setMethod] = useState<any>(null);
  const [trx, setTrx] = useState<any>(null);
  const cancel = () => {
    history.goBack();
  };
  const getTrx = (user: any, trx: any) => {
    axios.get(`${API}/trx/${user}/${trx}`).then((res) => {
      setTrx(res.data.header);
    });
  };
  useIonViewWillEnter(() => {
    setMethod(null);
    getTrx(match.params.user, match.params.trx);
  });

  useIonViewDidEnter(() => {
    if (match.params.method === "tf") {
      setMethod("Bank Transfer");
    } else if (match.params.method === "cod") {
      setMethod("COD");
    } else if (match.params.method === "cc") {
      setMethod("Kartu Kredit");
    } else if (match.params.method === "va") {
      setMethod("Virtual Account");
    }
  }, [match.params.method]);

  return (
    <IonPage id="payment">
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {trx && method ? (
                <>
                  <IonListHeader>
                    <IonLabel>{method}</IonLabel>
                    <IonButton color="danger" onClick={cancel}>
                      Batal
                    </IonButton>
                  </IonListHeader>
                  {match.params.method === "tf" ? (
                    <PaymentTf info={trx} onCancel={cancel} />
                  ) : match.params.method === "cod" ? (
                    <PaymentCod info={trx} onCancel={cancel} />
                  ) : match.params.method === "cc" ? (
                    <PaymentCC info={trx} onCancel={cancel} history={history} />
                  ) : match.params.method === "va" ? (
                    <PaymentVA info={trx} onCancel={cancel} />
                  ) : (
                    <span>Payment Unknown</span>
                  )}
                </>
              ) : (
                <>
                  <IonListHeader>
                    <IonLabel>
                      <IonSkeletonText animated style={{ width: "30%" }} />
                    </IonLabel>
                    <IonText slot="end">
                      <IonSkeletonText animated />
                    </IonText>
                  </IonListHeader>
                  <IonSkeletonText
                    animated
                    style={{ width: "100%", height: "300px" }}
                  />
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default memo(Payment);
