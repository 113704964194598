import { RefresherEventDetail } from "@ionic/core";
import {
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonText,
} from "@ionic/react";
import axios from "axios";
import { chevronDownOutline, documentText } from "ionicons/icons";
import React, { memo, useEffect, useState } from "react";
import SkeletonList from "../../components/SkeletonList";
import { API } from "../../helpers/api";

const ListTransaction = (props: any) => {
  const [loading, setLoading] = useState<any>(true);
  const [transaction, setTransaction] = useState<any>([]);
  const [page, setPage] = useState<number>(1);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
    false
  );
  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");
  const status = props.status ? `&status=${String(props.status)}` : "";

  const getTransaction = async (_page: number) => {
    _page === 1 && setLoading(true);
    await axios
      .get(`${API}/transaction/${user_id}?limit=10&page=${_page}${status}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res) => {
        if (_page === 1) {
          setTransaction(res.data);
        } else {
          setTransaction([...transaction, ...res.data]);
        }
        setLoading(false);
        setDisableInfiniteScroll(res.data.length < 10);
        setPage(_page + 1);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const onRefresh2 = (event: CustomEvent<RefresherEventDetail>) => {
    setLoading(true);
    getTransaction(1)
      .then(() => event.detail.complete())
      .catch(() => event.detail.complete());
  };

  const onRefresh = () => {
    setLoading(true);
    getTransaction(1);
  };

  async function searchNext($event: CustomEvent<void>) {
    await getTransaction(page);

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  useEffect(() => {
    onRefresh();
  }, [status]);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh2}>
        <IonRefresherContent
          pullingIcon={chevronDownOutline}
          pullingText="Pull to refresh"
          refreshingSpinner="lines"
        ></IonRefresherContent>
      </IonRefresher>
      {loading ? (
        <SkeletonList number={10} />
      ) : transaction.length > 0 ? (
        <>
          {transaction.map((i: any) => (
            <IonList lines="full" key={i.salesid}>
              <IonItem routerLink={"/transaction/" + user_id + "/" + i.trxno}>
                <IonIcon slot="start" icon={documentText} />
                <IonLabel>
                  <div>#{i.trxno}</div>
                  <small>Status: {i.orderdate}</small>
                </IonLabel>
                <span
                  slot="end"
                  style={{
                    fontSize: 12,
                    color: i.status_color,
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  {i.status}
                </span>
              </IonItem>
            </IonList>
          ))}
          <IonInfiniteScroll
            threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
          >
            <IonInfiniteScrollContent
              loadingSpinner="lines"
              loadingText="Loading more products"
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
      ) : (
        <div
          style={{
            justifyContent: "center",
            flex: 1,
            textAlign: "center",
            color: "#252158",
            fontWeight: "bold",
          }}
        >
          <IonImg
            src={require("../../assets/images/nodata.png")}
            style={{
              resizeMode: "contain",
            }}
          />
          <IonText
            style={{
              fontSize: 17,
            }}
          >
            Belum ada data
          </IonText>
        </div>
      )}
    </>
  );
};
export default memo(ListTransaction);
