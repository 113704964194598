import { IonButton, IonCard, IonCardContent, IonItem } from "@ionic/react";
import React, { memo, useState } from "react";
import "../Comps.css";

interface GridProps {
  info: any;
}

const TextLong: React.FC<GridProps> = ({ info }) => {
  const [expand, setExpand] = useState<boolean>(false);
  return (
    <div
      style={{
        backgroundColor: info.color1,
        paddingLeft: 8,
      }}
    >
      <IonCard className="m-0 no-shadow">
        <IonCardContent style={{ maxHeight: expand ? 9999 : 160 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: info && info.api,
            }}
          />
          <div
            className="fade"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 20,
            }}
          />
        </IonCardContent>
        <IonItem>
          <IonButton
            fill="outline"
            slot="end"
            onClick={() => setExpand(!expand)}
          >
            Show {expand ? "Less" : "More"}
          </IonButton>
        </IonItem>
      </IonCard>
    </div>
  );
};

export default memo(TextLong);
