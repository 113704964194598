import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import axios from "axios";
import React, { memo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import NumberFormat from "react-number-format";
import "./Comps.css";

interface Props {
  info: any;
  onCancel: any;
}
const PaymentTf: React.FC<Props> = ({ info, onCancel }) => {
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  const Confirm = (trx: any) => {
    setProcessing(true);
    axios
      .post(`https://api.harnic.id/trx/payWithTF/${trx}`)
      .then((res) => {
        onCancel();
        setToastMsg(res.data.message);
        setProcessing(false);
        setShowToast(true);
      })
      .catch((e) => {
        setToastMsg(e);
        setProcessing(false);
        setShowToast(true);
      });
  };
  return (
    <>
      <IonCard>
        <IonCardContent className="ion-text-center">
          <div>Silahkan lakukan transfer ke:</div>
          <div style={{ margin: 16 }}>
            <IonCardTitle>
              39 7978 9999
              <CopyToClipboard
                text="3979789999"
                onCopy={() => {
                  setToastMsg("Disalin ke clipboard");
                  setShowToast(true);
                }}
              >
                <IonBadge color="success" style={{ marginLeft: 4 }}>
                  SALIN
                </IonBadge>
              </CopyToClipboard>
            </IonCardTitle>
            <small>a/n PT. HARNIC ONLINE STORE</small>
          </div>
          <div style={{ margin: 16 }}>
            <small>Dengan nominal:</small>
            <IonCardTitle>
              <NumberFormat
                value={info.total}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp. "}
              />
              <CopyToClipboard
                text={info.total}
                onCopy={() => {
                  setToastMsg("Disalin ke clipboard");
                  setShowToast(true);
                }}
              >
                <IonBadge color="success" style={{ marginLeft: 4 }}>
                  SALIN
                </IonBadge>
              </CopyToClipboard>
            </IonCardTitle>
          </div>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardContent className="ion-text-center">
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Tips!</div>
          <div style={{ margin: 16 }}>
            <small>
              Masukkan kode berikut di berita untuk verifikasi cepat
            </small>
            <IonCardTitle>
              {info.trxno}
              <CopyToClipboard
                text={info.trxno}
                onCopy={() => {
                  setToastMsg("Disalin ke clipboard");
                  setShowToast(true);
                }}
              >
                <IonBadge color="success" style={{ marginLeft: 4 }}>
                  SALIN
                </IonBadge>
              </CopyToClipboard>
            </IonCardTitle>
          </div>
        </IonCardContent>
      </IonCard>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <div style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 4 }}>
        <IonButton
          disabled={processing}
          expand="block"
          onClick={() => {
            Confirm(info.trxno);
          }}
        >
          {processing ? <IonSpinner name="dots" /> : "KONFIRMASI"}
        </IonButton>
      </div>
    </>
  );
};

export default memo(PaymentTf);
