import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import React, { memo } from "react";
import "./Comps.css";

const Loading = () => {
  return (
    <IonContent>
      <IonGrid className="p-0 m-0">
        <IonRow className="p-0 m-0">
          <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
          <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
            <div
              style={{
                justifyContent: "center",
                flex: 1,
                flexDirection: "column",
                display: "flex",
                height: "100vh",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <IonSpinner name="circular" color="primary" />
              <IonText style={{ fontSize: 11 }}>Mengambil Data</IonText>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default memo(Loading);
