import {
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRippleEffect,
  IonRow,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import React, { memo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { RouteComponentProps } from "react-router";
import YouTube from "react-youtube";
import { API } from "../helpers/api";
import "./Page.css";

interface ArticleProps extends RouteComponentProps<{ id: any }> {}
declare const window: any;
const Article: React.FC<ArticleProps> = ({ match, history }) => {
  const [hdr, setHdr] = useState<any>(null);
  const [items, setItems] = useState<any>(null);

  const getArticle = (id: any) => {
    axios.get(`${API}/article/get2/${id}`).then((res) => {
      setHdr(res.data.hdr);
      setItems(res.data.items);
    });
  };
  const sendToRN = (type: any, id: any) => {
    let param = {
      type: type,
      id: id,
    };
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(param));
    } else {
      window.location.href = "https://harnic.id/product/details/" + id;
    }
  };

  useIonViewWillEnter(() => {
    getArticle(match.params.id);
  });

  const _onReady = (event: any) => {
    event.target.pauseVideo();
  };
  return (
    <IonPage id="payment">
      <IonContent>
        <YouTube
          videoId={hdr && hdr.article_video}
          opts={{
            height: "250px",
            width: "100%",
            playerVars: {
              autoplay: 1,
            },
          }}
          onReady={_onReady}
        />
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <IonCardTitle>{hdr && hdr.article_title}</IonCardTitle>
              <IonCardSubtitle>{hdr && hdr.created_at}</IonCardSubtitle>
              <span
                dangerouslySetInnerHTML={{ __html: hdr && hdr.article_body }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        {items && items.length > 0 && (
          <>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <IonCardTitle>Produk Dalam Video</IonCardTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid>
              <IonRow>
                {items &&
                  items.map((i: any) => (
                    <IonCol key={i.itemid} size="4" size-sm>
                      <div className="ion-activatable ripple-parent">
                        <IonCard
                          className="m-2"
                          onClick={() => sendToRN("goToDetail", i.itemid)}
                        >
                          <IonRippleEffect></IonRippleEffect>
                          <LazyLoadImage
                            placeholder={<Skeleton width="100%" height={150} />}
                            src={i.pic_url}
                            width="100%"
                            height={undefined}
                          />
                          <div className="product-caption">
                            <p className="product-name">{i.online_name}</p>
                            <span className="price-regular">
                              <NumberFormat
                                value={i.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rp. "}
                              />
                            </span>
                          </div>
                        </IonCard>
                      </div>
                    </IonCol>
                  ))}
              </IonRow>
            </IonGrid>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default memo(Article);
