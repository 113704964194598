import { RefresherEventDetail } from "@ionic/core";
import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonPage,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSkeletonText,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { arrowUpOutline, chevronDownOutline } from "ionicons/icons";
import React, { lazy, Suspense, useRef, useState } from "react";
import { Img } from "react-image";
import {
    LazyLoadComponent,
    trackWindowScroll,
} from "react-lazy-load-image-component";
import ItemTabList from "../components/url/ItemTabList";
import TextLong from "../components/url/TextLong";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

const ItemGrid = lazy(() => import("../components/url/ItemGrid"));
const ItemSlide = lazy(() => import("../components/url/ItemSlide"));
const CategoryGrid = lazy(() => import("../components/url/CategoryGrid"));
const Carousel = lazy(() => import("../components/url/Carousel"));
const Title = lazy(() => import("../components/Title"));
const Countdown = lazy(() => import("../components/Countdown"));
const ItemHorizontalFlashsale = lazy(
    () => import("../components/url/ItemHorizontalFlashsale")
);
const More = lazy(() => import("../components/More"));
const More2 = lazy(() => import("../components/More2"));
const ArticleSlide = lazy(() => import("../components/ArticleSlide"));
const ItemInfinite = lazy(() => import("../components/ItemInfinite"));
interface PageProps {
    match: any;
    history: any;
    scrollPosition: any;
}
declare const window: any;

const Url: React.FC<PageProps> = ({ match, history, scrollPosition }) => {
    const [loading, setLoading] = useState<any>(true);
    const contentRef = useRef(null);
    const scrollToTop = () => {
        // @ts-ignore
        contentRef.current.scrollToTop("300");
    };
    const scrollTo = (e: any) => {
        let offset = Number(e) - 130;

        // @ts-ignore
        contentRef.current.scrollToPoint(0, offset, 500);
    };
    const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
    const [comps, setComps] = useState<any>([]);

    function onRefresh(event: CustomEvent<RefresherEventDetail>) {
        getComps(match.params.url).then(() => event.detail.complete());
    }

    const getComps = async (url: any) => {
        setLoading(true);
        await axios
            .get(`${API}/page/url2/${url}`)
            .then((response) => response.data)
            .then((data) => {
                setLoading(false);
                setComps(data);
            })
            .catch(() => setLoading(false));
    };

    const contentOrigin = (url: any) => {
        if (url.includes("https://assets.harnicid.com/page_content/")) {
            let file = url.replace(
                "https://assets.harnicid.com/page_content/",
                ""
            );
            let origin = file.replace(".webp", "");
            return `https://assets.harnicid.com/page_content/jpg/${origin}`;
        } else {
            return url;
        }
    };

    const sendToRN = (type: any, id: any) => {
        let param = {
            type: type,
            id: id,
        };

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(param));
        } else {
            switch (type) {
                case "goToDetail":
                    history.push("/product/" + id);
                    break;
                case "goToSearch":
                    history.push("/resultsearch/category/" + id);
                    break;
                case "goToBrand":
                    history.push("/resultsearch/brand/" + id);
                    break;
                default:
                    break;
            }
        }
    };

    useIonViewWillEnter(() => {
        getComps(match.params.url);
    }, [match.params.url]);

    return (
        <IonPage id="url">
            <IonHeader translucent={true}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent ref={contentRef} scrollEvents={true} fullscreen>
                {loading && <IonProgressBar type="indeterminate" />}
                <IonLoading
                    cssClass="my-custom-class"
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={"Please wait..."}
                    duration={5000}
                />
                <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
                    <IonRefresherContent
                        pullingIcon={chevronDownOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="lines"
                    ></IonRefresherContent>
                </IonRefresher>
                <IonGrid className="p-0 m-0">
                    <IonRow className="p-0 m-0">
                        <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
                        <IonCol
                            sizeLg="8"
                            className="p-0 m-0 justify-content-center "
                        >
                            {comps.map((i: any) => (
                                <LazyLoadComponent key={i.row_id}>
                                    <Suspense
                                        fallback={
                                            <IonSkeletonText
                                                animated
                                                style={{ height: 300 }}
                                            />
                                        }
                                    >
                                        {i.component_type === "ITEM_GRID3" ? (
                                            <ItemGrid
                                                col={4}
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "ITEM_GRID2" ? (
                                            <ItemGrid
                                                col={6}
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "ITEM_HORIZONTAL_FLASHSALE" ? (
                                            <ItemHorizontalFlashsale
                                                info={i}
                                                reverse={false}
                                            />
                                        ) : i.component_type ===
                                          "ITEM_HORIZONTAL_FLASHSALE_REVERSE" ? (
                                            <ItemHorizontalFlashsale
                                                info={i}
                                                reverse={true}
                                            />
                                        ) : i.component_type ===
                                          "ITEM_INFINITE" ? (
                                            <ItemInfinite
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "ITEM_SLIDE" ? (
                                            <ItemSlide
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "ARTICLE_SLIDE" ? (
                                            <ArticleSlide
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "CATEGORIES_GRID4" ? (
                                            <CategoryGrid
                                                col={3}
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "CATEGORIES_GRID2" ? (
                                            <CategoryGrid
                                                col={6}
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "CATEGORIES_GRID3" ? (
                                            <CategoryGrid
                                                col={4}
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type ===
                                          "CATEGORIES_GRID5" ? (
                                            <CategoryGrid
                                                col={2.4}
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type === "BANNER" ? (
                                            <Img
                                                onClick={() =>
                                                    sendToRN(
                                                        i.gotoscreen ===
                                                            "product"
                                                            ? "goToDetail"
                                                            : i.gotoscreen ===
                                                              "category"
                                                            ? "goToSearch"
                                                            : i.gotoscreen ===
                                                              "brand"
                                                            ? "goToBrand"
                                                            : "",
                                                        i.api2
                                                    )
                                                }
                                                width="100%"
                                                src={
                                                    isChrome
                                                        ? i.api
                                                        : contentOrigin(i.api)
                                                }
                                                loader={
                                                    <IonSkeletonText
                                                        animated
                                                        className="ratio-41"
                                                    />
                                                }
                                                unloader={
                                                    <img
                                                        src={require("../assets/images/default-banner.png")}
                                                        alt="no-pic"
                                                        width="100%"
                                                    />
                                                }
                                            />
                                        ) : i.component_type === "CAROUSEL" ? (
                                            <Carousel
                                                info={i}
                                                history={history}
                                            />
                                        ) : i.component_type === "TITLE" ? (
                                            <Title info={i} />
                                        ) : i.component_type === "MORE" ? (
                                            <More info={i} history={history} />
                                        ) : i.component_type === "MORE2" ? (
                                            <More2 info={i} history={history} />
                                        ) : i.component_type === "COUNTDOWN" ? (
                                            <Countdown info={i} />
                                        ) : i.component_type === "TEXT_LONG" ? (
                                            <TextLong info={i} />
                                        ) : i.component_type ===
                                          "ITEM_TAB_LIST" ? (
                                            <ItemTabList
                                                info={i}
                                                scrollTo={(e: any) =>
                                                    scrollTo(e)
                                                }
                                            />
                                        ) : (
                                            <span></span>
                                        )}
                                    </Suspense>
                                </LazyLoadComponent>
                            ))}
                        </IonCol>
                        <IonCol sizeLg="3" sizeXs="auto" className="p-0 m-0" />
                    </IonRow>
                </IonGrid>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={() => scrollToTop()}>
                        <IonIcon icon={arrowUpOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
            <MainTabs />
        </IonPage>
    );
};

export default trackWindowScroll(Url);
