import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import firebase from "./firebase";
import AddAlamat from "./pages/alamat/AddAlamat";
import EditAlamat from "./pages/alamat/EditAlamat";
import ListAlamat from "./pages/alamat/ListAlamat";
import Article from "./pages/Article";
import Cart from "./pages/Cart";
import DeliverySupport from "./pages/DeliverySupport";
import ResultSearch from "./pages/search/ResultSearchTab";
import ResultSearchBrand from "./pages/search/ResultSearchBrand";
import ForgetPassword from "./pages/ForgetPassword";
// import Home from "./pages/Home";
import Home from "./pages/home/index";
import TransactionTab from "./pages/transaction/TransactionTab";
import Auth from "./pages/auth/index";
//NEW LOGIN
// import Login from "./pages/auth/Login";
//
import Login from "./pages/Login";
import Payment from "./pages/Payment";
import Product from "./pages/Product";
import ProductBuy from "./pages/ProductBuy";
//NEW REGISTER
// import Register from "./pages/auth/Register";
//
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import Search from "./pages/Search";
import Transaction from "./pages/Transaction";
/* Theme Pages */
import Url from "./pages/Url";
import Url2 from "./pages/Url2";
//NEW USER
// import User from "./pages/user/index";
//
import User from "./pages/User2";
import UserDetail from "./pages/UserDetail";
import UserNotification from "./pages/UserNotification";
import UserVoucher from "./pages/UserVoucher";
import UserPassword from "./pages/UserPassword";
import UserShoppingList from "./pages/UserShoppingList";
import VerifyEmail from "./pages/VerifyEmail";
import UserActivation from "./pages/UserActivation";
import ResendActivation from "./pages/ResendActivation";
import UserCreditCard from "./pages/creditcard/ListCreditCard";
import AddCreditCard from "./pages/creditcard/AddCreditCard";
import EditCreditCard from "./pages/creditcard/EditCreditCard";
import ClaimGaransi from "./pages/ClaimGaransi";
import PageContent from "./pages/PageContent";
/* Theme variables */
import "./theme/variables.css";

import { AppProvider } from "./contexts/AppContext";
import AccountDeletionTerm from "./pages/AccountDeletionTerm";

declare const window: any;
const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

if (isChrome) {
  const messaging = firebase.messaging();
  messaging
    .requestPermission()
    .then(() => {
      return messaging.getToken();
    })
    .then((token) => {
      sessionStorage.setItem("token_fcm", token);
    })
    .catch(() => {
      sessionStorage.clear();
    });
}

const App: React.FC = () => (
  <AppProvider>
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/verify/:code" component={VerifyEmail} exact={true} />
          <Route path="/login/" component={Login} exact={true} />
          <Route path="/auth/" component={Auth} exact={true} />
          <Route path="/register/" component={Register} exact={true} />
          <Route path="/url/:url" component={Url} exact={true} />
          <Route path="/url2/:url" component={Url2} exact={true} />
          <Route
            path="/transaction/:user/:trx"
            component={Transaction}
            exact={true}
          />
          <Route
            path="/transaction/:user/:trx/payment/:method"
            component={Payment}
            exact={true}
          />
          <Route path="/cart/" component={Cart} exact={true} />
          <Route path="/home/" component={Home} exact={true} />
          <Route path="/article/:id" component={Article} exact={true} />
          <Route
            path="/resultSearch/:key/:id"
            component={ResultSearch}
            exact={true}
          />
          <Route
            path="/resultsearchbrand/:q/:key/:id"
            component={ResultSearchBrand}
            exact={true}
          />
          <Route path="/product/:id" component={Product} exact={true} />
          <Route path="/search/" component={Search} exact={true} />
          <Route path="/user/" component={User} exact={true} />
          <Route
            path="/user/transactions/:val"
            component={TransactionTab}
            exact={true}
          />
          <Route path="/user/detail/" component={UserDetail} exact={true} />
          <Route path="/user/shippings/" component={ListAlamat} exact={true} />
          <Route
            path="/user/shipping/create/"
            component={AddAlamat}
            exac={true}
          />
          <Route
            path="/user/shipping/edit/:id"
            component={EditAlamat}
            exact={true}
          />
          <Route path="/user/password/" component={UserPassword} exact={true} />
          <Route
            path="/user/notifications/"
            component={UserNotification}
            exact={true}
          />
          <Route path="/user/voucher/" component={UserVoucher} exact={true} />
          <Route
            path="/user/activation/"
            component={UserActivation}
            exact={true}
          />
          <Route
            path="/user/resendactivation/"
            component={ResendActivation}
            exact={true}
          />
          <Route
            path="/user/creditcard/"
            component={UserCreditCard}
            exact={true}
          />
          <Route
            path="/user/creditcard/create"
            component={AddCreditCard}
            exact={true}
          />
          <Route
            path="/user/creditcard/edit/:id"
            component={EditCreditCard}
            exact={true}
          />
          <Route
            path="/user/claimgaransi/"
            component={ClaimGaransi}
            exact={true}
          />
          <Route path="/shoplist/" component={UserShoppingList} exact={true} />
          <Route path="/productbuy/:id" component={ProductBuy} exact={true} />
          <Route
            path="/deliverysupport"
            component={DeliverySupport}
            exact={true}
          />
          <Route
            path="/forgetpassword"
            component={ForgetPassword}
            exact={true}
          />
          <Route
            path="/reset_password/:code"
            component={ResetPassword}
            exact={true}
          />
          <Route path="/page/:title" component={PageContent} exact={true} />
          <Route path="/account_deletion" component={AccountDeletionTerm} exact={true} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  </AppProvider>
);

export default App;
