import {
  IonBadge,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import {
  barcode,
  barcodeOutline,
  cardOutline,
  checkmarkOutline,
  codeOutline,
  location,
  lockClosed,
  notifications,
  person,
  ribbon,
  ribbonOutline,
} from "ionicons/icons";
import React, { memo, useState } from "react";
import * as Icon from "react-feather";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

interface User2Props extends RouteComponentProps<{ props: any }> {}

declare const window: any;

const User2: React.FC<User2Props> = (props: any) => {
  const [user, setUser] = useState<any>("");

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const getUser = async () => {
    await axios
      .get(`${API}/user/${user_id}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res: any) => {
        if (res.data.success !== false) {
          setUser(res.data);
        }
      })
      .catch((err) => console.error(err));
  };

  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  function openInNewTab(url: any) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  useIonViewDidEnter(() => {
    getUser();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="4" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  margin: 16,
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IonImg
                  style={{ position: "absolute", resizeMode: "contain" }}
                  src={require(`../assets/images/art1.png`)}
                />
              </div>
              <IonListHeader>
                <IonLabel>
                  <IonText style={{ fontSize: 20, fontWeight: "600" }}>
                    {(user && user.user_name) || (
                      <IonSkeletonText
                        animated
                        style={{ width: "30%", height: 30 }}
                      />
                    )}
                  </IonText>
                  <br />
                  <IonText style={{ fontSize: 15, fontWeight: "400" }}>
                    {(user && user.user_email) || (user && user.user_phone) || (
                      <IonSkeletonText
                        animated
                        style={{ width: "30%", height: 20 }}
                      />
                    )}
                  </IonText>
                </IonLabel>
                <img
                  onClick={() =>
                    openInNewTab(
                      "https://tawk.to/chat/5d79fce5c22bdd393bb57440/default"
                    )
                  }
                  style={{
                    maxWidth: "25%",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                  src={require(`../assets/images/cs.png`)}
                />
              </IonListHeader>
              <IonGrid
                className="relative"
                style={{
                  marginTop: 20,
                  backgroundColor: "#1100bb",
                }}
              >
                <IonRow>
                  <IonCol>
                    <IonText
                      style={{
                        fontSize: 14,
                        color: "#fff",
                        flex: 1,
                      }}
                    >
                      Pesanan
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <div
                      className="ratio-square"
                      style={{
                        flex: 1,
                        backgroundColor: "#eee",
                        borderRadius: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/user/transactions/0")}
                    >
                      <Icon.Clock style={{ left: "35%", top: "25%" }} />

                      <IonText
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                          top: "60%",
                        }}
                      >
                        Baru
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol>
                    <div
                      className="ratio-square"
                      style={{
                        flex: 1,
                        backgroundColor: "#eee",
                        borderRadius: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/user/transactions/1")}
                    >
                      <Icon.Package style={{ left: "35%", top: "25%" }} />
                      <IonText
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                          top: "60%",
                        }}
                      >
                        Disiapkan
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol>
                    <div
                      className="ratio-square"
                      style={{
                        flex: 1,
                        backgroundColor: "#eee",
                        borderRadius: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/user/transactions/2")}
                    >
                      <Icon.Truck style={{ left: "35%", top: "25%" }} />
                      <IonText
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                          top: "60%",
                        }}
                      >
                        Dikirim
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol>
                    <div
                      className="ratio-square"
                      style={{
                        flex: 1,
                        backgroundColor: "#eee",
                        borderRadius: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/user/transactions/3")}
                    >
                      <Icon.Check style={{ left: "35%", top: "25%" }} />
                      <IonText
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                          top: "60%",
                        }}
                      >
                        Diterima
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      onClick={() => props.history.push("/user/transactions/5")}
                      className="ion-float-end ion-activatable ripple-parent"
                      color="transparent"
                    >
                      <IonIcon
                        style={{ color: "#1100bb", marginTop: -15 }}
                        slot="start"
                        icon={codeOutline}
                      />
                      <IonText style={{ color: "#1100bb", marginTop: -15 }}>
                        {" "}
                        LIHAT SEMUA
                      </IonText>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid
                style={{
                  marginTop: -20,
                  backgroundColor: "#1100bb",
                }}
              >
                <IonRow>
                  <IonCol>
                    <IonText
                      style={{
                        fontSize: 14,
                        color: "#fff",
                        flex: 1,
                      }}
                    >
                      Pengaturan
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonList lines="full" style={{ padding: 8, margin: 6 }}>
                <IonItem routerLink="/user/detail">
                  <IonIcon icon={person} slot="start" />
                  <IonLabel>Data Pengguna</IonLabel>
                </IonItem>
                <IonItem routerLink="/user/password">
                  <IonIcon icon={lockClosed} slot="start" />
                  <IonLabel>Password</IonLabel>
                </IonItem>
                <IonItem routerLink="/user/shippings">
                  <IonIcon icon={location} slot="start" />
                  <IonLabel>Alamat Kirim</IonLabel>
                </IonItem>
                <IonItem routerLink="/user/creditcard">
                  <IonIcon icon={cardOutline} slot="start" />
                  <IonLabel>Kartu Kredit</IonLabel>
                </IonItem>
                <IonItem routerLink="/user/notifications">
                  <IonIcon icon={notifications} slot="start" />
                  <IonLabel>Notifikasi</IonLabel>
                  {user.unread_notification && user.unread_notification > 0 && (
                    <IonBadge
                      style={{ backgroundColor: "orange", color: "fff" }}
                    >
                      {(user && user.unread_notification) || 0}
                    </IonBadge>
                  )}
                </IonItem>
                <IonItem routerLink="/user/voucher">
                  <IonIcon icon={barcode} slot="start" />
                  <IonLabel>Voucher Saya</IonLabel>
                </IonItem>
                <IonItem routerLink="/user/claimgaransi">
                  <IonIcon icon={ribbon} slot="start" />
                  <IonLabel>Klaim Garansi</IonLabel>
                </IonItem>
                <IonItem routerLink="/user/activation">
                  <IonIcon icon={checkmarkOutline} slot="start" />
                  <IonLabel>Aktivasi Akun</IonLabel>
                </IonItem>
              </IonList>
              <div className="ion-padding" style={{ marginTop: 40 }}>
                <IonButton
                  className="fab-h"
                  expand="block"
                  color="danger"
                  onClick={() => {
                    localStorage.setItem("user_id", "");
                    localStorage.setItem("user_token", "");
                    localStorage.clear();
                    window.location.href = "/home";
                    // props.history.replace("home");
                  }}
                >
                  Log Out
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(User2);
