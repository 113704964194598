import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToast,
    IonToolbar,
    IonSkeletonText,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState, lazy, Suspense } from "react";
import { RouteComponentProps } from "react-router";
import OTPInput from "../components/auth/otp";
import { API } from "../helpers/api";
import "./Page.css";

const CountdownPhone = lazy(() => import("../components/CountdownPhone"));

interface LoginProps extends RouteComponentProps<{}> {}

const Login: React.FC<LoginProps> = ({ match, history }) => {
    const [progress, setProgress] = useState<any>(false);
    const [email, setEmail] = useState<any>(null);
    const [password, setPassword] = useState<any>(null);
    const [phone, setPhone] = useState<any>(null);
    const [method, setMethod] = useState<any>("phone");
    const [otp, setOTP] = useState<any>(null);
    const [showOTP, setShowOTP] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<any>(null);
    const [toastMsg, setToastMsg] = useState<any>(null);

    const login = () => {
        setProgress(true);
        axios
            .post(`${API}/login`, qs.stringify({ email, password }), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                },
            })
            .then((res) => {
                setProgress(false);
                if (res.data.success) {
                    localStorage.setItem("user_id", res.data.user_id);
                    localStorage.setItem("user_token", res.data.token);
                    localStorage.setItem("price_type", res.data.price_type);
                    const token_fcm = sessionStorage.getItem("token_fcm");
                    token_fcm &&
                        axios
                            .post(
                                `${API}/user/fcm/${res.data.user_id}`,
                                qs.stringify({ token: token_fcm }),
                                {
                                    headers: {
                                        token: res.data.token,
                                    },
                                }
                            )
                            .then((rush) => {
                                console.log(rush.data.message);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    window.location.href = "/home";
                    setToastMsg("Welcome Back");
                    setShowToast(true);
                    // history.push("/home");
                } else {
                    setToastMsg(res.data.message);
                    setShowToast(true);
                }
            })
            .catch((err) => {
                setProgress(false);
                setToastMsg(err);
                setShowToast(true);
            });
    };
    const loginPhone = () => {
        setProgress(true);
        axios
            .post(`${API}/phone_login`, qs.stringify({ phone, otp }), {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                },
            })
            .then((res) => {
                setProgress(false);
                setShowOTP(false);
                if (res.data.success) {
                    localStorage.setItem("user_id", res.data.user_id);
                    localStorage.setItem("user_token", res.data.token);
                    localStorage.setItem("price_type", res.data.price_type);
                    console.log(res.data.price_type);

                    const token_fcm = localStorage.getItem("token_fcm");
                    token_fcm &&
                        axios
                            .post(
                                `${API}/user/fcm/${res.data.user_id}`,
                                qs.stringify({ token: token_fcm }),
                                {
                                    headers: {
                                        token: res.data.token,
                                    },
                                }
                            )
                            .then((rush) => {
                                console.log(rush.data.message);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    window.location.href = "/home";
                    // history.push("/home");
                    setToastMsg("Welcome Back");
                    setShowToast(true);
                } else {
                    setToastMsg(res.data.message);
                    setShowToast(true);
                }
            })
            .catch((err) => {
                setProgress(false);
                setToastMsg(err);
                setShowToast(true);
            });
    };

    const getOTP = () => {
        setProgress(true);
        if (phone !== "" && phone) {
            axios
                .post(`${API}/is_phone_registered`, qs.stringify({ phone }), {
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                })
                .then((res) => {
                    setProgress(false);
                    if (res.data.success) {
                        axios
                            .post(
                                `${API}/phone_auth_OTP`,
                                qs.stringify({ phone }),
                                {
                                    headers: {
                                        "Content-Type":
                                            "application/x-www-form-urlencoded;charset=UTF-8",
                                    },
                                }
                            )
                            .then((res) => {
                                setShowOTP(true);
                            })
                            .catch((err) => {
                                setToastMsg(err);
                                setShowToast(true);
                            });
                    } else {
                        setToastMsg(res.data.message);
                        setShowToast(true);
                    }
                })
                .catch((err) => {
                    setProgress(false);
                    setToastMsg(err);
                    setShowToast(true);
                });
        } else {
            setProgress(false);
            setToastMsg("Nomor Harus Diisi");
            setShowToast(true);
        }
    };

    // React.useEffect(() => {
    //   getBanks();
    // }, [method]);

    return (
        <IonPage id="login">
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMsg}
                color="dark"
                position="top"
                duration={2000}
            />
            <IonLoading
                cssClass="my-custom-class"
                isOpen={progress}
                onDidDismiss={() => setProgress(false)}
                message={"Please wait..."}
                duration={5000}
            />
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home" />
                    </IonButtons>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className="p-0 m-0">
                    <IonRow className="p-0 m-0">
                        <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
                        <IonCol
                            sizeLg="4"
                            className="p-0 m-0 justify-content-center align-items-center"
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    height: 50,
                                    fontSize: 32,
                                    fontWeight: "bold",
                                    marginTop: 50,
                                }}
                            >
                                Oopps!
                            </div>
                            <div
                                style={{
                                    textAlign: "center",
                                    height: 50,
                                    fontSize: 24,
                                }}
                            >
                                Website sedang maintenance, silahkan gunakan
                                aplikasi untuk sementara
                            </div>
                            <div
                                style={{
                                    justifyContent: "center",
                                    flex: 1,
                                    flexDirection: "row",
                                    textAlign: "center",
                                    color: "#252158",
                                    fontWeight: "bold",
                                    paddingTop: 100,
                                }}
                            >
                                <a href="https://play.google.com/store/apps/details?id=id.harnic.app">
                                    <img
                                        alt="authentication"
                                        src={require("../assets/images/playstore.png")}
                                        width="auto"
                                        height="50px"
                                        style={{
                                            marginLeft: 8,
                                            marginRight: 8,
                                        }}
                                    />
                                </a>
                                <a href="https://apps.apple.com/id/app/harnic-id-toko-online/id1553872222">
                                    <img
                                        alt="authentication"
                                        src={require("../assets/images/appstore.png")}
                                        width="auto"
                                        height="50px"
                                        style={{
                                            marginLeft: 8,
                                            marginRight: 8,
                                        }}
                                    />
                                </a>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            {/* <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="4" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  marginTop: 60,
                  paddingBottom: 16,
                  paddingLeft: 32,
                  paddingRight: 32,
                }}
                className="ion-text-center"
              >
                <img
                  alt="a"
                  width="50%"
                  src={require(`../assets/images/harnic.svg`)}
                />
              </div>
              <IonList lines="full" className="ion-no-margin ion-padding">
                <IonItem lines="none">
                  <IonSegment
                    value={method}
                    onIonChange={(e) => setMethod(e.detail.value)}
                  >
                    <IonSegmentButton value="phone">
                      <IonLabel>Phone</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="email">
                      <IonLabel>Email</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </IonItem>

                {method === "email" ? (
                  <>
                    <IonItem>
                      <IonLabel position="floating">Email</IonLabel>
                      <IonInput
                        placeholder="user@email.com"
                        inputMode="email"
                        value={email}
                        onIonChange={(e) => {
                          setEmail(e.detail.value);
                        }}
                      ></IonInput>
                    </IonItem>

                    <IonItem>
                      <IonLabel position="floating">Password</IonLabel>
                      <IonInput
                        placeholder="********"
                        type="password"
                        value={password}
                        onIonChange={(e) => {
                          setPassword(e.detail.value);
                        }}
                      ></IonInput>
                    </IonItem>
                  </>
                ) : (
                  <IonItem>
                    <IonLabel position="floating">Nomor Handphone</IonLabel>
                    <IonInput
                      type="tel"
                      placeholder="08123 456 768"
                      value={phone}
                      onIonChange={(e) => {
                        setPhone(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                )}
              </IonList>

              <div className="ion-padding">
                {method === "email" ? (
                  <IonButton
                    expand="block"
                    onClick={() => login()}
                    type="submit"
                    className="ion-no-margin"
                  >
                    Masuk
                  </IonButton>
                ) : (
                  <IonButton
                    expand="block"
                    onClick={() => getOTP()}
                    type="submit"
                    className="ion-no-margin"
                  >
                    Kirim OTP
                  </IonButton>
                )}
                <div style={{ height: 16 }} />
                <IonButton
                  color="light"
                  expand="block"
                  onClick={() => history.push("register")}
                  type="submit"
                  className="ion-no-margin"
                >
                  Daftar Baru
                </IonButton>
                {method === "email" && (
                  <>
                    <div style={{ height: 16 }} />
                    <div
                      className="product-caption"
                      style={{ textAlign: "center", fontSize: 12 }}
                    >
                      Lupa Password?{" "}
                      <span
                        onClick={() => {
                          history.push("/forgetpassword");
                        }}
                        style={{ color: "#1100BB" }}
                      >
                        Klik Disini
                      </span>
                    </div>
                  </>
                )}
              </div>
            </IonCol>
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent> */}
            <IonModal isOpen={showOTP} cssClass="my-custom-class">
                <IonListHeader>
                    <IonLabel>OTP Verification</IonLabel>
                    <IonButton color="danger" onClick={() => setShowOTP(false)}>
                        Batal
                    </IonButton>
                </IonListHeader>
                <IonContent style={{ textAlign: "center" }}>
                    <div style={{ marginTop: 60 }}>
                        <small>Masukkan 4 digit kode OTP</small>
                    </div>
                    <OTPInput
                        style={{ textAlign: "center", marginBottom: 32 }}
                        length={4}
                        className="otpContainer"
                        inputClassName="otpInput"
                        isNumberInput
                        autoFocus
                        onChangeOTP={(otp) => setOTP(otp)}
                    />
                    <div style={{ textAlign: "center" }}>
                        <small>Tidak mendapat kode?</small>
                    </div>
                    {/* <IonButton
            // onClick={() => getOTP()}
            className="ion-no-margin"
            color="light"
            size="small"
          >
            Kirim Ulang
          </IonButton> */}
                    <Suspense
                        fallback={
                            <IonSkeletonText animated style={{ height: 300 }} />
                        }
                    >
                        <CountdownPhone action={() => getOTP()} />
                    </Suspense>
                </IonContent>
                <div className="ion-padding">
                    <IonButton
                        expand="block"
                        className="ion-no-margin"
                        onClick={() => loginPhone()}
                    >
                        Belanja Sekarang
                    </IonButton>
                </div>
            </IonModal>
        </IonPage>
    );
};

export default memo(Login);
