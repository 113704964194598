import { RefresherEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSkeletonText,
  IonText,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import { chevronDownOutline } from "ionicons/icons";
import qs from "qs";
import React, { lazy, memo, Suspense, useRef, useState } from "react";
import * as Icon from "react-feather";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

const ProductComponent = lazy(
  () => import("../components/product/ProductComponent")
);
const ProductReview = lazy(() => import("../components/product/ProductReview"));
const ProductDeskripsi = lazy(
  () => import("../components/product/ProductDeskripsi")
);
const ProductRelated = lazy(
  () => import("../components/product/ProductRelated")
);

interface ProductProps extends RouteComponentProps<{ id: any }> {}
declare const window: any;

const Product: React.FC<ProductProps> = ({ match, history }) => {
  const contentRef = useRef(null);

  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const [items, setItems] = useState<any>([]);
  const [media, setMedia] = useState<any>([]);
  const [mediaIOS, setMediaIOS] = useState<any>([]);
  const [variant, setVariant] = useState<any>([]);
  const [related, setRelated] = useState<any>([]);
  const [review, setReview] = useState<any>([]);
  const [productLoading, setProductLoading] = useState<any>(false);
  const limit = 10;
  function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    getItems(match.params.id);
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  const getItems = (id: any) => {
    setProductLoading(true);
    const price_type = localStorage.getItem("price_type");
    let url_type = "";

    if (price_type) {
      url_type = "/" + price_type;
    }

    axios
      .get(`${API}/product/get/${id}${url_type}`)
      .then((response) => response.data)
      .then((data) => {
        setProductLoading(false);
        setItems(data);
        setMedia(data.media);
        setMediaIOS(data.media_ios);
        setVariant(data.variant);
      })
      .catch(() => {
        setProductLoading(false);
      });
  };

  const getRelated = async (id: any) => {
    await axios
      .get(`${API}/product/getrelated/${id}`)
      .then((response) => response.data)
      .then((data) => {
        setRelated(data);
      });
  };

  const getReview = async (id: any) => {
    await axios
      .get(`${API}/product/getreview/${id}/${limit}`)
      .then((response) => response.data)
      .then((data) => {
        setReview(data);
      });
  };

  const AddToCart = (id: any, qty: number, note: any) => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");

    if (user_id && user_token) {
      axios
        .post(
          `${API}/cart/${user_id}`,
          qs.stringify({
            item_id: id,
            qty: qty,
            app_version: 999999999,
            note: note,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: user_token,
            },
          }
        )
        .then((res) => history.push("/cart"))
        .catch((e) => {});
    } else {
      history.push("/login");
    }
  };

  function openInNewTab(url: any) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  useIonViewDidEnter(() => {
    getItems(match.params.id);
    getRelated(match.params.id);
    getReview(match.params.id);
  }, [match.params.id]);

  return (
    <IonPage id="product">
      <IonHeader translucent={true}>
        <IonToolbar style={{ paddingTop: 10 }}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          {/* <IonSearchbar
            searchIcon="search-outline"
            color="light"
            placeholder="Cari Produk"
            onFocus={() => history.push("/search")}
          ></IonSearchbar> */}
          <IonButton
            color="light"
            expand="block"
            onClick={() => history.push("/search")}
            type="submit"
            style={{
              marginLeft: 12,
              marginRight: 12,
              paddingTop: -5,
              paddingBottom: 5,
            }}
            className="ion-no-margin"
          >
            <div
              style={{
                flex: 1,
                textAlign: "left",
              }}
            >
              <IonIcon icon="search-outline" />
              &nbsp;
              <IonText style={{ color: "#A0A1A3" }}>Cari Produk</IonText>
            </div>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={productLoading}
        onDidDismiss={() => setProductLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonContent ref={contentRef} scrollEvents={true} fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="lines"
          ></IonRefresherContent>
        </IonRefresher>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center">
              <Suspense
                fallback={<IonSkeletonText animated style={{ height: 200 }} />}
              >
                <ProductComponent
                  info={items}
                  variant={variant}
                  media={isChrome ? media : mediaIOS}
                  history={history}
                />
              </Suspense>
              <Suspense
                fallback={<IonSkeletonText animated style={{ height: 200 }} />}
              >
                {review.length > 0 && <ProductReview info={review} />}
              </Suspense>
              <Suspense
                fallback={<IonSkeletonText animated style={{ height: 200 }} />}
              >
                <ProductDeskripsi info={items} />
              </Suspense>
              <Suspense
                fallback={<IonSkeletonText animated style={{ height: 200 }} />}
              >
                <ProductRelated info={related} history={history} />
              </Suspense>
            </IonCol>
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent>
      <div slot="fixed">
        <IonGrid className="p-0 m-0">
          <IonRow>
            <IonCol className="p-0 m-0">
              <IonButton
                style={{ margin: 0 }}
                expand="full"
                color="light"
                onClick={() =>
                  openInNewTab(
                    "https://tawk.to/chat/5d79fce5c22bdd393bb57440/default"
                  )
                }
              >
                <Icon.MessageSquare color="gray" />
              </IonButton>
            </IonCol>
            {items.stock_num === "0" ? (
              <>
                <IonCol className="p-0 m-0">
                  <IonButton
                    color="medium"
                    expand="full"
                    className="ion-no-margin"
                  >
                    Stok Habis
                  </IonButton>
                </IonCol>
              </>
            ) : (
              <>
                <IonCol className="p-0 m-0">
                  <IonButton
                    expand="full"
                    onClick={() => AddToCart(items.itemid, 1, "")}
                    className="ion-no-margin"
                  >
                    Beli
                  </IonButton>
                </IonCol>
                <IonCol className="p-0 m-0">
                  <IonButton
                    color="warning"
                    expand="full"
                    onClick={() => history.push("/productbuy/" + items.itemid)}
                    className="ion-no-margin"
                  >
                    Tambah Keranjang
                  </IonButton>
                </IonCol>
              </>
            )}
          </IonRow>
        </IonGrid>
      </div>
      <MainTabs />
    </IonPage>
  );
};

export default memo(Product);
