import { RefresherEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { chatboxOutline, chevronDownOutline } from "ionicons/icons";
import React, { memo, useState } from "react";
import * as Icon from "react-feather";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

const UserNotification = (props: any) => {
  const [notification, setNotification] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);

  const [page, setPage] = useState<number>(1);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
    false
  );

  const getNotification = async (_page: number) => {
    _page === 1 && setLoading(true);
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .get(`${API}/news/${user_id}?limit=10&page=${_page}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (_page === 1) {
          setNotification(res.data);
        } else {
          setNotification([...notification, ...res.data]);
        }
        setLoading(false);
        setDisableInfiniteScroll(res.data.length < 10);
        setPage(_page + 1);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setLoading(true);
    getNotification(1)
      .then(() => event.detail.complete())
      .catch(() => event.detail.complete());
  };

  async function searchNext($event: CustomEvent<void>) {
    await getNotification(page);

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const setNotificationRead = async (id: string) => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios.put(
      `${API}/news/read/${user_id}/${id}`,
      {},
      {
        headers: {
          token: user_token,
        },
      }
    );
  };

  const setReadAll = async () => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .put(
        `${API}/news/readall/${user_id}`,
        {},
        {
          headers: {
            token: user_token,
          },
        }
      )
      .then(() => getNotification(1));
  };

  useIonViewWillEnter(() => {
    getNotification(1);
  }, []);

  const _renderSkeleton = () => {
    const dataMap = [...Array(10)];
    return (
      <>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {dataMap.map((i: any, index: any) => (
                <IonSkeletonText
                  key={index}
                  animated
                  style={{
                    width: "100%",
                    paddingTop: 70,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                />
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };
  const _renderItem = () => {
    return (
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="lines"
          ></IonRefresherContent>
        </IonRefresher>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {notification &&
                notification.map((i: any) => (
                  <IonList lines="full" key={i.news_id}>
                    <IonItem
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setModal(true);
                        setDetail(i);
                        setNotificationRead(i.news_id);
                      }}
                    >
                      <IonIcon slot="start" icon={chatboxOutline} />
                      <IonLabel>
                        <div
                          style={{
                            color: "#555",
                            fontWeight: "bold",
                          }}
                        >
                          <IonText>{i.body}</IonText>
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            fontStyle: "italic",
                            color: "#999",
                          }}
                        >
                          <IonText>{i.body}</IonText>
                        </div>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                ))}
              <IonInfiniteScroll
                threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="lines"
                  loadingText="Loading more notifications"
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal isOpen={modal} onWillDismiss={() => setModal(false)}>
          <IonToolbar style={{ marginBottom: 10 }}>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setModal(false);
                }}
              >
                <IonIcon slot="icon-only" color="grey" name="close" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonCol size="12">
            <div className="ion-activatable ripple-parent">
              <IonCard className="m-2">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          marginBottom: 10,
                        }}
                      >
                        <IonText>
                          {notification.length > 0 && detail.title}
                        </IonText>
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          color: "#555",
                        }}
                      >
                        <IonText>
                          {notification.length > 0 && detail.body}
                        </IonText>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </div>
          </IonCol>
        </IonModal>
      </IonContent>
    );
  };

  const _emptyScreen = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  justifyContent: "center",
                  flex: 1,
                  textAlign: "center",
                  color: "#252158",
                  fontWeight: "bold",
                  paddingTop: 20,
                }}
              >
                <div>
                  <img
                    alt="empty-address"
                    src={require("../assets/images/nodata.png")}
                    width="60%"
                  />
                </div>
                <IonText
                  style={{
                    fontSize: 17,
                  }}
                >
                  Tidak ada notifikasi
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };
  return (
    <IonPage id="usernotification">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Notifikasi</IonTitle>
        </IonToolbar>
      </IonHeader>

      {loading
        ? _renderSkeleton()
        : notification.length > 0
        ? _renderItem()
        : _emptyScreen()}

      {notification.length > 0 && (
        <IonButton
          style={{
            position: "absolute",
            bottom: 60,
            left: "50%",
            right: "5%",
          }}
          className="fab-h"
          onClick={() => setReadAll()}
          color="primary"
        >
          <div>
            <Icon.Check />
          </div>
          <IonText>BACA SEMUA</IonText>
        </IonButton>
      )}
      <MainTabs />
    </IonPage>
  );
};

export default memo(UserNotification);
