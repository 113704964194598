import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
  IonText,
} from "@ionic/react";
import axios from "axios";
import { businessOutline, homeOutline } from "ionicons/icons";
import qs from "qs";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import * as Icon from "react-feather";
import { API } from "../../helpers/api";
import MainTabs from "../../MainTabs";
import "../Page.css";

interface AddAlamatProps extends RouteComponentProps<{ props: any }> {}

const AddAlamat: React.FC<AddAlamatProps> = ({ match, history }) => {
  const [province, setProvince] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [district, setDistrict] = useState<any>([]);
  const [subdistrict, setSubdistrict] = useState<any>([]);
  const [postal, setPostal] = useState<any>([]);
  const [shipping_name, setShipping_name] = useState<any>("Rumah");
  const [shipping_pic_name, setShipping_pic_name] = useState<any>("");
  const [shipping_pic_phone, setShipping_pic_phone] = useState<any>("");
  const [shipping_address, setShipping_address] = useState<any>("");
  const [shipping_city, setShipping_city] = useState("");
  const [shipping_province, setShipping_province] = useState("");
  const [shipping_dis, setShipping_dis] = useState("");
  const [shipping_subdis, setShipping_subdis] = useState("");
  const [shipping_zip, setShipping_zip] = useState("");

  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [progress, setProgress] = useState<any>(false);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const createPengiriman = async () => {
    setProgress(true);
    axios
      .post(
        `${API}/shipping/${user_id}`,
        qs.stringify({
          shipping_name: shipping_name,
          shipping_pic_name: shipping_pic_name,
          shipping_pic_phone: shipping_pic_phone,
          shipping_address: shipping_address,
          shipping_city: shipping_city,
          shipping_province: shipping_province,
          shipping_dis: shipping_dis,
          shipping_subdis: shipping_subdis,
          shipping_zip: shipping_zip,
        }),
        {
          headers: {
            token: user_token,
          },
        }
      )
      .then((res: any) => {
        setToastMsg(res.data.message);
        setShowToast(true);
        history.push("/user/shippings");
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  const getProvince = () => {
    axios.get(`https://api.harnic.id/provinces`).then((res) => {
      setProvince(res.data);
    });
  };

  const getCity = (id: number) => {
    axios.get(`https://api.harnic.id/cities/${id}`).then((res) => {
      setCity(res.data);
    });
  };

  const getDistrict = (id: number) => {
    axios.get(`https://api.harnic.id/districts/${id}`).then((res) => {
      setDistrict(res.data);
    });
  };

  const getSubDistrict = (id: number) => {
    axios.get(`https://api.harnic.id/subdistricts/${id}`).then((res) => {
      setSubdistrict(res.data);
    });
  };

  const getPostals = (id: number) => {
    axios.get(`https://api.harnic.id/postals/${id}`).then((res) => {
      setPostal(res.data);
    });
  };

  useIonViewWillEnter(() => {
    getProvince();
  }, []);

  return (
    <IonPage id="addalamat">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Tambah Alamat</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <IonList>
                <IonListHeader style={{ fontSize: 14 }}>
                  <IonLabel>Nama Tempat</IonLabel>
                </IonListHeader>
                <IonRadioGroup
                  border-radius
                  allow-empty-selection
                  value={shipping_name}
                >
                  <IonItem
                    onClick={() => {
                      setShipping_name("Rumah");
                    }}
                  >
                    <IonIcon slot="start" icon={homeOutline} />
                    <IonLabel>Rumah</IonLabel>
                    <IonRadio
                      onClick={() => {
                        setShipping_name("Rumah");
                      }}
                      slot="end"
                      value="Rumah"
                    />
                  </IonItem>
                  <IonItem
                    onClick={() => {
                      setShipping_name("Kantor");
                    }}
                  >
                    <IonIcon slot="start" icon={businessOutline} />
                    <IonLabel>Kantor</IonLabel>
                    <IonRadio
                      onClick={() => {
                        setShipping_name("Kantor");
                      }}
                      slot="end"
                      value="Kantor"
                    />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
              <IonList>
                <IonListHeader style={{ fontSize: 14 }}>
                  <IonLabel>Detail Alamat</IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonLabel position="floating">Nama Penerima</IonLabel>
                  <IonInput
                    type="text"
                    value={shipping_pic_name}
                    onIonChange={(e) => {
                      setShipping_pic_name(e.detail.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Nomor HP Penerima</IonLabel>
                  <IonInput
                    type="number"
                    value={shipping_pic_phone}
                    onIonChange={(e) => {
                      setShipping_pic_phone(e.detail.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">
                    Alamat Lengkap, Jalan, Gang, RT/RW
                  </IonLabel>
                  <IonInput
                    type="text"
                    value={shipping_address}
                    onIonChange={(e) => {
                      setShipping_address(e.detail.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Provinsi"
                    interface="action-sheet"
                    value={shipping_province}
                    onIonChange={(e) => {
                      setShipping_province(e.detail.value);
                      getCity(e.detail.value);
                    }}
                  >
                    {province.map((data: any) => (
                      <IonSelectOption value={data.prov_id}>
                        {data.prov_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kabupaten"
                    interface="action-sheet"
                    value={shipping_city}
                    onIonChange={(e) => {
                      setShipping_city(e.detail.value);
                      getDistrict(e.detail.value);
                    }}
                  >
                    {city.map((data: any) => (
                      <IonSelectOption value={data.city_id}>
                        {data.city_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kecamatan"
                    interface="action-sheet"
                    value={shipping_dis}
                    onIonChange={(e) => {
                      setShipping_dis(e.detail.value);
                      getSubDistrict(e.detail.value);
                    }}
                  >
                    {district.map((data: any) => (
                      <IonSelectOption value={data.dis_id}>
                        {data.dis_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kelurahan"
                    interface="action-sheet"
                    value={shipping_subdis}
                    onIonChange={(e) => {
                      setShipping_subdis(e.detail.value);
                      getPostals(e.detail.value);
                    }}
                  >
                    {subdistrict.map((data: any) => (
                      <IonSelectOption value={data.subdis_id}>
                        {data.subdis_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kode Pos"
                    interface="action-sheet"
                    value={shipping_zip}
                    onIonChange={(e) => {
                      setShipping_zip(e.detail.value);
                    }}
                  >
                    {postal.map((data: any) => (
                      <IonSelectOption value={data.postal_id}>
                        {data.postal_code}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonList>
              <div className="ion-padding">
                <IonButton
                  disabled={
                    shipping_name === "" ||
                    shipping_pic_name === "" ||
                    shipping_pic_phone === "" ||
                    shipping_address === "" ||
                    shipping_city === "" ||
                    shipping_province === "" ||
                    shipping_dis === "" ||
                    shipping_subdis === "" ||
                    shipping_zip === ""
                  }
                  expand="block"
                  onClick={() => createPengiriman()}
                  type="submit"
                  className="fab-h"
                >
                  <Icon.Save />
                  &nbsp;&nbsp;
                  <IonText>SIMPAN</IonText>
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(AddAlamat);
