import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import React, { memo } from "react";
import { RouteComponentProps } from "react-router";
import MainTabs from "../MainTabs";
import "./Page.css";

interface ResendActivationProps extends RouteComponentProps<{ props: any }> {}

const ResendActivation: React.FC<ResendActivationProps> = ({
  match,
  history,
}) => {
  return (
    <IonPage id="resendactivation">
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <>
                <div style={{ textAlign: "center", marginTop: 30 }}>
                  <IonIcon
                    style={{
                      color: "#01A51E",
                      fontSize: 100,
                      marginHorizontal: 5,
                    }}
                    icon={checkmarkCircleOutline}
                  />
                </div>
                <div style={{ textAlign: "center", marginTop: 10 }}>
                  <IonText style={{ fontSize: 25, marginHorizontal: 5 }}>
                    Email Telah Dikirim
                  </IonText>
                </div>
                <div style={{ textAlign: "center", marginTop: 10 }}>
                  <IonText style={{ fontSize: 15, marginHorizontal: 5 }}>
                    Anda dapat menutup halaman ini.
                  </IonText>
                </div>
                <div style={{ textAlign: "center", marginTop: 10 }}>
                  <IonText style={{ fontSize: 16, marginHorizontal: 5 }}>
                    Mohon cek inbox secara berkala atau folder SPAM jika anda
                    tidak menerima email
                  </IonText>
                </div>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(ResendActivation);
