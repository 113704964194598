import React from 'react';

const containerStyle: React.CSSProperties = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  fontFamily: 'Arial, sans-serif',
};

const headingStyle: React.CSSProperties = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '10px',
};

const emailLinkStyle: React.CSSProperties = {
  color: 'blue',
};

function AccountDeletionTerm() {
  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Terms and Conditions - Account Deletion</h1>

      <p>
        At HARNIC.ID, we value your privacy and provide you with the option to delete your account and associated data. Please read the following terms and conditions for account deletion:
      </p>

      <h2 style={headingStyle}>Account Deletion Procedure</h2>
      <p>To request the deletion of your HARNIC.ID account, please follow these steps:</p>
      <ol>
        <li>Send an email to <a style={emailLinkStyle} href="mailto:dev@harnic.id">dev@harnic.id</a> from the registered email address associated with your HARNIC.ID account.</li>
        <li>In the subject line, please clearly state "Account Deletion Request."</li>
        <li>In the body of the email, include your full name and username associated with your HARNIC.ID account.</li>
        <li>We may require additional verification for security purposes.</li>
      </ol>

      <h2 style={headingStyle}>Data Deletion</h2>
      <p>Upon receiving your account deletion request, we will take the following actions:</p>
      <ul>
        <li>Delete all personal data associated with your account, except for data that we are required to retain for legal or administrative purposes.</li>
        <li>We will retain non-personal data for analytical and reporting purposes but will not link it to your deleted account.</li>
      </ul>

      <h2 style={headingStyle}>Retention Period</h2>
      <p>We may retain certain data for a specified retention period as required by applicable laws and regulations. After the retention period expires, we will securely delete this data.</p>

      <h2 style={headingStyle}>Contact Us</h2>
      <p>If you have any questions or concerns about account deletion or our privacy practices, please contact us at <a style={emailLinkStyle} href="mailto:dev@harnic.id">dev@harnic.id</a>.</p>

      <p>By requesting the deletion of your HARNIC.ID account, you agree to abide by these terms and conditions.</p>
    </div>
  );
}

export default AccountDeletionTerm;
