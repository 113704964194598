export const contentOrigin = (url: any) => {
  if (url.includes("https://assets.harnicid.com/page_content/")) {
    let file = url.replace("https://assets.harnicid.com/page_content/", "");
    let origin = file.replace(".webp", "");
    return `https://assets.harnicid.com/page_content/jpg/${origin}`;
  }
  if (url.includes("https://assets.harnicid.com/slide/")) {
    let file = url.replace("https://assets.harnicid.com/slide/", "");
    let origin = file.replace(".webp", "");
    return `https://assets.harnicid.com/slide/jpg/${origin}`;
  } else {
    return url;
  }
};
