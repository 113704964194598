import React, { memo } from "react";
import "./Comps.css";
import "./Scroll.css";

const ProgressBar = (props: any) => {
  let { stock, full, reverse } = props;
  stock = Number(stock) > Number(full) ? full : stock;
  const [style, setStyle] = React.useState({});
  const [label, setLabel] = React.useState("");

  React.useEffect(() => {
    let done;
    //REVERSE FALSE
    if (reverse) {
      done = (1 - stock / full) * 100;
      // setLabel(stock > 0 ? `TERJUAL ${full - stock}` : "HABIS");
      setLabel(stock > 0 ? `TERJUAL ${Math.round(done)}` : "HABIS");
    } else {
      done = (stock / full) * 100;
      if (done <= 15 && stock > 0) {
        done = 15;
      } else if (done <= 15 && stock === 0) {
        done = 0;
      }
      // setLabel(stock > 0 ? `SISA ${stock}` : "HABIS");
      setLabel(stock > 0 ? `SISA ${Math.round(done)}` : "HABIS");
    }
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
    };
    setStyle(newStyle);
  }, []);

  return (
    <div className="progress">
      <span className="progress-label">{label}</span>
      <div className="progress-done" style={style} />
    </div>
  );
};

export default memo(ProgressBar);
