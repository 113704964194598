import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { memo } from "react";
import MainTabs from "../../MainTabs";
import "../Page.css";

const Auth = (props: any) => {
    return (
        <IonPage id="auth">
            <IonContent>
                <IonGrid className="p-0 m-0">
                    <IonRow className="p-0 m-0">
                        <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
                        <IonCol
                            sizeLg="4"
                            className="p-0 m-0 justify-content-center align-items-center"
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    height: 50,
                                    fontSize: 32,
                                    fontWeight: "bold",
                                    marginTop: 50,
                                }}
                            >
                                Ayo selesaikan belanja anda!
                            </div>
                            <div
                                style={{
                                    textAlign: "center",
                                    height: 50,
                                    fontSize: 24,
                                }}
                            >
                                Untuk pengalaman belanja lebih baik, install
                                aplikasi harnic di smartphone anda
                            </div>
                            <div
                                style={{
                                    justifyContent: "center",
                                    flex: 1,
                                    flexDirection: "row",
                                    textAlign: "center",
                                    color: "#252158",
                                    fontWeight: "bold",
                                    paddingTop: 100,
                                }}
                            >
                                <a href="https://play.google.com/store/apps/details?id=id.harnic.app">
                                    <img
                                        alt="authentication"
                                        src={require("../../assets/images/playstore.png")}
                                        width="auto"
                                        height="50px"
                                        style={{
                                            marginLeft: 8,
                                            marginRight: 8,
                                        }}
                                    />
                                </a>
                                <a href="https://apps.apple.com/id/app/harnic-id-toko-online/id1553872222">
                                    <img
                                        alt="authentication"
                                        src={require("../../assets/images/appstore.png")}
                                        width="auto"
                                        height="50px"
                                        style={{
                                            marginLeft: 8,
                                            marginRight: 8,
                                        }}
                                    />
                                </a>
                            </div>
                            {/* <div
                                style={{
                                    flex: 1,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                <IonText
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 20,
                                    }}
                                >
                                    Anda Belum Log In
                                </IonText>
                                <br />
                                <IonText style={{ fontSize: 13 }}>
                                    Silahkan login/register untuk mendapatkan
                                    penawaran terbaik dari HARNIC ID
                                </IonText>
                                <div style={{ marginTop: 25 }}>
                                    <IonButton
                                        expand="block"
                                        size="default"
                                        onClick={() => {
                                            props.history.push("/login");
                                        }}
                                    >
                                        Log In
                                    </IonButton>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <IonButton
                                        expand="block"
                                        color="light"
                                        size="default"
                                        onClick={() => {
                                            props.history.push("/register");
                                        }}
                                    >
                                        Register
                                    </IonButton>
                                </div>
                            </div> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <MainTabs />
        </IonPage>
    );
};

export default memo(Auth);
