import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonRippleEffect,
} from "@ionic/react";
import React, { memo } from "react";
import "./Comps.css";
import {
  creditCardMask,
  getCardColor,
  getCardType,
} from "../helpers/creditCard";

const CreditCard = (props: any) => {
  return (
    <div className="ion-activatable ripple-parent">
      <IonCard
        className="m-16"
        color={getCardColor(props.card_num)}
        onClick={() => props.action()}
      >
        <IonRippleEffect></IonRippleEffect>
        <IonCardHeader>
          <IonCardSubtitle>{getCardType(props.card_num)}</IonCardSubtitle>
          <IonCardTitle>{creditCardMask(props.card_num)}</IonCardTitle>
          <IonCardSubtitle>
            {props.card_holder} {props.card_exp.substring(0, 2)}/
            {props.card_exp.substring(2, 4)}
          </IonCardSubtitle>
        </IonCardHeader>
      </IonCard>
    </div>
  );
};

export default memo(CreditCard);
