import { RefresherEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRippleEffect,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { chevronDownOutline } from "ionicons/icons";
import React, { memo, useState } from "react";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

const UserVoucher = (props: any) => {
  const [voucher, setVoucher] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);

  const [page, setPage] = useState<number>(1);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
    false
  );

  const getVoucher = async (_page: number) => {
    _page === 1 && setLoading(true);
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .get(`${API}/user/${user_id}/voucher?page=${_page}&limit=10`, {
        headers: {
          token: user_token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (_page === 1) {
          setVoucher(res.data);
        } else {
          setVoucher([...voucher, ...res.data]);
        }
        setLoading(false);
        setDisableInfiniteScroll(res.data.length < 10);
        setPage(_page + 1);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setLoading(true);
    getVoucher(1)
      .then(() => event.detail.complete())
      .catch(() => event.detail.complete());
  };

  async function searchNext($event: CustomEvent<void>) {
    await getVoucher(page);

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  useIonViewWillEnter(() => {
    getVoucher(1);
  }, []);

  const _renderSkeleton = () => {
    const dataMap = [...Array(10)];
    return (
      <>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {dataMap.map((i: any, index: any) => (
                <IonSkeletonText
                  key={index}
                  animated
                  style={{
                    width: "100%",
                    paddingTop: 70,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                />
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  const _renderItem = () => {
    return (
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="lines"
          ></IonRefresherContent>
        </IonRefresher>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {voucher &&
                voucher.map((i: any) => (
                  <div className="ion-activable" key={i.vc_id}>
                    <IonCard
                      className="m-16"
                      style={{ backgroundColor: "#faaf40" }}
                    >
                      <IonRippleEffect></IonRippleEffect>
                      <IonCardHeader>
                        <IonCardTitle
                          style={{ fontSize: 20, textAlign: "center" }}
                        >
                          {i.vc_code}
                        </IonCardTitle>
                        <IonCardTitle
                          style={{ fontSize: 13, textAlign: "center" }}
                        >
                          {i.vc_name}
                        </IonCardTitle>
                      </IonCardHeader>
                    </IonCard>
                  </div>
                ))}
              <IonInfiniteScroll
                threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="lines"
                  loadingText="Loading more notifications"
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  const _emptyScreen = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  justifyContent: "center",
                  flex: 1,
                  textAlign: "center",
                  color: "#252158",
                  fontWeight: "bold",
                  paddingTop: 20,
                }}
              >
                <div>
                  <img
                    alt="empty-address"
                    src={require("../assets/images/nodata.png")}
                    width="60%"
                  />
                </div>
                <IonText
                  style={{
                    fontSize: 17,
                  }}
                >
                  Tidak ada voucher
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Voucher Saya</IonTitle>
        </IonToolbar>
      </IonHeader>
      {loading
        ? _renderSkeleton()
        : voucher.length > 0
        ? _renderItem()
        : _emptyScreen()}
      <MainTabs />
    </IonPage>
  );
};
export default memo(UserVoucher);
