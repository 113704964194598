import firebase from "firebase/app";
import "firebase/messaging";

const config = {
  //HARNIC
  apiKey: "AIzaSyBqHG0XJLgqwGhFXx2aN1RPRsOdrpPn8Po",
  authDomain: "harnic-push-notification.firebaseapp.com",
  databaseURL: "https://harnic-push-notification.firebaseio.com",
  projectId: "harnic-push-notification",
  storageBucket: "harnic-push-notification.appspot.com",
  messagingSenderId: "299740634622",
  appId: "1:299740634622:web:981e7943a5bc7c793434af",
  measurementId: "G-PFQPWYV74F",
};
firebase.initializeApp(config);

export default firebase;
