import {
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonText,
  IonThumbnail,
} from "@ionic/react";
import React, { memo } from "react";

const ListItemCartCardBonus = (props: any) => {
  const { pic_url, online_name, qty, note, removeBonus } = props;

  return (
    <IonItem>
      <IonThumbnail slot="start">
        <IonImg src={pic_url} />
      </IonThumbnail>
      <IonLabel className="ion-text-wrap">
        <IonText style={{ fontSize: 13, textAlign: "left" }}>
          {online_name}
        </IonText>
        <br />
        <IonText style={{ fontWeight: "bold", color: "#393836" }}>
          GRATIS {qty}
        </IonText>
        <br />
        {note !== "" && note !== null && (
          <div
            className="m-2"
            style={{
              flex: 1,
              backgroundColor: "#FEF4C5",
              paddingBottom: 5,
              textAlign: "center",
            }}
          >
            <IonText
              style={{
                fontSize: 10,
              }}
            >
              {note}
            </IonText>
          </div>
        )}
      </IonLabel>
      {removeBonus && (
        <IonButton
          onClick={() => removeBonus()}
          style={{ backgroundColor: "#FEF4C5" }}
        >
          Tukar
        </IonButton>
      )}
    </IonItem>
  );
};

export default memo(ListItemCartCardBonus);
