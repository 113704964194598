import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import { chevronDownOutline } from "ionicons/icons";
import React, { lazy, memo, Suspense, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";
const ListShoppingItemCard = lazy(
  () => import("../components/ListShoppingItemCard")
);

interface UserShoppingListProps extends RouteComponentProps<{ props: any }> {}

const UserShoppingList: React.FC<UserShoppingListProps> = (props: any) => {
  const contentRef = useRef(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const getItems = async () => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    setLoading(true);
    axios
      .get(`${API}/user/shopping_list/${user_id}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res) => {
        setLoading(false);
        setItems(res.data);
      });
  };

  const removeShoppingList = async (id: any) => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .delete(`${API}/user/shopping_list/${user_id}/${id}`, {
        headers: { token: user_token },
      })
      .then((res) => {
        setToastMsg("Dihapus");
        setShowToast(true);
      })
      .catch((err) => {
        setToastMsg(err);
        setShowToast(true);
        setLoading(false);
      });
  };

  useIonViewDidEnter(() => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      getItems();
    } else {
      setItems([]);
    }
  }, []);

  const _renderSkeleton = () => {
    const dataMap = [...Array(10)];
    return (
      <IonContent className="ion-padding">
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {dataMap.map((i: any) => (
                <IonSkeletonText
                  key={i}
                  animated
                  style={{
                    width: "100%",
                    paddingTop: 70,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                />
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    getItems();
    setItems([]);
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  const _emptyScreen = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  justifyContent: "center",
                  flex: 1,
                  textAlign: "center",
                  color: "#252158",
                  fontWeight: "bold",
                  paddingTop: 20,
                }}
              >
                <div>
                  <img
                    alt="empty-data"
                    src={require("../assets/images/nodata.png")}
                    width="60%"
                  />
                </div>
                <IonText
                  style={{
                    fontSize: 17,
                  }}
                >
                  Shopping List Kosong
                </IonText>
                <div style={{ marginTop: 32 }}>
                  <IonButton
                    size="default"
                    onClick={() => {
                      const user_id = localStorage.getItem("user_id");
                      if (user_id) {
                        props.history.push("/search");
                      } else {
                        props.history.push("/login");
                      }
                    }}
                  >
                    BELANJA SEKARANG
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  const _renderItem = () => {
    return (
      <IonContent ref={contentRef} scrollEvents={true} fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="lines"
          ></IonRefresherContent>
        </IonRefresher>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {items.map((data: any, i: number) => (
                <Suspense
                  fallback={
                    <IonSkeletonText animated style={{ height: 100 }} />
                  }
                >
                  <ListShoppingItemCard
                    itemid={data.itemid}
                    pic_url={data.pic_url}
                    online_name={data.online_name}
                    stock={data.stock_loc}
                    price={data.price}
                    remove={async () => {
                      await removeShoppingList(data.itemid);
                      await getItems();
                    }}
                    {...props}
                  />
                </Suspense>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>Shopping List</IonTitle>
        </IonToolbar>
      </IonHeader>
      {loading
        ? _renderSkeleton()
        : items.length > 0
        ? _renderItem()
        : _emptyScreen()}
      <MainTabs />
    </IonPage>
  );
};

export default memo(UserShoppingList);
