import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonSkeletonText,
    IonSpinner,
    IonText,
    IonThumbnail,
    IonToast,
    IonToolbar,
    IonTextarea,
    useIonViewWillEnter,
    IonImg,
    IonRippleEffect,
    IonCardTitle,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { useContext, useState } from "react";
import NumberFormat from "react-number-format";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CartItem from "../components/cart/CartItem";
import CartItemRecomend from "../components/cart/CartItemRecomend";
import CartItemRecomendNoBtn from "../components/cart/CartItemRecomendNoBtn";
import ListItemCartCardBonus from "../components/cart/ListItemCartCardBonus";
import Loading from "../components/Loading";
import { AppContext } from "../contexts/AppContext";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

const Cart = (props: any) => {
    const [checkoutLoading, setCheckoutLoading] = useState<any>(false);
    const [itemLoading, setItemLoading] = useState<any>(true);
    const [shippingLoading, setShippingLoading] = useState<any>(false);
    const [shippingDetailLoading, setShippingDetailLoading] = useState<any>(
        false
    );
    const [trxNote, setTrxNote] = useState<any>("");
    const [kurirLoading, setKurirLoading] = useState<any>(false);
    const [typeLoading, setTypeLoading] = useState<any>(false);
    const [timeLoading, setTimeLoading] = useState<any>(false);
    const [couponLoading, setCouponLoading] = useState<any>(false);
    const [showToast, setShowToast] = useState<any>(false);
    const [toastMsg, setToastMsg] = useState<any>("");

    const [cartContext, setCartContext] = useContext(AppContext);
    const carts = cartContext.cart_reg || [];
    const cartsDropship = cartContext.cart_dropship || [];
    const hasBonus = cartContext.has_bonus;
    const bonus = cartContext.bonus || [];
    // const [carts, setCarts] = useState<any>([]);
    // const [cartsDropship, setCartsDropship] = useState<any>([]);
    // const [hasBonus, setHasBonus] = useState<any>(false);
    // const [bonus, setBonus] = useState<any>([]);
    const [coupon, setCoupon] = useState<any>(null);
    const [inputCoupon, setInputCoupon] = useState<any>(false);
    const [inputCouponValue, setInputCouponValue] = useState<any>(null);

    const [shippings, setShippings] = useState<any>([]);
    const [shippingSelected, setShippingSelected] = useState<any>(null);
    const [
        shippingSelectedDropship,
        setShippingSelectedDropship,
    ] = useState<any>(null);
    const [shippingDetail, setShippingDetail] = useState<any>([]);
    const [kurir, setKurir] = useState<any>(null);
    const [kurirSelected, setKurirSelected] = useState<any>(null);
    const [kurirSelectedDropship, setKurirSelectedDropship] = useState<any>(
        null
    );
    const [type, setType] = useState<any>(null);
    const [typeSelected, setTypeSelected] = useState<any>(null);
    const [typeSelectedDropship, setTypeSelectedDropship] = useState<any>(null);
    const [typeDetail, setTypeDetail] = useState<any>(null);
    const [time, setTime] = useState<any>(null);
    const [timeSelected, setTimeSelected] = useState<any>(null);
    const [timeSelectedDropship, setTimeSelectedDropship] = useState<any>(null);
    const [timeDetail, setTimeDetail] = useState<any>(null);

    const [popup, setPopup] = useState<any>([]);
    const [freeItems, setFreeItems] = useState<any>([]);
    const [voucher, setVoucher] = useState<any>([]);

    // const getPopup = () => {
    //   axios.get(`${API}/product/mainitemlist/popup/10`).then((res) => {
    //     console.log(res.data);

    //     setPopup(res.data);
    //   });
    // };

    // const getFreeItems = () => {
    //   axios.get(`${API}/product/mainitemlist/freeitem/10`).then((res) => {
    //     console.log(res.data);

    //     setFreeItems(res.data);
    //   });
    // };

    const deleteItemBonus = async (id: any) => {
        setShippingDetail([]);
        const user_id = await localStorage.getItem("user_id");
        const token = await localStorage.getItem("user_token");
        axios.delete(`${API}/cart/bonus/${user_id}/${id}`, {
            headers: { token: token },
        });
    };

    const getCarts = async () => {
        const user_token = localStorage.getItem("user_token");
        const user_id = localStorage.getItem("user_id");
        setItemLoading(true);
        await axios
            .get(`${API}/carts4/${user_id}`, {
                headers: {
                    token: user_token,
                },
            })
            .then((res: any) => {
                setItemLoading(false);
                setCartContext(res.data);
                setPopup(res.data.item_recomendations);
                setFreeItems(res.data.item_bonus);
                setVoucher(res.data.vouchers);

                setShippingLoading(false);
                setShippings(res.data.shippings);
                setShippingSelected(res.data.shippings[0].shipping_id);
                setShippingDetail(res.data.shippings[0]);
                getKurir(res.data.shippings[0].shipping_id);

                // setCarts(res.data.cart_reg);
                // setCartsDropship(res.data.cart_dropship);
                // setBonus(res.data.bonus);
                // setHasBonus(res.data.has_bonus);
            })
            .catch((err) => {
                setItemLoading(false);
                setShippingLoading(false);
                setShippings([]);
            });
    };

    // const getShippings = () => {
    //   const user_token = localStorage.getItem("user_token");
    //   const user_id = localStorage.getItem("user_id");
    //   setShippingLoading(true);
    //   axios
    //     .get(`${API}/shippings/${user_id}`, {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //         token: user_token,
    //       },
    //     })
    //     .then((res) => {
    //       console.log("shipping : ", res.data);

    //       setShippingLoading(false);
    //       setShippings(res.data);
    //       setShippingSelected(res.data[0].shipping_id);
    //       setShippingDetail(res.data[0]);
    //       getKurir(res.data[0].shipping_id);
    //     })
    //     .catch(() => {
    //       setShippingLoading(false);
    //       setShippings([]);
    //     });
    // };
    const getShippingDetail = (id: number) => {
        const user_token = localStorage.getItem("user_token");
        const user_id = localStorage.getItem("user_id");
        setShippingDetailLoading(true);
        axios
            .get(`${API}/shipping/${user_id}/${id}`, {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                    token: user_token,
                },
            })
            .then((res) => {
                setShippingDetail(res.data[0]);
                setShippingDetailLoading(false);
            })
            .catch(() => {
                setShippingDetailLoading(false);
                setShippings([]);
            });
    };
    const getKurir = (shipping_id: any) => {
        setKurirLoading(true);
        axios
            .get(`${API}/courier/${shipping_id}`)
            .then((res) => {
                setKurirLoading(false);
                setKurir(res.data);
                setKurirSelected(res.data[0].ekspedisiid);
                getType(res.data[0].ekspedisiid, shipping_id);
            })
            .catch(() => {
                setKurirLoading(false);
            });
    };
    const getType = (courier_id: any, shipping_id: any) => {
        setTypeLoading(true);
        axios
            .get(`${API}/shipping_type/${courier_id}/${shipping_id}`)
            .then((res) => {
                setTypeLoading(false);

                setType(res.data);
                setTypeSelected(res.data[0].shipping_type_id);
                setTypeDetail(res.data[0]);
                getTime(res.data[0].shipping_type_id);
            })
            .catch(() => {
                setTypeLoading(false);
            });
    };
    const getTypeDetail = (shipping_type_id: any, shipping_id: any) => {
        setTypeLoading(true);
        axios
            .get(`${API}/shipping_type_id/${shipping_type_id}/${shipping_id}`)
            .then((res) => {
                setTypeLoading(false);
                setTypeDetail(res.data[0]);
            })
            .catch(() => {
                setTypeLoading(false);
            });
    };

    const getTime = (shipping_type_id: any) => {
        setTimeLoading(true);
        axios
            .get(`${API}/shipping_time/${shipping_type_id}`)
            .then((res) => {
                setTimeLoading(false);
                if (res.data.success !== false) {
                    setTime(res.data);

                    setTimeSelected(res.data[0].bracket_id);
                    setTimeDetail(res.data[0]);
                }
                if (res.data.success === false) {
                    setTime([]);
                }
            })
            .catch(() => {
                setTimeLoading(false);
            });
    };
    const getCoupon = (code: any) => {
        const user_token = localStorage.getItem("user_token");
        setCouponLoading(true);
        axios
            .get(`${API}/promo/${code}`, {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                    token: user_token,
                },
            })
            .then((res) => {
                setCouponLoading(false);
                if (res.data.success) {
                    setCoupon(res.data.data);
                    setInputCoupon(false);
                } else {
                    setCoupon(null);
                }

                setToastMsg(res.data.message);
                setShowToast(true);
            })
            .catch(() => {
                setCouponLoading(false);
            });
    };

    const checkOut = () => {
        setCheckoutLoading(true);
        const user_token = localStorage.getItem("user_token");
        const user_id = localStorage.getItem("user_id");

        let d = new Date();
        let datenow = d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate();
        let data = qs.stringify({
            has_bonus: hasBonus,
            customer_name: shippingDetail.pic_name,
            customer_phone: shippingDetail.pic_phone,
            shipping_address: shippingDetail.shipping_address,
            shipping_prov: shippingDetail.shipping_province,
            shipping_city: shippingDetail.shipping_city,
            postalcode: shippingDetail.zip_code,
            coupon: inputCouponValue,
            trxnote: trxNote, //TODO

            //PARAMS FOR REG
            courier_reg: kurirSelected || null,
            shipping_type_id_reg: typeSelected || null,
            shipping_time_start_reg:
                timeDetail.length > 0 ? timeDetail.bracket_time_start : null,
            shipping_time_end_reg:
                timeDetail.length > 0 ? timeDetail.bracket_time_end : null,
            shipping_date_reg: timeDetail.length > 0 ? datenow : null,
            shipping_fee_reg: typeDetail.length > 0 ? typeDetail.tarif : null,

            //PARAMS FOR DROPSHIP
            courier_ds: null,
            shipping_type_id_ds: null,
            shipping_time_start_ds: null,
            shipping_time_end_ds: null,
            shipping_date_ds: null,
            shipping_fee_ds: null,
        });

        axios
            .post(`${API}/trx/create2/${user_id}`, data, {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                    token: user_token,
                },
            })
            .then((res) => {
                setCheckoutLoading(false);
                if (res.data.multiinvoice) {
                    props.history.push("/user/transactions");
                } else {
                    props.history.push(
                        `/transaction/${user_id}/${res.data.id}`
                    );
                }
            })
            .catch((err) => {
                setCheckoutLoading(false);
            });
    };

    const subtotal_reg = carts.reduce(
        (a: any, b: any) => a + Number(b.subtotal),
        0
    );
    const subtotal = subtotal_reg;

    const subtotal_for_discount_reg = carts.reduce(
        (a: any, b: any) => a + Number(b.subtotal_for_discount),
        0
    );
    const subtotal_for_discount = subtotal_for_discount_reg;

    const biayaPengiriman_reg = typeDetail ? Number(typeDetail.tarif) : 0;

    const biayaPengiriman = biayaPengiriman_reg;

    const totalTemp = subtotal_for_discount + biayaPengiriman;

    //PERHITUNGAN KODE VOUCEHER/COUPON/DISKON
    let couponAfterCalculate = 0;
    if (coupon) {
        if (coupon.promo_type === "ALL") {
            switch (coupon.type) {
                case "PERCENTAGE":
                    switch (coupon.discount_for) {
                        case "PRODUCTS":
                            couponAfterCalculate =
                                (Number(coupon.value) / 100) *
                                subtotal_for_discount;
                            break;
                        case "DELIVERY":
                            couponAfterCalculate =
                                (Number(coupon.value) / 100) * biayaPengiriman;
                            break;
                        case "TOTAL":
                            couponAfterCalculate =
                                (Number(coupon.value) / 100) * totalTemp;
                            break;
                    }
                    break;
                case "VALUE":
                    switch (coupon.discount_for) {
                        case "PRODUCTS":
                            couponAfterCalculate =
                                subtotal_for_discount < coupon.value
                                    ? subtotal_for_discount
                                    : Number(coupon.value);
                            break;
                        case "DELIVERY":
                            couponAfterCalculate =
                                biayaPengiriman < Number(coupon.value)
                                    ? biayaPengiriman
                                    : Number(coupon.value);
                            break;
                        case "TOTAL":
                            couponAfterCalculate = Number(coupon.value);
                            break;
                    }
                    break;
            }
        } else {
            couponAfterCalculate = Number(coupon.DiscValue);
        }

        if (
            Number(coupon.vc_maxtrx) > 0 &&
            couponAfterCalculate > Number(coupon.vc_maxtrx)
        ) {
            couponAfterCalculate = Number(coupon.vc_maxtrx);
        }
    }

    let couponText = "0";

    if (coupon) {
        switch (coupon.type) {
            case "PERCENTAGE":
                couponText = `${Number(coupon.value)}% ${coupon.description} `;
                break;
            case "VALUE":
                couponText = `${couponAfterCalculate} ${coupon.description} `;

                break;
        }
    }

    useIonViewWillEnter(() => {
        const user_id = localStorage.getItem("user_id");
        setKurir([]);
        setType([]);
        setTime([]);
        if (user_id) {
            getCarts();
            // getShippings();
            // getPopup();
            // getFreeItems();
        } else {
            // setCartContext([]);
            // setCarts([]);
            setPopup([]);
            setFreeItems([]);
        }
    }, []);

    const _emptyScreen = () => {
        return (
            <IonContent>
                <IonGrid className="p-0 m-0">
                    <IonRow className="p-0 m-0">
                        <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
                        <IonCol
                            sizeLg="8"
                            className="p-0 m-0 justify-content-center "
                        >
                            <div
                                style={{
                                    justifyContent: "center",
                                    flex: 1,
                                    textAlign: "center",
                                    color: "#252158",
                                    fontWeight: "bold",
                                    paddingTop: 20,
                                }}
                            >
                                <div>
                                    <img
                                        alt="empty-cart"
                                        src={require("../assets/images/empty_cart.png")}
                                        width="80%"
                                    />
                                </div>
                                <IonText
                                    style={{
                                        fontSize: 17,
                                    }}
                                >
                                    Keranjang masih kosong
                                </IonText>
                                <div style={{ marginTop: 32 }}>
                                    <IonButton
                                        size="default"
                                        onClick={() => {
                                            const user_id = localStorage.getItem(
                                                "user_id"
                                            );
                                            if (user_id) {
                                                props.history.push("/search");
                                            } else {
                                                props.history.push("/login");
                                            }
                                        }}
                                    >
                                        BELANJA SEKARANG
                                    </IonButton>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        );
    };

    const _cartScreen = () => {
        return (
            <>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toastMsg}
                    color="dark"
                    position="top"
                    duration={2000}
                />
                <IonContent>
                    <IonGrid className="p-0 m-0">
                        <IonRow className="p-0 m-0">
                            <IonCol
                                sizeLg="2"
                                sizeXs="auto"
                                className="p-0 m-0"
                            />
                            <IonCol
                                sizeLg="8"
                                className="p-0 m-0 justify-content-center "
                            >
                                <IonHeader>
                                    {/* <IonSearchbar
                    placeholder="Cari Apa Lagi?"
                    onFocus={() => props.history.push("/search")}
                  ></IonSearchbar> */}
                                    <IonButton
                                        color="light"
                                        expand="block"
                                        onClick={() =>
                                            props.history.push("/search")
                                        }
                                        type="submit"
                                        style={{
                                            marginLeft: 12,
                                            marginRight: 12,
                                            paddingTop: -5,
                                            paddingBottom: 5,
                                        }}
                                        className="ion-no-margin"
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: "left",
                                            }}
                                        >
                                            <IonIcon icon="search-outline" />
                                            &nbsp;
                                            <IonText
                                                style={{ color: "#A0A1A3" }}
                                            >
                                                Cari Apa Lagi?
                                            </IonText>
                                        </div>
                                    </IonButton>
                                </IonHeader>
                                {itemLoading ? (
                                    <>
                                        <IonListHeader>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{
                                                        width: "30%",
                                                        height: 22,
                                                    }}
                                                />
                                            </IonLabel>
                                        </IonListHeader>
                                        <IonItem>
                                            <IonThumbnail slot="start">
                                                <IonSkeletonText />
                                            </IonThumbnail>
                                            <IonLabel>
                                                <h3>
                                                    <IonSkeletonText
                                                        animated
                                                        style={{ width: "80%" }}
                                                    />
                                                </h3>
                                                <p>
                                                    <IonSkeletonText
                                                        animated
                                                        style={{ width: "60%" }}
                                                    />
                                                </p>
                                                <p>
                                                    <IonSkeletonText
                                                        animated
                                                        style={{ width: "30%" }}
                                                    />
                                                </p>
                                            </IonLabel>
                                        </IonItem>
                                    </>
                                ) : (
                                    <IonList>
                                        <IonItem>
                                            <IonImg
                                                style={{ width: 200 }}
                                                src={require("../assets/images/xpress-label-b.png")}
                                            />
                                        </IonItem>
                                        {carts &&
                                            carts.map((i: any) => (
                                                <CartItem
                                                    key={"cartitem" + i.itemid}
                                                    i={i}
                                                    refreshItem={() =>
                                                        getCarts()
                                                    }
                                                    resetCoupon={() => {
                                                        if (coupon) {
                                                            setToastMsg(
                                                                "Masukkan kembali kode voucher"
                                                            );
                                                            setShowToast(true);
                                                        }
                                                        setCoupon(null);
                                                    }}
                                                    {...props}
                                                />
                                            ))}
                                    </IonList>
                                )}

                                <div
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "50%",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            backgroundColor: "#1100BB",
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                        }}
                                    >
                                        <IonText
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: 14,
                                                color: "#eee",
                                            }}
                                        >
                                            BELI JUGA
                                        </IonText>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: "#eee" }}>
                                    <Slider
                                        lazyLoad="progressive"
                                        centerPadding="30px"
                                        dots={false}
                                        infinite={false}
                                        speed={300}
                                        slidesToShow={2.2}
                                        slidesToScroll={2}
                                        responsive={[
                                            {
                                                breakpoint: 600,
                                                settings: {
                                                    slidesToShow: 3.2,
                                                    slidesToScroll: 2,
                                                },
                                            },
                                            {
                                                breakpoint: 1000,
                                                settings: {
                                                    slidesToShow: 3.2,
                                                    slidesToScroll: 2,
                                                },
                                            },
                                            {
                                                breakpoint: 2000,
                                                settings: {
                                                    slidesToShow: 6.2,
                                                    slidesToScroll: 2,
                                                },
                                            },
                                        ]}
                                    >
                                        {popup.map((i: any) => (
                                            <CartItemRecomend
                                                key={
                                                    "cartitemrecomend" +
                                                    i.itemid
                                                }
                                                refreshItem={() => getCarts()}
                                                i={i}
                                                num="popup"
                                                {...props}
                                            />
                                        ))}
                                    </Slider>
                                </div>

                                {shippingLoading ? (
                                    <>
                                        <IonListHeader>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{
                                                        width: "30%",
                                                        height: 22,
                                                    }}
                                                />
                                            </IonLabel>
                                        </IonListHeader>
                                        <IonItem>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{ width: "50%" }}
                                                />
                                            </IonLabel>
                                            <IonLabel
                                                slot="end"
                                                className="ion-text-right"
                                            >
                                                <IonSkeletonText
                                                    animated
                                                    style={{ width: "30%" }}
                                                />
                                            </IonLabel>
                                        </IonItem>
                                    </>
                                ) : (
                                    shippings && (
                                        <>
                                            <IonListHeader>
                                                <IonLabel>Alamat</IonLabel>
                                            </IonListHeader>
                                            <IonItem>
                                                <IonLabel>
                                                    Alamat Kirim
                                                </IonLabel>
                                                <IonSelect
                                                    value={shippingSelected}
                                                    onIonChange={(e) => {
                                                        if (
                                                            e.detail.value === 0
                                                        ) {
                                                            props.history.push(
                                                                "/user/shipping/create"
                                                            );
                                                        } else {
                                                            setKurir([]);
                                                            setType([]);
                                                            setTime([]);
                                                            setShippingSelected(
                                                                e.detail.value
                                                            );
                                                            getShippingDetail(
                                                                e.detail.value
                                                            );
                                                            getKurir(
                                                                e.detail.value
                                                            );
                                                        }
                                                    }}
                                                    interface="action-sheet"
                                                >
                                                    <IonSelectOption
                                                        key="new_addr"
                                                        value={0}
                                                    >
                                                        + Tambah Baru
                                                    </IonSelectOption>
                                                    {shippings.map((i: any) => (
                                                        <IonSelectOption
                                                            key={
                                                                i.shipping_id +
                                                                i.shipping_name
                                                            }
                                                            value={
                                                                i.shipping_id
                                                            }
                                                        >
                                                            {i.shipping_name},{" "}
                                                            {i.shipping_address}
                                                        </IonSelectOption>
                                                    ))}
                                                </IonSelect>
                                            </IonItem>
                                        </>
                                    )
                                )}

                                {shippingDetailLoading ? (
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardSubtitle>
                                                <IonSkeletonText
                                                    animated
                                                    style={{
                                                        width: "30%",
                                                        height: 22,
                                                    }}
                                                />
                                            </IonCardSubtitle>
                                            <IonCardSubtitle>
                                                <IonSkeletonText animated />
                                            </IonCardSubtitle>
                                        </IonCardHeader>
                                    </IonCard>
                                ) : (
                                    shippingDetail &&
                                    shippingDetail.pic_name && (
                                        <IonCard>
                                            <IonCardHeader>
                                                <IonCardSubtitle>
                                                    {shippingDetail.pic_name} (
                                                    {shippingDetail.pic_phone})
                                                </IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent
                                                style={{ fontSize: 11 }}
                                            >
                                                {
                                                    shippingDetail.shipping_address
                                                }
                                                ,{shippingDetail.subdis_name},
                                                {shippingDetail.city_name},
                                                {shippingDetail.prov_name},
                                                {shippingDetail.zip_code}
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                )}

                                {kurirLoading ? (
                                    <>
                                        <IonListHeader>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{
                                                        width: "30%",
                                                        height: 22,
                                                    }}
                                                />
                                            </IonLabel>
                                        </IonListHeader>
                                        <IonItem>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{ width: "50%" }}
                                                />
                                            </IonLabel>
                                            <IonLabel
                                                slot="end"
                                                className="ion-text-right"
                                            >
                                                <IonSkeletonText
                                                    animated
                                                    style={{ width: "30%" }}
                                                />
                                            </IonLabel>
                                        </IonItem>
                                    </>
                                ) : (
                                    kurir && (
                                        <>
                                            <IonListHeader>
                                                <IonLabel>Kurir</IonLabel>
                                            </IonListHeader>
                                            <IonItem>
                                                <IonLabel>Kurir</IonLabel>
                                                <IonSelect
                                                    value={kurirSelected}
                                                    onIonChange={(e) => {
                                                        setKurirSelected(
                                                            e.detail.value
                                                        );
                                                        getType(
                                                            e.detail.value,
                                                            shippingSelected
                                                        );
                                                    }}
                                                    interface="action-sheet"
                                                >
                                                    {kurir.map((i: any) => (
                                                        <IonSelectOption
                                                            key={
                                                                i.ekspedisiid +
                                                                i.namaekspedisi
                                                            }
                                                            value={
                                                                i.ekspedisiid
                                                            }
                                                        >
                                                            {i.namaekspedisi}
                                                        </IonSelectOption>
                                                    ))}
                                                </IonSelect>
                                            </IonItem>
                                        </>
                                    )
                                )}

                                {typeLoading ? (
                                    <>
                                        <IonListHeader>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{
                                                        width: "30%",
                                                        height: 22,
                                                    }}
                                                />
                                            </IonLabel>
                                        </IonListHeader>
                                        <IonItem>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{ width: "50%" }}
                                                />
                                            </IonLabel>
                                            <IonLabel
                                                slot="end"
                                                className="ion-text-right"
                                            >
                                                <IonSkeletonText
                                                    animated
                                                    style={{ width: "30%" }}
                                                />
                                            </IonLabel>
                                        </IonItem>
                                    </>
                                ) : (
                                    type && (
                                        <>
                                            <IonListHeader>
                                                <IonLabel>
                                                    Jenis Pengiriman
                                                </IonLabel>
                                            </IonListHeader>
                                            <IonRadioGroup
                                                value={typeSelected}
                                                onIonChange={(e) => {
                                                    //SINI
                                                    getTypeDetail(
                                                        e.detail.value,
                                                        shippingSelected
                                                    );
                                                    setTypeSelected(
                                                        e.detail.value
                                                    );
                                                    getTime(e.detail.value);
                                                }}
                                            >
                                                {type.map((i: any) => (
                                                    <IonItem
                                                        key={
                                                            i.shipping_type_id +
                                                            i.description
                                                        }
                                                    >
                                                        <IonLabel>
                                                            {i.description}
                                                        </IonLabel>
                                                        <IonRadio
                                                            slot="start"
                                                            value={
                                                                i.shipping_type_id
                                                            }
                                                        />
                                                        <IonLabel
                                                            slot="end"
                                                            className="ion-text-right"
                                                        >
                                                            {Number(i.tarif) ===
                                                            0 ? (
                                                                "GRATIS"
                                                            ) : (
                                                                <NumberFormat
                                                                    value={
                                                                        i.tarif
                                                                    }
                                                                    displayType={
                                                                        "text"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                />
                                                            )}
                                                        </IonLabel>
                                                    </IonItem>
                                                ))}
                                            </IonRadioGroup>
                                        </>
                                    )
                                )}

                                {timeLoading ? (
                                    <>
                                        <IonListHeader>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{
                                                        width: "30%",
                                                        height: 22,
                                                    }}
                                                />
                                            </IonLabel>
                                        </IonListHeader>
                                        <IonItem>
                                            <IonLabel>
                                                <IonSkeletonText
                                                    animated
                                                    style={{ width: "50%" }}
                                                />
                                            </IonLabel>
                                            <IonLabel
                                                slot="end"
                                                className="ion-text-right"
                                            >
                                                <IonSkeletonText animated />
                                            </IonLabel>
                                        </IonItem>
                                    </>
                                ) : (
                                    time && (
                                        <>
                                            <IonListHeader>
                                                <IonLabel>Waktu Kirim</IonLabel>
                                            </IonListHeader>
                                            <IonRadioGroup
                                                value={timeSelected}
                                                onIonChange={(e) =>
                                                    setTimeSelected(
                                                        e.detail.value
                                                    )
                                                }
                                            >
                                                {time.map((i: any) => (
                                                    <IonItem
                                                        key={
                                                            i.bracket_id +
                                                            i.bracket_time_end
                                                        }
                                                    >
                                                        <IonLabel>{`${i.bracket_time_start} - ${i.bracket_time_end}`}</IonLabel>
                                                        <IonRadio
                                                            slot="start"
                                                            value={i.bracket_id}
                                                        />
                                                    </IonItem>
                                                ))}
                                            </IonRadioGroup>
                                        </>
                                    )
                                )}

                                {/* COUPON */}
                                <IonListHeader>
                                    <IonLabel>Punya Coupon?</IonLabel>
                                </IonListHeader>
                                <IonItem
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setInputCoupon(true)}
                                >
                                    <IonLabel>Kode Coupon</IonLabel>
                                    <IonLabel
                                        slot="end"
                                        className="ion-text-right"
                                    >
                                        {coupon ? coupon.code : "Masukkan Kode"}
                                    </IonLabel>
                                </IonItem>
                                <IonModal isOpen={inputCoupon}>
                                    <IonToolbar>
                                        <IonListHeader>
                                            <IonLabel>
                                                Pilih Voucher Anda
                                            </IonLabel>
                                            <IonButton
                                                color="danger"
                                                onClick={() =>
                                                    setInputCoupon(false)
                                                }
                                            >
                                                <IonIcon
                                                    slot="end"
                                                    name="close"
                                                />
                                            </IonButton>
                                        </IonListHeader>
                                        <IonList
                                            lines="full"
                                            className="ion-no-margin ion-padding"
                                        >
                                            <IonItem>
                                                <IonInput
                                                    placeholder="Kode Coupon"
                                                    autocapitalize="sentences"
                                                    inputMode="text"
                                                    value={inputCouponValue}
                                                    onIonChange={(e) => {
                                                        setInputCouponValue(
                                                            e.detail.value
                                                        );
                                                    }}
                                                ></IonInput>
                                                <IonButton
                                                    disabled={couponLoading}
                                                    expand="block"
                                                    onClick={() => {
                                                        getCoupon(
                                                            inputCouponValue
                                                        );
                                                    }}
                                                >
                                                    {couponLoading ? (
                                                        <IonSpinner name="dots" />
                                                    ) : (
                                                        "GUNAKAN KODE"
                                                    )}
                                                </IonButton>
                                            </IonItem>
                                        </IonList>
                                    </IonToolbar>
                                    <IonContent className="ion-no-margin ion-padding">
                                        {voucher &&
                                            voucher.map((i: any) => (
                                                <div className="ion-activable">
                                                    <IonCard
                                                        className="m-4"
                                                        style={{
                                                            backgroundColor:
                                                                "#faaf40",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            getCoupon(
                                                                i.vc_code
                                                            );
                                                        }}
                                                    >
                                                        <IonRippleEffect></IonRippleEffect>
                                                        <IonCardHeader>
                                                            <IonCardTitle
                                                                style={{
                                                                    fontSize: 20,
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {i.vc_code}
                                                            </IonCardTitle>
                                                            <IonCardTitle
                                                                style={{
                                                                    fontSize: 15,
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {i.vc_name}
                                                            </IonCardTitle>
                                                        </IonCardHeader>
                                                    </IonCard>
                                                </div>
                                            ))}
                                    </IonContent>
                                </IonModal>

                                {bonus.length === 0 ? (
                                    subtotal >= 200000 &&
                                    hasBonus === 1 && (
                                        <>
                                            <div
                                                style={{
                                                    marginTop: 10,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "50%",
                                                        paddingRight: 16,
                                                        paddingLeft: 16,
                                                        backgroundColor:
                                                            "#F4A91D",
                                                        borderTopRightRadius: 5,
                                                        borderBottomRightRadius: 5,
                                                    }}
                                                >
                                                    <IonText
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: 14,
                                                            color: "#eee",
                                                        }}
                                                    >
                                                        AMBIL BONUSNYA
                                                    </IonText>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: "#FED859",
                                                }}
                                            >
                                                {itemLoading ? (
                                                    <Slider
                                                        lazyLoad="progressive"
                                                        centerMode={false}
                                                        dots={false}
                                                        infinite={false}
                                                        speed={300}
                                                        slidesToShow={2.2}
                                                        slidesToScroll={2}
                                                        responsive={[
                                                            {
                                                                breakpoint: 600,
                                                                settings: {
                                                                    slidesToShow: 3.2,
                                                                    slidesToScroll: 2,
                                                                },
                                                            },
                                                            {
                                                                breakpoint: 1000,
                                                                settings: {
                                                                    slidesToShow: 3.2,
                                                                    slidesToScroll: 2,
                                                                },
                                                            },
                                                            {
                                                                breakpoint: 2000,
                                                                settings: {
                                                                    slidesToShow: 6.2,
                                                                    slidesToScroll: 2,
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <div className="ratio-square m-2">
                                                            <IonSkeletonText
                                                                animated
                                                            />
                                                        </div>
                                                        <div className="ratio-square m-2">
                                                            <IonSkeletonText
                                                                animated
                                                            />
                                                        </div>
                                                        <div className="ratio-square m-2">
                                                            <IonSkeletonText
                                                                animated
                                                            />
                                                        </div>
                                                    </Slider>
                                                ) : (
                                                    <Slider
                                                        lazyLoad="progressive"
                                                        centerMode={false}
                                                        dots={false}
                                                        infinite={false}
                                                        speed={300}
                                                        slidesToShow={2.2}
                                                        slidesToScroll={2}
                                                        responsive={[
                                                            {
                                                                breakpoint: 600,
                                                                settings: {
                                                                    slidesToShow: 3.2,
                                                                    slidesToScroll: 2,
                                                                },
                                                            },
                                                            {
                                                                breakpoint: 1000,
                                                                settings: {
                                                                    slidesToShow: 3.2,
                                                                    slidesToScroll: 2,
                                                                },
                                                            },
                                                            {
                                                                breakpoint: 2000,
                                                                settings: {
                                                                    slidesToShow: 6.2,
                                                                    slidesToScroll: 2,
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        {freeItems.map(
                                                            (i: any) => (
                                                                <CartItemRecomendNoBtn
                                                                    key={
                                                                        "cartitemrecomend" +
                                                                        i.itemid
                                                                    }
                                                                    refreshItem={() => {
                                                                        getCarts();
                                                                    }}
                                                                    i={i}
                                                                    num="freeitem"
                                                                    is_bonus={
                                                                        true
                                                                    }
                                                                    {...props}
                                                                />
                                                            )
                                                        )}
                                                    </Slider>
                                                )}
                                            </div>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                marginTop: 10,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "50%",
                                                    paddingRight: 16,
                                                    paddingLeft: 16,
                                                    backgroundColor: "#F4A91D",
                                                    borderTopRightRadius: 5,
                                                    borderBottomRightRadius: 5,
                                                }}
                                            >
                                                <IonText
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 14,
                                                        color: "#eee",
                                                    }}
                                                >
                                                    BONUS PEMBELIAN
                                                </IonText>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                backgroundColor: "#FED859",
                                            }}
                                        >
                                            <IonList>
                                                {bonus &&
                                                    bonus.map(
                                                        (b: any, i: number) => (
                                                            <ListItemCartCardBonus
                                                                key={i}
                                                                pic_url={
                                                                    b.pic_url
                                                                }
                                                                online_name={
                                                                    b.online_name
                                                                }
                                                                note={b.note}
                                                                qty={b.qty}
                                                                removeBonus={async () => {
                                                                    await deleteItemBonus(
                                                                        b.itemid
                                                                    );
                                                                    await getCarts();
                                                                }}
                                                            />
                                                        )
                                                    )}
                                            </IonList>
                                        </div>
                                    </>
                                )}

                                <IonListHeader>
                                    <IonLabel>Total</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonLabel>Total Barang</IonLabel>
                                    <IonLabel className="ion-text-right">
                                        {itemLoading ? (
                                            <IonSkeletonText />
                                        ) : (
                                            <NumberFormat
                                                value={subtotal}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        )}
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Ongkir</IonLabel>
                                    <IonLabel className="ion-text-right">
                                        {typeLoading ||
                                        shippingLoading ||
                                        kurirLoading ? (
                                            <IonSkeletonText />
                                        ) : biayaPengiriman === 0 ? (
                                            "GRATIS"
                                        ) : (
                                            <NumberFormat
                                                value={biayaPengiriman}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        )}
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Diskon</IonLabel>
                                    <IonLabel className="ion-text-right">
                                        {couponLoading ? (
                                            <IonSkeletonText />
                                        ) : (
                                            <IonText>{couponText}</IonText>
                                            // <NumberFormat
                                            //   value={couponAfterCalculate}
                                            //   displayType={"text"}
                                            //   thousandSeparator={true}
                                            // />
                                        )}
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>TOTAL BAYAR</IonLabel>
                                    <IonLabel className="ion-text-right">
                                        {itemLoading ||
                                        couponLoading ||
                                        typeLoading ||
                                        shippingLoading ||
                                        kurirLoading ? (
                                            <IonSkeletonText />
                                        ) : (
                                            <NumberFormat
                                                value={
                                                    subtotal +
                                                    biayaPengiriman -
                                                    couponAfterCalculate
                                                }
                                                displayType={"text"}
                                                thousandSeparator={true}
                                            />
                                        )}
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonTextarea
                                        style={{
                                            backgroundColor: "#FEF4C5",
                                            borderRadius: 10,
                                            flex: 1,
                                            borderColor: "transparent",
                                            paddingLeft: 20,
                                            marginTop: 10,
                                        }}
                                        placeholder="Catatan untuk kami, patokan alamat dll (opsional)"
                                        rows={5}
                                        value={trxNote}
                                        onIonChange={(e) =>
                                            setTrxNote(e.detail.value)
                                        }
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <div className="ion-padding">
                    <IonButton
                        disabled={
                            (carts.length > 0 && cartsDropship.length === 0
                                ? shippingSelected > 0
                                    ? kurirSelected > 0
                                        ? typeSelected > 0
                                            ? subtotal - couponAfterCalculate <
                                              40000
                                                ? true
                                                : false
                                            : true
                                        : true
                                    : true
                                : carts.length === 0 && cartsDropship.length > 0
                                ? shippingSelected > 0
                                    ? kurirSelectedDropship > 0
                                        ? typeSelectedDropship > 0
                                            ? subtotal - couponAfterCalculate <
                                              40000
                                                ? true
                                                : false
                                            : true
                                        : true
                                    : true
                                : carts.length > 0 && cartsDropship.length > 0
                                ? shippingSelected > 0
                                    ? kurirSelected > 0 &&
                                      kurirSelectedDropship > 0
                                        ? typeSelected > 0 &&
                                          typeSelectedDropship > 0
                                            ? subtotal - couponAfterCalculate <
                                              40000
                                                ? true
                                                : false
                                            : true
                                        : true
                                    : true
                                : true) || checkoutLoading
                        }
                        onClick={() => checkOut()}
                        expand="block"
                    >
                        {checkoutLoading ? (
                            <IonSpinner name="dots" />
                        ) : (
                            "CHECKOUT"
                        )}
                    </IonButton>
                </div>
            </>
        );
    };

    return (
        <IonPage id="cart">
            {itemLoading && shippingLoading ? (
                <Loading />
            ) : carts.length === 0 && cartsDropship.length === 0 ? (
                _emptyScreen()
            ) : (
                _cartScreen()
            )}
            <MainTabs />
        </IonPage>
    );
};

export default Cart;
