import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import { businessOutline, homeOutline } from "ionicons/icons";
import React, { memo, useState } from "react";
import * as Icon from "react-feather";
import { RouteComponentProps } from "react-router";
import { API } from "../../helpers/api";
import MainTabs from "../../MainTabs";
import "../Page.css";

interface EditAlamatProps extends RouteComponentProps<{ id: any }> {}

const EditAlamat: React.FC<EditAlamatProps> = ({ match, history }) => {
  const [province, setProvince] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [district, setDistrict] = useState<any>([]);
  const [subdistrict, setSubdistrict] = useState<any>([]);
  const [postal, setPostal] = useState<any>([]);

  const [shipping_name, setShipping_name] = useState<any>("");
  const [shipping_pic_name, setShipping_pic_name] = useState<any>("");
  const [shipping_pic_phone, setShipping_pic_phone] = useState<any>("");
  const [shipping_address, setShipping_address] = useState<any>("");
  const [shipping_city, setShipping_city] = useState("");
  const [shipping_province, setShipping_province] = useState("");
  const [shipping_dis, setShipping_dis] = useState("");
  const [shipping_subdis, setShipping_subdis] = useState("");
  const [shipping_zip, setShipping_zip] = useState("");

  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [progress, setProgress] = useState<any>(false);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const updatePengiriman = async (id: any, data: any) => {
    setProgress(true);
    axios
      .put(`${API}/shipping/${user_id}/${id}`, data, {
        headers: {
          token: user_token,
        },
      })
      .then((res: any) => {
        setProgress(false);
        setToastMsg(res.data.message);
        setShowToast(true);
        history.push("/user/shippings");
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  const deletePengiriman = async (id: any) => {
    setProgress(true);
    axios
      .delete(`${API}/shipping/${user_id}/${id}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res) => {
        setProgress(false);
        setToastMsg(res.data.message);
        setShowToast(true);
        history.push("/user/shippings");
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  const getData = async (id: number) => {
    setProgress(true);
    await axios
      .get(`${API}/shipping/${user_id}/${id}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res) => {
        setShipping_name(res.data[0].shipping_name);
        setShipping_pic_name(res.data[0].pic_name);
        setShipping_pic_phone(res.data[0].pic_phone);
        setShipping_address(res.data[0].shipping_address);
        setShipping_province(res.data[0].shipping_province);
        getCity(res.data[0].shipping_province);
        setShipping_city(res.data[0].shipping_city);
        getDistrict(res.data[0].shipping_city);
        setShipping_dis(res.data[0].shipping_dis);
        getSubDistrict(res.data[0].shipping_dis);
        setShipping_subdis(res.data[0].shipping_subdis);
        getPostals(res.data[0].shipping_subdis);
        setShipping_zip(res.data[0].zip_code);
        setProgress(false);
      })
      .catch((res) => {
        setProgress(false);
      });
  };

  const getProvince = () => {
    axios.get(`https://api.harnic.id/provinces`).then((res) => {
      setProvince(res.data);
    });
  };

  const getCity = (id: number) => {
    axios.get(`https://api.harnic.id/cities/${id}`).then((res) => {
      setCity(res.data);
    });
  };

  const getDistrict = (id: number) => {
    axios.get(`https://api.harnic.id/districts/${id}`).then((res) => {
      setDistrict(res.data);
    });
  };

  const getSubDistrict = (id: number) => {
    axios.get(`https://api.harnic.id/subdistricts/${id}`).then((res) => {
      setSubdistrict(res.data);
    });
  };

  const getPostals = (id: number) => {
    axios.get(`https://api.harnic.id/postals/${id}`).then((res) => {
      setPostal(res.data);
    });
  };

  useIonViewDidEnter(() => {
    getData(match.params.id);
    getProvince();
  }, []);

  // useIonViewWillEnter(() => {

  // }, [match.params.id]);

  return (
    <IonPage id="editalamat">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Edit Alamat</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <IonList>
                <IonListHeader style={{ fontSize: 14 }}>
                  <IonLabel>Nama Tempat</IonLabel>
                </IonListHeader>
                <IonRadioGroup
                  border-radius
                  allow-empty-selection
                  value={shipping_name}
                >
                  <IonItem
                    onClick={() => {
                      setShipping_name("Rumah");
                    }}
                  >
                    <IonIcon slot="start" icon={homeOutline} />
                    <IonLabel>Rumah</IonLabel>
                    <IonRadio
                      onClick={() => {
                        setShipping_name("Rumah");
                      }}
                      slot="end"
                      value="Rumah"
                    />
                  </IonItem>
                  <IonItem
                    onClick={() => {
                      setShipping_name("Kantor");
                    }}
                  >
                    <IonIcon slot="start" icon={businessOutline} />
                    <IonLabel>Kantor</IonLabel>
                    <IonRadio
                      onClick={() => {
                        setShipping_name("Kantor");
                      }}
                      slot="end"
                      value="Kantor"
                    />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
              <IonList>
                <IonListHeader style={{ fontSize: 14 }}>
                  <IonLabel>Detail Alamat</IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonLabel position="floating">Nama Penerima</IonLabel>
                  <IonInput
                    type="text"
                    value={shipping_pic_name}
                    onIonChange={(e) => {
                      setShipping_pic_name(e.detail.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Nomor HP Penerima</IonLabel>
                  <IonInput
                    type="number"
                    value={shipping_pic_phone}
                    onIonChange={(e) => {
                      setShipping_pic_phone(e.detail.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">
                    Alamat Lengkap, Jalan, Gang, RT/RW
                  </IonLabel>
                  <IonInput
                    type="text"
                    value={shipping_address}
                    onIonChange={(e) => {
                      setShipping_address(e.detail.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <select
                    className="form-control"
                    style={{ cursor: "pointer" }}
                    placeholder="Pilih Kabupaten"
                    value={shipping_province}
                    onChange={(e) => {
                      getCity(parseInt(e.target.value));
                      setShipping_province(e.target.value);
                    }}
                  >
                    {province.map((data: any) => (
                      <option key={data.prov_id} value={data.prov_id}>
                        {data.prov_name}
                      </option>
                    ))}
                  </select>
                  {/* <IonLabel position="floating"></IonLabel> */}
                  {/* <IonSelect
                    placeholder="Pilih Provinsi"
                    interface="action-sheet"
                    value={shipping_province}
                    onIonChange={(e) => {
                      getCity(e.detail.value);
                      setShipping_province(e.detail.value);
                    }}
                  >
                    {province.map((data: any) => (
                      <IonSelectOption key={data.prov_id} value={data.prov_id}>
                        {data.prov_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect> */}
                </IonItem>
                <IonItem>
                  <select
                    className="form-control"
                    style={{ cursor: "pointer" }}
                    placeholder="Pilih Kabupaten"
                    value={shipping_city}
                    onChange={(e) => {
                      setShipping_city(e.target.value);
                      getDistrict(parseInt(e.target.value));
                    }}
                  >
                    {city.map((data: any) => (
                      <option key={data.city_id} value={data.city_id}>
                        {data.city_name}
                      </option>
                    ))}
                  </select>
                </IonItem>
                {/* <IonLabel position="floating"></IonLabel> */}
                {/* <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kabupaten"
                    interface="action-sheet"
                    value={shipping_city}
                    onIonChange={(e) => {
                      setShipping_city(e.detail.value);
                      getDistrict(e.detail.value);
                    }}
                  >
                    {city.map((data: any) => (
                      <IonSelectOption key={data.city_id} value={data.city_id}>
                        {data.city_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem> */}
                <IonItem>
                  <select
                    className="form-control"
                    style={{ cursor: "pointer" }}
                    placeholder="Pilih Kecamatan"
                    value={shipping_dis}
                    onChange={(e) => {
                      setShipping_dis(e.target.value);
                      getSubDistrict(parseInt(e.target.value));
                    }}
                  >
                    {district.map((data: any) => (
                      <option key={data.dis_id} value={data.dis_id}>
                        {data.dis_name}
                      </option>
                    ))}
                  </select>
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kecamatan"
                    interface="action-sheet"
                    value={shipping_dis}
                    onIonChange={(e) => {
                      setShipping_dis(e.detail.value);
                      getSubDistrict(e.detail.value);
                    }}
                  >
                    {district.map((data: any) => (
                      <IonSelectOption key={data.dis_id} value={data.dis_id}>
                        {data.dis_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem> */}
                <IonItem>
                  <select
                    className="form-control"
                    style={{ cursor: "pointer" }}
                    placeholder="Pilih Kelurahan"
                    value={shipping_subdis}
                    onChange={(e) => {
                      setShipping_subdis(e.target.value);
                      getPostals(parseInt(e.target.value));
                    }}
                  >
                    {subdistrict.map((data: any) => (
                      <option key={data.subdis_id} value={data.subdis_id}>
                        {data.subdis_name}
                      </option>
                    ))}
                  </select>
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kelurahan"
                    interface="action-sheet"
                    value={shipping_subdis}
                    onIonChange={(e) => {
                      setShipping_subdis(e.detail.value);
                      getPostals(e.detail.value);
                    }}
                  >
                    {subdistrict.map((data: any) => (
                      <IonSelectOption
                        key={data.subdis_id}
                        value={data.subdis_id}
                      >
                        {data.subdis_name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem> */}
                <IonItem>
                  <select
                    className="form-control"
                    style={{ cursor: "pointer" }}
                    placeholder="Pilih Kode Pos"
                    value={shipping_zip}
                    onChange={(e) => {
                      setShipping_zip(e.target.value);
                    }}
                  >
                    {postal.map((data: any) => (
                      <option key={data.postal_code} value={data.postal_code}>
                        {data.postal_code}
                      </option>
                    ))}
                  </select>
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="floating"></IonLabel>
                  <IonSelect
                    placeholder="Pilih Kode Pos"
                    interface="action-sheet"
                    value={shipping_zip}
                    onIonChange={(e) => {
                      setShipping_zip(e.detail.value);
                    }}
                  >
                    {postal.map((data: any) => (
                      <IonSelectOption
                        key={data.postal_code}
                        value={data.postal_code}
                      >
                        {data.postal_code}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem> */}
              </IonList>
              <div className="ion-padding">
                <IonButton
                  style={{ marginBottom: 5 }}
                  color="primary"
                  disabled={
                    shipping_name === "" ||
                    shipping_pic_name === "" ||
                    shipping_pic_phone === "" ||
                    shipping_address === "" ||
                    shipping_city === "" ||
                    shipping_province === "" ||
                    shipping_dis === "" ||
                    shipping_subdis === "" ||
                    shipping_zip === ""
                  }
                  expand="block"
                  onClick={() => {
                    updatePengiriman(match.params.id, {
                      shipping_name: shipping_name,
                      pic_name: shipping_pic_name,
                      pic_phone: shipping_pic_phone,
                      shipping_address: shipping_address,
                      shipping_province: shipping_province,
                      shipping_city: shipping_city,
                      shipping_dis: shipping_dis,
                      shipping_subdis: shipping_subdis,
                      zip_code: shipping_zip,
                    });
                  }}
                  type="submit"
                  className="fab-h"
                >
                  <Icon.Save />
                  &nbsp;&nbsp;
                  <IonText>SIMPAN</IonText>
                </IonButton>
                <IonButton
                  color="danger"
                  expand="block"
                  onClick={() => {
                    deletePengiriman(match.params.id);
                  }}
                  type="submit"
                  className="fab-h"
                >
                  <Icon.Trash />
                  &nbsp;&nbsp;
                  <IonText>HAPUS</IonText>
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(EditAlamat);
