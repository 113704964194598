import {
  IonCard,
  IonImg,
  IonRippleEffect,
  IonSkeletonText,
} from "@ionic/react";
import React, { memo } from "react";
import { Img } from "react-image";
import NumberFormat from "react-number-format";
import StarRatingComponent from "react-star-rating-component";
import BuyButtonCard from "./BuyButtonCard";
import ProgressBar from "./ProgressBar";

declare const window: any;
const ItemCard = (props: any) => {
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const price_type = localStorage.getItem("price_type") || "sellprice";

  return (
    <div
      key={props.i.itemid.toString()}
      className="ion-activatable ripple-parent"
    >
      <IonCard className="m-2">
        <IonRippleEffect></IonRippleEffect>
        {Number(props.i.stock_loc || props.i.stock) === 0 && (
          <div
            className="ion-justify-content-center ion-align-items-center"
            style={{
              position: "absolute",
              backgroundColor: "rgb(0,0,0,0.3)",
              width: "100%",
              height: "100%",
              zIndex: 3,
              paddingTop: "30%",
            }}
            onClick={() => {
              props.history.push("/product/" + props.i.itemid);
            }}
          >
            <IonImg src={require("../assets/images/habis.png")} />
          </div>
        )}
        <Img
          onClick={() => {
            props.history.push("/product/" + props.i.itemid);
          }}
          container={(children) => {
            return <div className="ratio-square">{children}</div>;
          }}
          src={isChrome ? props.i.pic_url : props.i.pic_url_ios}
          loader={
            <>
              {props.i.stock_loc === "0" && (
                <div
                  className="ion-justify-content-center ion-align-items-center"
                  style={{
                    position: "absolute",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    width: "100%",
                    height: "100%",
                    zIndex: 3,
                    paddingTop: "30%",
                    marginTop: 15,
                  }}
                >
                  {/* <IonImg src={require("../assets/images/habis.png")} /> */}
                </div>
              )}
              <IonSkeletonText
                animated
                className="ratio-square"
                style={{ marginTop: -15 }}
              />
            </>
          }
          unloader={
            <img
              src={require("../assets/images/default.png")}
              alt="no-pic"
              width="100%"
            />
          }
        />
        {props.i.info && <div className="ribbon-product">{props.i.info}</div>}
        <div
          className="product-caption"
          onClick={() => {
            props.history.push("/product/" + props.i.itemid);
          }}
        >
          <p className="product-name">{props.i.online_name}</p>
          <div>
            <StarRatingComponent
              editing={false}
              name="rate"
              starCount={5}
              emptyStarColor="#E6E6E6"
              value={Number(props.i.comment_avg)}
            />
            &nbsp;
            {props.i.comment_count && (
              <span style={{ fontSize: 12 }}>({props.i.comment_count})</span>
            )}
          </div>
          <span className="price-regular">
            <NumberFormat
              value={
                price_type !== "sellprice" && props.i[price_type] > 0
                  ? props.i[price_type]
                  : props.i.price
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp. "}
            />
          </span>
        </div>
        <div
          className="price"
          style={{ paddingLeft: 8, paddingRight: 8, height: 20 }}
        >
          {props.i.price_old > 0 && (
            <NumberFormat
              value={props.i.price_old}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp. "}
              style={{
                textDecoration: "line-through",
                fontSize: 11,
              }}
            />
          )}
        </div>
        <div style={{ paddingLeft: 8, paddingRight: 8, height: 20 }}>
          {props.i.max_stock && Number(props.i.max_stock) > 0 && (
            <ProgressBar
              stock={props.i.stock_loc}
              full={props.i.max_stock}
              reverse={true}
            />
          )}
        </div>
        <BuyButtonCard
          item={props.i}
          style={{
            padding: 5,
            textAlign: "center",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        />
      </IonCard>
    </div>
  );
};

export default memo(ItemCard);
