import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import { isEmail } from "../helpers/validation";
import "./Page.css";

interface ForgetPasswordProps extends RouteComponentProps<{}> {}

const ForgetPassword: React.FC<ForgetPasswordProps> = ({ match, history }) => {
  const [progress, setProgress] = useState<any>(false);
  const [email, setEmail] = useState<any>(null);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [emailValid, setEmailValid] = useState<boolean>(true);

  const send = () => {
    setProgress(true);
    axios
      .post(`${API}/forgot/`, qs.stringify({ email }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((res) => {
        setProgress(false);
        if (res.data.success) {
          setToastMsg(res.data.message);
          setShowToast(true);
          history.push("/login");
        } else {
          setToastMsg(res.data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  return (
    <IonPage id="forgetPassword">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="4" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  marginTop: 60,
                  paddingBottom: 16,
                  paddingLeft: 32,
                  paddingRight: 32,
                }}
                className="ion-text-center"
              >
                <img
                  alt="a"
                  width="50%"
                  src={require(`../assets/images/harnic.svg`)}
                />
              </div>
              <IonList lines="full" className="ion-no-margin ion-padding">
                <>
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      placeholder="user@email.com"
                      inputMode="email"
                      value={email}
                      onIonChange={(e) => {
                        isEmail(e.detail.value)
                          ? setEmailValid(true)
                          : setEmailValid(false);
                        setEmail(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                  {!emailValid && (
                    <IonText
                      style={{
                        color: "#DC3545",
                        fontWeight: "bold",
                        marginLeft: 15,
                        fontSize: 13,
                      }}
                    >
                      Email Tidak Valid!
                    </IonText>
                  )}
                </>
              </IonList>

              <div className="ion-padding">
                <IonButton
                  expand="block"
                  disabled={
                    email === "" || isEmail(email) === false ? true : false
                  }
                  onClick={() => send()}
                  type="submit"
                  className="ion-no-margin"
                >
                  Kirim Link Reset
                </IonButton>
              </div>
              <div
                onClick={() => {
                  history.push("/login");
                }}
                style={{ color: "#1100BB", textAlign: "center", fontSize: 12 }}
              >
                Kembali ke Login
              </div>
            </IonCol>
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default memo(ForgetPassword);
