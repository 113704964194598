import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
  IonIcon,
  IonText,
} from "@ionic/react";
import axios from "axios";
import { checkmarkCircleOutline, timeOutline } from "ionicons/icons";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

interface UserActivationProps extends RouteComponentProps<{ props: any }> {}

const UserActivation: React.FC<UserActivationProps> = ({ match, history }) => {
  const [progress, setProgress] = useState<any>(false);
  const [statusCode, setStatusCode] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const getUser = async () => {
    setProgress(true);
    axios
      .get(`${API}/user/${user_id}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res: any) => {
        setProgress(false);
        setStatusCode(res.data.status_code);
        setEmail(res.data.user_email);
      })

      .catch((err) => {
        setProgress(false);
        console.error(err);
      });
  };

  const sendVerif = async () => {
    axios
      .get(`${API}/user/resendVerif/${user_id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((res: any) => {
        history.push("/user/resendactivation");
      })

      .catch((err) => {
        console.error(err);
      });
  };

  useIonViewWillEnter(() => {
    getUser();
  }, []);

  return (
    <IonPage id="useractivation">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>User Activation</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {statusCode === "1" ? (
                <>
                  <div style={{ textAlign: "center", marginTop: 30 }}>
                    <IonIcon
                      style={{
                        color: "#01A51E",
                        fontSize: 100,
                        marginHorizontal: 5,
                      }}
                      icon={checkmarkCircleOutline}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: 10 }}>
                    <IonText style={{ fontSize: 30, marginHorizontal: 5 }}>
                      Akun Anda Sudah Diverifikasi
                    </IonText>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ textAlign: "center", marginTop: 30 }}>
                    <IonIcon
                      style={{
                        color: "#FBC324",
                        fontSize: 100,
                        marginHorizontal: 5,
                      }}
                      icon={timeOutline}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: 10 }}>
                    <IonText style={{ fontSize: 25, marginHorizontal: 5 }}>
                      Akun Anda Belum Diverifikasi <br />
                    </IonText>
                  </div>
                  <div style={{ textAlign: "center", marginTop: 10 }}>
                    <IonText style={{ fontSize: 15, marginHorizontal: 5 }}>
                      Email anda saat ini: <br />
                      {email}
                    </IonText>
                  </div>
                  <div className="ion-padding">
                    <IonButton
                      expand="block"
                      onClick={() => sendVerif()}
                      type="submit"
                      className="ion-no-margin"
                    >
                      KIRIM EMAIL VERIFIKASI
                    </IonButton>
                  </div>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(UserActivation);
