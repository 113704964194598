import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import { isValid } from "../helpers/passwordValidation";
import MainTabs from "../MainTabs";
import "./Page.css";

interface UserPasswordProps extends RouteComponentProps<{ props: any }> {}

const UserPassword: React.FC<UserPasswordProps> = ({ match, history }) => {
  const [progress, setProgress] = useState<any>(false);
  const [password, setPassword] = useState<any>(null);
  const [confpassword, setConfPassword] = useState<any>(null);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const UpdatePassword = async () => {
    var data = confpassword;
    var crypto = require("crypto");
    var pwhash = crypto.createHash("md5").update(data).digest("hex");
    setProgress(true);
    axios
      .put(
        `${API}/user/${user_id}`,
        {
          user_password: pwhash,
        },
        {
          headers: {
            token: user_token,
          },
        }
      )
      .then((res: any) => {
        setToastMsg("Password Berhasil Dirubah");
        setShowToast(true);
        history.push("/user");
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  return (
    <IonPage id="userpassword">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Ubah Data</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <IonList lines="full" className="ion-no-margin ion-padding">
                <>
                  <IonItem>
                    <IonLabel position="floating">Password Baru</IonLabel>
                    <IonInput
                      placeholder="********"
                      type="password"
                      value={password}
                      onIonChange={(e) => {
                        setPassword(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Ulangi Password</IonLabel>
                    <IonInput
                      placeholder="********"
                      type="password"
                      value={confpassword}
                      onIonChange={(e) => {
                        setConfPassword(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                  {isValid(password, confpassword) === false && (
                    <IonText
                      style={{
                        color: "#DC3545",
                        fontWeight: "bold",
                        marginLeft: 15,
                        fontSize: 13,
                      }}
                    >
                      Password Tidak Sama!
                    </IonText>
                  )}
                </>
              </IonList>
              <div className="ion-padding">
                <IonButton
                  disabled={
                    !password ||
                    !confpassword ||
                    isValid(password, confpassword) === false
                      ? true
                      : false
                  }
                  expand="block"
                  onClick={() => UpdatePassword()}
                  type="submit"
                  className="ion-no-margin"
                >
                  UBAH PASSWORD
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(UserPassword);
