import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { chevronDownOutline, close } from "ionicons/icons";
import Steps, { Step } from "rc-steps";
import React, { lazy, Suspense, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Countdown from "react-countdown";
import NumberFormat from "react-number-format";
import "../../node_modules/rc-steps/assets/index.css";
import ItemReview from "../components/ItemReview";
import { API } from "../helpers/api";
import { transactionStep } from "../helpers/transactionStep";
import MainTabs from "../MainTabs";
import "./Page.css";
const TransactionItem = lazy(() => import("../components/TransactionItem"));

interface CountRender {
  hours: any;
  minutes: any;
  seconds: any;
  completed: any;
}
declare const window: any;
const Transaction = (props: any) => {
  const [hdr, setHdr] = useState<any>([]);
  const [dtl, setDtl] = useState<any>([]);
  const [tracking, setTracking] = useState<any>([]);
  const [reviewItems, setReviewItems] = useState<any>([]);
  const [codSupp, setCodSupp] = useState<any>();
  const [payment, setPayment] = useState<any>();
  const [method, setMethods] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showAddr, setShowAddr] = useState(false);

  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  const getTrx = (user: any, trx: any) => {
    axios.get(`${API}/trx/${user}/${trx}`).then((res) => {
      setHdr(res.data.header);
      setDtl(res.data.detail);
      setTracking(res.data.tracking);
      setCodSupp(res.data.cod_support);
    });
  };
  const trxReceived = (trx: any) => {
    setProcessing(true);
    axios
      .post(`${API}/trx/updateWithTrxNo/${trx}`, {
        status: 6,
      })
      .then((res) => {
        setProcessing(false);

        setToastMsg("Terimakasih, jangan lupa untuk memberi ulasan");
        setShowToast(true);
        getTrx(props.match.params.user, props.match.params.trx);
      })
      .catch((e) => {
        setToastMsg(e.message);
        setProcessing(false);
        setShowToast(true);
      });
  };
  const trxReview = (trx: any) => {
    setProcessing(true);

    axios
      .get(`${API}/trx/getItemToReview/${trx}`)
      .then((res) => {
        setProcessing(false);
        setReviewItems(res.data);
        setShowModal3(true);
      })
      .catch((e) => {
        setToastMsg(e.message);
        setProcessing(false);
        setShowToast(true);
      });
  };

  const reBuy = (user: any, trx: any) => {
    setProcessing(true);

    axios
      .get(`${API}/trx/rebuy/${user}/${trx}`)
      .then((res) => {
        if (res.data.message) {
          setToastMsg("Produk ditambahkan ke Keranjang");
          setProcessing(false);
          setShowToast(true);
          let param = {
            type: "goToRoute",
            id: "CartLanding",
          };
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(param));
          } else {
            props.history.push("/cart");
          }
        } else {
          setToastMsg("Coba lagi dalam beberapa saat");
          setProcessing(false);
          setShowToast(true);
        }
      })
      .catch((e) => {
        setToastMsg(e.message + ", Coba beberapa saat lagi");
        setProcessing(false);
        setShowToast(true);
      });
  };

  const getPaymentStatus = () => {
    switch (hdr.payment_method) {
      case "1":
        setPayment({
          method: "Bank Transfer",
          total: hdr.total,
          bank: "BCA",
          rekno: "3979789999",
          time: hdr.orderdate,
        });
        setShowModal2(true);
        break;
      case "2":
        let reff = hdr.reff_bank;
        let code = reff.split(";");
        let bank = "";
        switch (code[1]) {
          case "BMRI":
            bank = "Bank Mandiri";
            break;
          case "IBBK":
            bank = "Maybank";
            break;
          case "BBBA":
            bank = "Bank Permata";
            break;
          case "BNIN":
            bank = "BNI";
            break;
          case "HNBN":
            bank = "KEB Hana";
            break;
          case "BRIN":
            bank = "Bank BRI";
            break;
          case "BNIA":
            bank = "CIMB Niaga";
            break;
          case "BDIN":
            bank = "Bank Danamon";
            break;

          default:
            break;
        }
        setPayment({
          method: "Virtual Account",
          total: hdr.total,
          bank: bank,
          rekno: hdr.payment_va,
          time: hdr.orderdate,
        });
        setShowModal2(true);
        break;
      case "5":
        setPayment({
          method: "Virtual Account",
          bank: "BCA",
          rekno: "12105" + hdr.payment_va,
          time: hdr.orderdate,
        });
        setShowModal2(true);
        break;

      default:
    }
  };
  const getPaymentMethods = () => {
    axios.get(`${API}/trx/getPaymentMethods`).then((res) => {
      setMethods(res.data);
    });
  };
  const cancelTrx = (salesid: any) => {
    setProcessing(true);
    axios
      .post(`${API}/trx/cancel/${salesid}`)
      .then((res) => {
        setToastMsg(res.data.message);
        setProcessing(false);
        setShowToast(true);
        getTrx(props.match.params.user, props.match.params.trx);
      })
      .catch((e) => {
        setToastMsg(e.message);
        setProcessing(false);
        setShowToast(true);
      });
  };

  const _renderSkeleton = () => {
    return (
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>
              <IonSkeletonText animated style={{ width: "30%" }} />
              <IonSkeletonText animated style={{ width: "10%" }} />
            </IonLabel>
            <IonThumbnail>
              <IonSkeletonText animated />
            </IonThumbnail>
          </IonItem>
        </IonList>
        <IonListHeader>
          <IonSkeletonText animated style={{ width: "30%" }} />
        </IonListHeader>
        <IonList>
          <IonItem>
            <IonThumbnail slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel>
              <h3>
                <IonSkeletonText animated style={{ width: "50%" }} />
              </h3>
              <p>
                <IonSkeletonText animated style={{ width: "80%" }} />
              </p>
              <p>
                <IonSkeletonText animated style={{ width: "60%" }} />
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonThumbnail slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel>
              <h3>
                <IonSkeletonText animated style={{ width: "50%" }} />
              </h3>
              <p>
                <IonSkeletonText animated style={{ width: "80%" }} />
              </p>
              <p>
                <IonSkeletonText animated style={{ width: "60%" }} />
              </p>
            </IonLabel>
          </IonItem>
        </IonList>

        <IonListHeader>
          <IonSkeletonText animated style={{ width: "30%" }} />
        </IonListHeader>
        <IonCard>
          <IonCardContent style={{ padding: 4 }}>
            <IonList>
              <IonItem>
                <IonSkeletonText animated style={{ width: "30%" }} />
                <span slot="end">
                  <IonSkeletonText animated style={{ width: "10%" }} />
                </span>
              </IonItem>
              <IonItem>
                <IonSkeletonText animated style={{ width: "30%" }} />
                <IonText slot="end">
                  <IonSkeletonText animated style={{ width: "30%" }} />
                </IonText>
              </IonItem>
              <IonItem>
                <IonSkeletonText animated style={{ width: "30%" }} />
                <IonText slot="end">
                  <IonSkeletonText animated style={{ width: "30%" }} />
                </IonText>
              </IonItem>
              <IonItem lines="none">
                <IonSkeletonText animated style={{ width: "30%" }} />
                <IonText slot="end">
                  <IonSkeletonText animated style={{ width: "30%" }} />
                </IonText>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonListHeader>
          <IonSkeletonText animated style={{ width: "30%" }} />
        </IonListHeader>
        <IonList>
          <IonItem>
            <IonSkeletonText animated style={{ width: "30%" }} />
            <IonSkeletonText animated style={{ width: "30%" }} />
          </IonItem>
          <IonItem>
            <IonSkeletonText animated style={{ width: "30%" }} />
            <IonText slot="end">
              <IonSkeletonText animated style={{ width: "30%" }} />
            </IonText>
          </IonItem>
          <IonItem>
            <IonSkeletonText animated style={{ width: "30%" }} />
            <IonText slot="end">
              <IonSkeletonText animated style={{ width: "30%" }} />
            </IonText>
          </IonItem>
          <IonItem>
            <IonSkeletonText animated style={{ width: "30%" }} />
            <IonText slot="end">
              <IonSkeletonText animated style={{ width: "30%" }} />
            </IonText>
          </IonItem>
          <IonItem>
            <IonSkeletonText animated style={{ width: "30%" }} />
            <IonText slot="end" style={{ fontWeight: "bold" }}>
              <IonSkeletonText animated style={{ width: "30%" }} />
            </IonText>
          </IonItem>
        </IonList>
      </IonContent>
    );
  };

  useIonViewWillEnter(() => {
    getTrx(props.match.params.user, props.match.params.trx);
  }, [props.match.params]);

  // Renderer callback with condition
  const renderer: React.FC<CountRender> = ({
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <div
          className="ion-text-center ion-activatable ripple-parent"
          style={{
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <span
            style={{
              fontSize: 50,
              color: "#555",
            }}
          >
            {minutes}:{seconds}
          </span>
        </div>
      );
    }
  };

  return (
    <IonPage id="transaction-page">
      {!window.ReactNativeWebView && (
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      {hdr ? (
        <>
          <IonContent>
            <IonGrid className="p-0 m-0">
              <IonRow className="p-0 m-0">
                <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
                <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
                  <IonList>
                    <IonItem>
                      <IonLabel>
                        <div style={{ fontWeight: "bold" }}>
                          Pesanan #{hdr.trxno}
                        </div>
                        <small>Status: {hdr.stat}</small>
                      </IonLabel>
                      <IonThumbnail>
                        {hdr.status === "0" ? (
                          <IonImg src={require("../assets/images/new.png")} />
                        ) : hdr.status === "1" ? (
                          <IonImg
                            src={require("../assets/images/checking.png")}
                          />
                        ) : hdr.status === "2" ? (
                          <IonImg
                            src={require("../assets/images/checked.png")}
                          />
                        ) : hdr.status === "3" ? (
                          <IonImg
                            src={require("../assets/images/checked.png")}
                          />
                        ) : hdr.status === "4" ? (
                          <IonImg
                            src={require("../assets/images/delivery.png")}
                          />
                        ) : hdr.status === "5" ||
                          hdr.status === "6" ||
                          hdr.status === "7" ? (
                          <IonImg
                            src={require("../assets/images/received.png")}
                          />
                        ) : (
                          <IonImg
                            src={require("../assets/images/warning.png")}
                          />
                        )}
                      </IonThumbnail>
                    </IonItem>
                  </IonList>
                  {hdr.trx_note && (
                    <IonCard>
                      <IonCardContent>
                        <IonLabel>
                          <small>Catatan:</small>
                          <br />
                          {hdr.trx_note}
                        </IonLabel>
                      </IonCardContent>
                    </IonCard>
                  )}
                  <IonListHeader>
                    <IonLabel>Belanjaan Anda</IonLabel>
                  </IonListHeader>
                  <IonList>
                    {dtl &&
                      dtl.map((i: any) => (
                        <Suspense
                          key={i.itemid}
                          fallback={
                            <IonSkeletonText animated style={{ height: 100 }} />
                          }
                        >
                          <TransactionItem i={i} {...props} />
                        </Suspense>
                      ))}
                  </IonList>
                  <div style={{ position: "relative" }}>
                    <IonListHeader>
                      <IonLabel>Status Pesanan</IonLabel>
                    </IonListHeader>
                    <IonButton
                      color={tracking.length === 0 ? "light" : "primary"}
                      disabled={tracking.length === 0 || Number(hdr.status) > 7}
                      style={{
                        position: "absolute",
                        right: 16,
                        bottom: 0,
                        zIndex: 2,
                      }}
                      size="small"
                      onClick={() => {
                        if (tracking) {
                          setShowModal4(true);
                        } else {
                          setToastMsg("Tracking belum tersedia");
                          setShowToast(true);
                        }
                      }}
                    >
                      Tracking
                    </IonButton>
                  </div>
                  <IonList>
                    <IonItem style={{ marginTop: 4 }}>
                      <Steps
                        labelPlacement="vertical"
                        direction="vertical"
                        current={
                          hdr.status === "8" || hdr.status === "9"
                            ? 1
                            : transactionStep(hdr.status)
                        }
                      >
                        <Step
                          title="Checkout"
                          description="Pesanan anda telah kami terima"
                        />
                        {hdr.status === "8" || hdr.status === "9" ? (
                          <Step
                            title="Dibatalkan"
                            description="Pesanan tidak diproses"
                          />
                        ) : (
                          <>
                            <Step
                              title="Dibayar"
                              description="Pembayaran telah diverifikasi sistem"
                            />
                            <Step
                              title="Disiapkan"
                              description="Pesanan sedang disiapkan"
                            />
                            <Step
                              title="Dikirim"
                              description="Paket anda sedang dibawa oleh kurir kami"
                            />
                            <Step
                              title="Tiba"
                              description="Pesanan telah sampai"
                            />
                            <Step
                              title="Diterima"
                              description="Pesanan telah dikonfirmasi terima"
                            />
                          </>
                        )}
                      </Steps>
                    </IonItem>
                  </IonList>
                  <IonListHeader>
                    <IonLabel>Detail Pengiriman</IonLabel>
                  </IonListHeader>
                  <IonCard>
                    <IonCardContent style={{ padding: 4 }}>
                      <IonList>
                        <IonItem>
                          <IonLabel>Penerima</IonLabel>
                          <span slot="end">
                            <IonButton
                              onClick={() => setShowAddr(true)}
                              size="small"
                              color="light"
                            >
                              {hdr.custname}
                              <IonIcon slot="end" icon={chevronDownOutline} />
                            </IonButton>
                          </span>
                          <IonAlert
                            isOpen={showAddr}
                            onDidDismiss={() => setShowAddr(false)}
                            cssClass="my-custom-class"
                            header={hdr.custname}
                            subHeader={hdr.phone}
                            message={hdr.ec_shipping_address}
                            buttons={["Close"]}
                          />
                        </IonItem>
                        <IonItem>
                          <IonLabel>Kurir</IonLabel>
                          <IonText slot="end">{hdr.namaekspedisi}</IonText>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Jenis</IonLabel>
                          <IonText slot="end">{hdr.shipping}</IonText>
                        </IonItem>
                        <IonItem lines="none">
                          <IonLabel>Waktu Terima</IonLabel>
                          <IonText slot="end">{hdr.ec_bracket_end}</IonText>
                        </IonItem>
                      </IonList>
                    </IonCardContent>
                  </IonCard>

                  <IonListHeader>
                    <IonLabel>Informasi Pembayaran</IonLabel>
                  </IonListHeader>
                  <IonList>
                    <IonItem>
                      <IonLabel>Metode Pembayaran</IonLabel>
                      <IonText slot="end">{hdr.payment_method_desc}</IonText>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Total Harga</IonLabel>
                      <IonText slot="end">
                        <NumberFormat
                          value={hdr.netsales}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp. "}
                        />
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Diskon</IonLabel>
                      <IonText slot="end">
                        <NumberFormat
                          value={hdr.discount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp. "}
                        />
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        color={hdr.freeongkir === "1" ? "success" : "dark"}
                      >
                        Ongkos Kirim
                      </IonLabel>
                      <IonText
                        slot="end"
                        color={hdr.freeongkir === "1" ? "success" : "dark"}
                      >
                        {hdr.freeongkir === "1" ? (
                          "GRATIS"
                        ) : (
                          <NumberFormat
                            value={hdr.ongkir}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Rp. "}
                          />
                        )}
                      </IonText>
                    </IonItem>
                    <IonItem>
                      <IonLabel style={{ fontWeight: "bold" }}>
                        Total bayar
                      </IonLabel>
                      <IonText slot="end" style={{ fontWeight: "bold" }}>
                        <NumberFormat
                          value={hdr.total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp. "}
                        />
                      </IonText>
                    </IonItem>
                  </IonList>
                </IonCol>
                <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
              </IonRow>
            </IonGrid>
          </IonContent>
          <div className="ion-padding">
            {hdr.status === "0" && hdr.netsales > 0 ? (
              <>
                <IonButton
                  disabled={processing}
                  expand="block"
                  onClick={() => {
                    getPaymentMethods();
                    setShowModal(true);
                  }}
                >
                  BAYAR
                </IonButton>
                <IonButton
                  disabled={processing}
                  color="danger"
                  expand="block"
                  onClick={() => {
                    cancelTrx(hdr.salesid);
                  }}
                >
                  {processing ? <IonSpinner name="dots" /> : "BATALKAN PESANAN"}
                </IonButton>
              </>
            ) : hdr.status === "1" ? (
              <IonButton
                disabled={processing}
                expand="block"
                onClick={() => {
                  getPaymentStatus();
                }}
              >
                SELESAIKAN PEMBAYARAN
              </IonButton>
            ) : hdr.status === "5" ? (
              <IonButton
                disabled={processing}
                expand="block"
                onClick={() => {
                  trxReceived(hdr.trxno);
                }}
              >
                {processing ? <IonSpinner name="dots" /> : "KONFIRMASI TERIMA"}
              </IonButton>
            ) : hdr.status === "6" ? (
              <IonButton
                disabled={processing}
                expand="block"
                onClick={() => {
                  trxReview(hdr.trxno);
                }}
              >
                {processing ? <IonSpinner name="dots" /> : "BERI ULASAN"}
              </IonButton>
            ) : null}

            {Number(hdr.status) > 1 && (
              <IonButton
                disabled={processing}
                expand="block"
                onClick={() => {
                  reBuy(hdr.ec_user_id, hdr.trxno);
                }}
              >
                {processing ? <IonSpinner name="dots" /> : "BELI LAGI"}
              </IonButton>
            )}
          </div>
          <IonModal isOpen={showModal} cssClass="my-custom-class">
            <IonContent>
              <IonListHeader>
                <IonLabel>Metode Pembayaran</IonLabel>
                <IonButton color="danger" onClick={() => setShowModal(false)}>
                  <IonIcon slot="end" icon={close} />
                </IonButton>
              </IonListHeader>
              <IonList>
                {method ? (
                  method.map((i: any) => (
                    <>
                      {i.payment_code !== "cod" ? (
                        <IonItem
                          disabled={i.status === "0"}
                          key={i.payment_id.toString()}
                          onClick={() => setShowModal(false)}
                          routerLink={
                            i.status === "0"
                              ? ""
                              : `/transaction/${props.match.params.user}/${props.match.params.trx}/payment/${i.payment_code}`
                          }
                        >
                          <IonLabel>{i.payment_name}</IonLabel>
                          {i.status === "0" && (
                            <small>Sedang Maintenance</small>
                          )}
                        </IonItem>
                      ) : (
                        Number(codSupp) === 1 && (
                          <IonItem
                            disabled={i.status === "0"}
                            key={i.payment_id.toString()}
                            onClick={() => setShowModal(false)}
                            routerLink={
                              i.status === "0"
                                ? ""
                                : `/transaction/${props.match.params.user}/${props.match.params.trx}/payment/${i.payment_code}`
                            }
                          >
                            <IonLabel>{i.payment_name}</IonLabel>
                            {i.status === "0" && (
                              <small>Sedang Maintenance</small>
                            )}
                          </IonItem>
                        )
                      )}
                    </>
                  ))
                ) : (
                  <>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem lines="none">
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                  </>
                )}
              </IonList>
            </IonContent>
          </IonModal>

          <IonModal isOpen={showModal2} cssClass="my-custom-class">
            <IonContent>
              <IonListHeader>
                <IonLabel>Status Pembayaran</IonLabel>
                <IonButton color="danger" onClick={() => setShowModal2(false)}>
                  <IonIcon slot="end" icon={close} />
                </IonButton>
              </IonListHeader>
              <IonList>
                {payment ? (
                  <>
                    <Countdown
                      date={Date.parse(payment.time + " GMT+7") + 3600000 * 2}
                      renderer={renderer}
                    >
                      <div>Pembayaran Kadaluarsa</div>
                    </Countdown>
                    <IonCard>
                      <IonCardContent className="p-10 ion-text-center">
                        <div>Silahkan transfer senilai:</div>
                        <div style={{ margin: 16 }}>
                          <IonCardTitle>
                            <NumberFormat
                              value={payment.total}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"Rp. "}
                            />
                            <CopyToClipboard
                              text={payment.total}
                              onCopy={() => {
                                setToastMsg("Disalin ke clipboard");
                                setShowToast(true);
                              }}
                            >
                              <IonBadge
                                color="success"
                                style={{ marginLeft: 4 }}
                              >
                                SALIN
                              </IonBadge>
                            </CopyToClipboard>
                          </IonCardTitle>
                        </div>
                        <div style={{ margin: 16 }}>
                          <small>
                            {payment.bank} {payment.method}
                          </small>
                          <IonCardTitle>
                            {payment.rekno}
                            <CopyToClipboard
                              text={payment.rekno}
                              onCopy={() => {
                                setToastMsg("Disalin ke clipboard");
                                setShowToast(true);
                              }}
                            >
                              <IonBadge
                                color="success"
                                style={{ marginLeft: 4 }}
                              >
                                SALIN
                              </IonBadge>
                            </CopyToClipboard>
                          </IonCardTitle>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </>
                ) : (
                  <>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem lines="none">
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                  </>
                )}
              </IonList>
            </IonContent>
          </IonModal>

          <IonModal isOpen={showModal3} cssClass="my-custom-class">
            <IonContent>
              <IonListHeader>
                <IonLabel>Beri Ulasan</IonLabel>
                <IonButton color="danger" onClick={() => setShowModal3(false)}>
                  <IonIcon slot="end" icon={close} />
                </IonButton>
              </IonListHeader>
              <IonList lines="full">
                {reviewItems ? (
                  <>
                    {reviewItems &&
                      reviewItems.map((i: any) => (
                        <ItemReview item={i} trx={hdr} />
                      ))}
                  </>
                ) : (
                  <>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem lines="none">
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                  </>
                )}
              </IonList>
            </IonContent>
          </IonModal>

          <IonModal isOpen={showModal4} cssClass="my-custom-class">
            <IonContent>
              <IonListHeader>
                <IonLabel>Harnic Xpress Tracking</IonLabel>
                <IonButton color="danger" onClick={() => setShowModal4(false)}>
                  <IonIcon slot="end" icon={close} />
                </IonButton>
              </IonListHeader>
              <IonList lines="full">
                {tracking ? (
                  <>
                    {tracking &&
                      tracking.map((i: any) => (
                        <IonItem key={i.timestamp.toString()}>
                          <IonLabel className="ion-text-wrap">
                            <h2>{i.type}</h2>
                            <h3>{i.droppoint}</h3>
                            <p>{i.timestamp}</p>
                            {i.photo && (
                              <img
                                alt={i.timestamp}
                                height="300px"
                                src={i.photo}
                              />
                            )}
                          </IonLabel>
                        </IonItem>
                      ))}
                  </>
                ) : (
                  <>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                    <IonItem lines="none">
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </IonItem>
                  </>
                )}
              </IonList>
            </IonContent>
          </IonModal>
        </>
      ) : (
        _renderSkeleton()
      )}
      {!window.ReactNativeWebView && <MainTabs />}
    </IonPage>
  );
};

export default Transaction;
