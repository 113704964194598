import React, { memo } from "react";
import "./Comps.css";

interface PageComponentProps {
  info: any;
}

const PageComponent: React.FC<PageComponentProps> = ({ info }) => {
  return (
    <>
      <h2 style={{ fontWeight: "bold", paddingLeft: 10, paddingRight: 10 }}>
        {info.page_title}
      </h2>
      <div
        style={{
          fontSize: 14,
          marginTop: 10,
          marginBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        dangerouslySetInnerHTML={{
          __html: info && info.page_content,
        }}
      />
    </>
  );
};

export default memo(PageComponent);
