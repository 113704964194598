import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/react";
import React, { memo } from "react";
import "./Comps.css";

const SkeletonList = (props: any) => {
  const dataMap = [...Array(props.number)];
  return (
    <IonContent>
      <IonGrid className="p-0 m-0">
        <IonRow className="p-0 m-0">
          <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
          <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
            <IonList>
              {dataMap.map((i: any) => (
                <IonItem key={i * i}>
                  <IonThumbnail slot="start">
                    <IonSkeletonText />
                  </IonThumbnail>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default memo(SkeletonList);
