import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import * as Icon from "react-feather";
import "./Page.css";

interface UserDetailProps extends RouteComponentProps<{ props: any }> {}

const UserDetail: React.FC<UserDetailProps> = ({ match, history }) => {
  const [progress, setProgress] = useState<any>(false);
  const [email, setEmail] = useState<any>(null);
  const [username, setUsername] = useState<any>(null);
  const [phone, setPhone] = useState<any>(null);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const getUser = async () => {
    setProgress(true);
    axios
      .get(`${API}/user/${user_id}`, {
        headers: {
          token: user_token,
        },
      })
      .then((res: any) => {
        setProgress(false);
        setUsername(res.data.user_name);
        setEmail(res.data.user_email);
        setPhone(res.data.user_phone);
      })

      .catch((err) => {
        setProgress(false);
        console.error(err);
      });
  };

  useIonViewWillEnter(() => {
    getUser();
  }, []);

  const UpdateUser = async () => {
    setProgress(true);
    axios
      .put(
        `${API}/user/${user_id}`,
        {
          user_name: username,
          user_phone: phone,
        },
        {
          headers: {
            token: user_token,
          },
        }
      )
      .then((res: any) => {
        setToastMsg("Data Updated");
        setShowToast(true);
        history.push("/user");
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  return (
    <IonPage id="userdetail">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Ubah Data</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <IonList lines="full" className="ion-no-margin ion-padding">
                <>
                  <IonItem>
                    <IonLabel position="floating">Nama Pengguna</IonLabel>
                    <IonInput
                      type="text"
                      placeholder="user"
                      value={username}
                      onIonChange={(e) => {
                        setUsername(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">
                      Email (Tidak dapat dirubah)
                    </IonLabel>
                    <IonInput
                      disabled
                      placeholder="user@email.com"
                      inputMode="email"
                      value={email}
                      onIonChange={(e) => {
                        setEmail(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Nomor Telepon/HP</IonLabel>
                    <IonInput
                      type="tel"
                      placeholder="08123 456 768"
                      value={phone}
                      onIonChange={(e) => {
                        setPhone(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                </>
              </IonList>
              <div className="ion-padding">
                <IonButton
                  disabled={!username || !phone}
                  expand="block"
                  onClick={() => UpdateUser()}
                  type="submit"
                  className="fab-h"
                >
                  <Icon.Save />
                  &nbsp;&nbsp;
                  <IonText>SIMPAN</IonText>
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(UserDetail);
