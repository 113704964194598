import axios from "axios";
import qs from "qs";
import { API, APIV3, version } from "./api";

export const addToCart = (item_id: any, qty: any, note: any) => {
  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  let result = axios
    .post(
      `${API}/cart/${user_id}`,
      qs.stringify({
        item_id: item_id,
        qty: qty,
        note: note,
        app_version: version,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: user_token,
        },
      }
    )
    .then((res) => {
      return res.data;
    });

  return result;
};

export const editCart = (item_id: any, qty: any) => {
  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");
  axios.put(
    `${API}/cart/${user_id}/${item_id}`,
    {
      qty: qty,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: user_token,
      },
    }
  );
};

export const deleteCart = (item_id: any) => {
  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");
  let resultDel = axios
    .delete(`${API}/cart/${user_id}/${item_id}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: user_token,
      },
    })
    .then((res) => {
      return res.data;
    });
  return resultDel;
};
