import {
  IonCard,
  IonImg,
  IonRippleEffect,
  IonSkeletonText,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo } from "react";
import { Img } from "react-image";
import { API, version } from "../../helpers/api";

declare const window: any;

const CartItemRecomendNoBtn = (props: any) => {
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const AddToCart = (item_id: any, qty: any, note: any, app_version: any) => {
    const token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .post(
        `${API}/cart/${user_id}`,
        qs.stringify({
          item_id,
          qty,
          note,
          app_version,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            token: token,
          },
        }
      )
      .then((res) => {
        props.refreshItem();
      })
      .catch(() => {});
  };

  const tambahItemBonus = async (
    item_id: any,
    qty: any,
    note: any,
    app_version: any
  ) => {
    const token = await localStorage.getItem("user_token");
    const user_id = await localStorage.getItem("user_id");
    axios.post(
      `${API}/cart/bonus/${user_id}`,
      qs.stringify({ item_id, qty, note, app_version }),
      {
        headers: {
          token: token,
        },
      }
    );
  };

  return (
    <div className="ion-activatable ripple-parent">
      <IonCard
        className="m-2"
        onClick={() => {
          if (Number(props.i.stock_loc) > 0) {
            if (props.is_bonus === true) {
              tambahItemBonus(props.i.itemid, 1, "Bonus Pembelian", version);
            } else {
              AddToCart(props.i.itemid, 1, "", version);
            }
            props.refreshItem();
          }
        }}
      >
        <IonRippleEffect></IonRippleEffect>
        {props.i.stock_loc === "0" && (
          <div
            className="ion-justify-content-center ion-align-items-center"
            style={{
              position: "absolute",
              backgroundColor: "rgb(0,0,0,0.3)",
              width: "100%",
              height: "100%",
              zIndex: 3,
              paddingTop: "30%",
            }}
          >
            <IonImg src={require("../../assets/images/habis.png")} />
          </div>
        )}
        <Img
          container={(children) => {
            return <div className="ratio-square">{children}</div>;
          }}
          src={isChrome ? props.i.pic_url : props.i.pic_url_ios}
          loader={
            <>
              {props.i.stock_loc === "0" && (
                <div
                  className="ion-justify-content-center ion-align-items-center"
                  style={{
                    position: "absolute",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    width: "100%",
                    height: "100%",
                    zIndex: 3,
                    paddingTop: "30%",
                    marginTop: 15,
                  }}
                >
                  {/* <IonImg src={require("../../assets/images/habis.png")} /> */}
                </div>
              )}
              <IonSkeletonText
                animated
                className="ratio-square"
                style={{ marginTop: -15 }}
              />
            </>
          }
          unloader={
            <img
              src={require("../../assets/images/default.png")}
              alt="no-pic"
              width="100%"
            />
          }
        />
      </IonCard>
    </div>
  );
};

export default memo(CartItemRecomendNoBtn);
