import { IonText, IonToast } from "@ionic/react";
import React, { memo, useContext, useState } from "react";
import * as Icon from "react-feather";
import { AppContext } from "../contexts/AppContext";
import { addToCart, deleteCart, editCart } from "../helpers/addToCart";
import { checkLogin } from "../helpers/auth";

declare const window: any;
const BuyButtonCard = (props: any) => {
  const [cartContext, setCartContext] = useContext(AppContext);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const stock = props.item.stock_loc || 999;
  const maxOrder = props.item.maxOrder || 9999;

  const [find, setFind] = useState<any>(
    cartContext.cart_reg.find(
      (e: any) => String(e.itemmst) === String(props.item.itemid)
    )
  );

  const [qty, setQty] = useState<any>(find ? find.qty : 0);
  const [isAdded, setAdded] = useState(find);
  // const [isAdded, setAdded] = useState(false);

  const newCart = () => {
    checkLogin().then((res) => {
      if (res) {
        const newQty = Number(qty) + 1;
        setQty(newQty);
        addToCart(props.item.itemid, newQty, "").then((d) => {
          setToastMsg(d.message);
          setShowToast(true);
          setCartContext(d.data);
        });
        setAdded(true);
      } else {
        window.location.href = "/login";
        setToastMsg("Login Untuk Belanja");
        setShowToast(true);
      }
    });
  };

  const minCart = () => {
    checkLogin().then((res) => {
      if (res) {
        const newQty = Number(qty) - 1;
        if (newQty > 0) {
          setQty(newQty);
          editCart(props.item.itemid, newQty);
          setAdded(true);
        } else {
          setQty(0);
          deleteCart(props.item.itemid).then((d) => {
            setToastMsg(d.message);
            setShowToast(true);
            // const newCount = cartContext.item_count - 1;
            // console.log("count : ", newCount);
            // setCartContext(cartContext.item_count - 1);
          });

          setAdded(false);
        }
      } else {
        window.location.href = "/login";
        setToastMsg("Login Untuk Belanja");
        setShowToast(true);
      }
    });
  };

  const addCart = () => {
    checkLogin().then((res) => {
      if (res) {
        const newQty = Number(qty) + 1;
        setQty(newQty);
        editCart(props.item.itemid, newQty);
        setAdded(true);
      } else {
        window.location.href = "/login";
        setToastMsg("Login Untuk Belanja");
        setShowToast(true);
      }
    });
  };

  const toastMaxOrder = () => {
    setToastMsg("Batas Beli " + maxOrder);
    setShowToast(true);
  };

  const toastStock = () => {
    setToastMsg("Sisa Stock " + stock);
    setShowToast(true);
  };

  return (
    <div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      {Number(props.item.stock_loc || props.item.stock) <= 0 ? (
        <div
          style={Object.assign({}, props.style, {
            backgroundColor: "#eee",
          })}
        >
          <IonText
            style={{
              fontWeight: "bold",
              fontSize: 12,
              color: "#888",
            }}
          >
            STOK HABIS
          </IonText>
        </div>
      ) : isAdded ? (
        <>
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                borderRadius: 5,
                paddingTop: 10,
                marginLeft: 5,
                backgroundColor: "#01A81E",
              }}
              onClick={() => minCart()}
            >
              <Icon.Minus style={{ color: "#fff", paddingTop: 5 }} />
            </span>
            <span>
              <IonText
                style={{
                  fontSize: 20,
                  paddingHorizontal: 10,
                  marginLeft: 15,
                  marginRight: 15,
                }}
              >
                {qty}
              </IonText>
            </span>
            <span
              style={{
                borderRadius: 5,
                paddingTop: 10,
                marginRight: 5,
                backgroundColor:
                  qty < maxOrder && stock > qty ? "#01A81E" : "#ddd",
              }}
              onClick={() => {
                qty < maxOrder
                  ? stock > qty
                    ? addCart()
                    : toastStock()
                  : toastMaxOrder();
              }}
            >
              <Icon.Plus style={{ color: "#fff", paddingTop: 5 }} />
            </span>
          </div>
        </>
      ) : (
        <div
          onClick={() => {
            newCart();
          }}
          style={Object.assign({}, props.style, {
            backgroundColor: "#01A81E",
          })}
        >
          <IonText
            style={{
              fontWeight: "bold",
              fontSize: 12,
              color: "#fff",
            }}
          >
            BELI
          </IonText>
        </div>
      )}
    </div>
  );
};

export default memo(BuyButtonCard);
