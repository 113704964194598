import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSearchbar,
  IonText,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { searchOutline } from "ionicons/icons";
import _ from "lodash";
import qs from "qs";
import React, { memo, useCallback, useRef, useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import { KeywordContext } from "../contexts/AppContext";

interface SearchProps extends RouteComponentProps<{ props: any }> {}
declare const window: any;

const Search: React.FC<SearchProps> = ({ match, history }) => {
  const inputRef = useRef<HTMLIonSearchbarElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [cats, setCats] = useState<any>([]);
  const [keyword, setKeyword] = useState<any>("");
  const [keyContext, setKeyContext] = useContext(KeywordContext);

  const fetchData = async (q: any) => {
    const url = `${API}/product/suggestions/`;
    setLoading(true);
    await axios
      .post(
        url,
        qs.stringify({
          keyword: q,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setItems(res.data.items);

        setBrands(res.data.brands);
        setCats(res.data.cats);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useIonViewWillEnter(() => {
    if (keyContext) {
      setItems(keyContext);
    }
    fetchData("");
    setTimeout(() => {
      focusInput();
    }, 50);
  }, []);

  const delayedQuery = useCallback(
    _.debounce((q: any) => fetchData(q), 500),
    []
  );
  const onChangeHandler = async (e: any) => {
    setKeyword(e);
    await delayedQuery(e);
  };
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    window.location.href =
      "/resultsearch/keyword/" + e.nativeEvent.target[0].value;
  };

  const sendFind = (type: any, id: any) => {
    window.location.href = "/resultsearch/" + type + "/" + id;
  };

  const focusInput = () => {
    const ref: any = inputRef.current;
    ref.setFocus();
  };

  return (
    <IonPage id="search">
      <IonHeader translucent={true}>
        <IonToolbar style={{ marginTop: 10 }}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <form onSubmit={onSubmitHandler}>
            <IonSearchbar
              ref={inputRef}
              value={keyword}
              onIonInput={(e: any) => onChangeHandler(e.target.value)}
              color="light"
              placeholder="Cari Produk"
            ></IonSearchbar>
          </form>
        </IonToolbar>
      </IonHeader>
      {loading && <IonProgressBar type="indeterminate" />}
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {items.length > 0 && (
                <div
                  style={{
                    backgroundColor: "#FEF4C5",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <IonText
                    style={{
                      color: "#393836",
                      fontSize: 15,
                      paddingLeft: 15,
                    }}
                  >
                    Pencarian Populer
                  </IonText>
                </div>
              )}
              {items.map((r: any) => (
                <div
                  key={r.val.toString()}
                  className="ion-activatable ripple-parent"
                  style={{ paddingBottom: 15 }}
                >
                  <IonList>
                    <IonItem onClick={() => sendFind("keyword", r.val)}>
                      <IonText>{r.val}</IonText>
                      <IonIcon slot="end" icon={searchOutline} />
                    </IonItem>
                  </IonList>
                </div>
              ))}
              {cats.length > 0 && (
                <div
                  style={{
                    backgroundColor: "#FEF4C5",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <IonText
                    style={{
                      color: "#393836",
                      fontSize: 15,
                      paddingLeft: 15,
                    }}
                  >
                    Kategori
                  </IonText>
                </div>
              )}
              {cats.map((r: any) => (
                <div
                  key={r.id.toString()}
                  className="ion-activatable ripple-parent"
                  style={{ paddingBottom: 15 }}
                >
                  <IonList>
                    <IonItem onClick={() => sendFind("category", r.id)}>
                      <IonText>{r.val}</IonText>
                      <IonIcon slot="end" icon={searchOutline} />
                    </IonItem>
                  </IonList>
                </div>
              ))}
              {brands.length > 0 && (
                <div
                  style={{
                    backgroundColor: "#FEF4C5",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <IonText
                    style={{
                      color: "#393836",
                      fontSize: 15,
                      paddingLeft: 15,
                    }}
                  >
                    Brand
                  </IonText>
                </div>
              )}
              {brands.map((r: any) => (
                <div
                  key={r.id.toString()}
                  className="ion-activatable ripple-parent"
                  style={{ paddingBottom: 15 }}
                >
                  <IonList>
                    <IonItem onClick={() => sendFind("brand", r.id)}>
                      <IonText>{r.val}</IonText>
                      <IonIcon slot="end" icon={searchOutline} />
                    </IonItem>
                  </IonList>
                </div>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(Search);
