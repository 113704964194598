import React, { createContext, useState } from "react";

export const AppContext = createContext<any>(null);
export const KeywordContext = createContext<any>(null);

export const AppProvider = (props: any) => {
  const [cartContext, setCartContext] = useState<any>({
    item_count: 0,
    bonus: [],
    cart_reg: [],
    cart_dropship: [],
    hasBonus: [],
  });
  const [keyContext, setKeyContext] = useState<any>([]);

  return (
    <AppContext.Provider value={[cartContext, setCartContext]}>
      <KeywordContext.Provider value={[keyContext, setKeyContext]}>
        {props.children}
      </KeywordContext.Provider>
    </AppContext.Provider>
  );
};
