import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  IonSkeletonText,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState, lazy, Suspense } from "react";
import { RouteComponentProps } from "react-router";
import OTPInput from "../components/auth/otp";
import { API } from "../helpers/api";
import "./Page.css";

const CountdownPhone = lazy(() => import("../components/CountdownPhone"));

interface RegisterProps extends RouteComponentProps<{}> {}

const Register: React.FC<RegisterProps> = ({ history }) => {
  const [progress, setProgress] = useState<any>(false);
  const [username, setUsername] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [password, setPassword] = useState<any>(null);
  const [password2, setPassword2] = useState<any>(null);
  const [phone, setPhone] = useState<any>(null);
  const [method, setMethod] = useState<any>("phone");
  const [otp, setOTP] = useState<any>(null);
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("Male");

  const register = () => {
    setProgress(true);
    axios
      .post(
        `${API}/register`,
        qs.stringify({ username, email, password, dob, gender }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setProgress(false);
        if (res.data.success) {
          localStorage.setItem("user_id", res.data.user_id);
          localStorage.setItem("user_token", res.data.token);
          localStorage.setItem("price_type", res.data.price_type);
          const token_fcm = sessionStorage.getItem("token_fcm");
          token_fcm &&
            axios
              .post(
                `${API}/user/fcm/${res.data.user_id}`,
                qs.stringify({ token: token_fcm }),
                {
                  headers: {
                    token: res.data.token,
                  },
                }
              )
              .then((rush) => {
                console.log(rush.data.message);
              })
              .catch((err) => {
                console.log(err);
              });
          setToastMsg(`Silahkan cek email ${email} untuk mulai belanja`);
          setShowToast(true);
          history.push("/home");
        } else {
          setToastMsg(res.data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };
  const registerPhone = () => {
    setProgress(true);
    axios
      .post(
        `${API}/phone_register`,
        qs.stringify({ username, phone, dob, gender, otp }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setProgress(false);

        if (res.data.success) {
          localStorage.setItem("user_id", res.data.user_id);
          localStorage.setItem("user_token", res.data.token);
          localStorage.setItem("price_type", res.data.price_type);
          const token_fcm = sessionStorage.getItem("token_fcm");
          token_fcm &&
            axios
              .post(
                `${API}/user/fcm/${res.data.user_id}`,
                qs.stringify({ token: token_fcm }),
                {
                  headers: {
                    token: res.data.token,
                  },
                }
              )
              .then((rush) => {
                console.log(rush.data.message);
              })
              .catch((err) => {
                console.log(err);
              });
          setToastMsg(`Silahkan cek email ${email} untuk mulai belanja`);
          setShowToast(true);
          history.push("/home");
        } else {
          setToastMsg(res.data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  const getOTP = () => {
    setProgress(true);
    if (phone !== "" && phone) {
      axios
        .post(`${API}/is_phone_registered`, qs.stringify({ phone }), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        })
        .then((res) => {
          setProgress(false);
          if (!res.data.success) {
            axios
              .post(`${API}/phone_auth_OTP`, qs.stringify({ phone }), {
                headers: {
                  "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
                },
              })
              .then((res) => {
                setShowOTP(true);
              })
              .catch((err) => {
                setToastMsg(err);
                setShowToast(true);
              });
          } else {
            setToastMsg(res.data.message);
            setShowToast(true);
          }
        })
        .catch((err) => {
          setProgress(false);
          setToastMsg(err);
          setShowToast(true);
        });
    } else {
      setProgress(false);
      setToastMsg("Nomor Harus Diisi");
      setShowToast(true);
    }
  };

  return (
    <IonPage id="login">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/login" text="Login" />
          </IonButtons>
          <IonTitle>Register</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="4" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  paddingTop: 32,
                  paddingBottom: 16,
                  paddingLeft: 32,
                  paddingRight: 32,
                }}
                className="ion-text-center"
              >
                <img
                  alt="a"
                  width="50%"
                  src={require(`../assets/images/harnic.svg`)}
                />
              </div>
              <IonList lines="full" className="ion-no-margin ion-padding">
                <IonItem lines="none">
                  <IonSegment
                    value={method}
                    onIonChange={(e) => setMethod(e.detail.value)}
                  >
                    <IonSegmentButton value="phone">
                      <IonLabel>Phone</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="email">
                      <IonLabel>Email</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Username</IonLabel>
                  <IonInput
                    placeholder="Nama Anda"
                    inputMode="text"
                    value={username}
                    onIonChange={(e) => {
                      setUsername(e.detail.value);
                    }}
                  ></IonInput>
                </IonItem>
                {method === "email" ? (
                  <>
                    <IonItem>
                      <IonLabel position="floating">Email</IonLabel>
                      <IonInput
                        placeholder="user@email.com"
                        inputMode="email"
                        value={email}
                        onIonChange={(e) => {
                          setEmail(e.detail.value);
                        }}
                      ></IonInput>
                    </IonItem>

                    <IonItem>
                      <IonLabel position="floating">Password</IonLabel>
                      <IonInput
                        placeholder="********"
                        type="password"
                        value={password}
                        onIonChange={(e) => {
                          setPassword(e.detail.value);
                        }}
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="floating">Ulangi Password</IonLabel>
                      <IonInput
                        placeholder="********"
                        type="password"
                        value={password2}
                        onIonChange={(e) => {
                          setPassword2(e.detail.value);
                        }}
                      ></IonInput>
                    </IonItem>
                  </>
                ) : (
                  <IonItem>
                    <IonLabel position="floating">Nomor Handphone</IonLabel>
                    <IonInput
                      type="tel"
                      placeholder="0812 3456 7689"
                      value={phone}
                      onIonChange={(e) => {
                        setPhone(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                )}
                <IonItem>
                  <IonLabel position="floating">Jenis Kelamin</IonLabel>
                  <IonSelect
                    value={gender}
                    onIonChange={(e) => setGender(e.detail.value)}
                    interface="popover"
                  >
                    <IonSelectOption value="Male">Male</IonSelectOption>
                    <IonSelectOption value="Female">Female</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Tanggal Lahir</IonLabel>
                  <IonDatetime
                    displayFormat="MM-DD-YYYY"
                    value={dob}
                    onIonChange={(e) => {
                      setDob(e.detail.value!);
                    }}
                  ></IonDatetime>
                </IonItem>
              </IonList>

              <div className="ion-padding">
                {method === "email" ? (
                  <IonButton
                    expand="block"
                    onClick={() => register()}
                    type="submit"
                    className="ion-no-margin"
                  >
                    Masuk
                  </IonButton>
                ) : (
                  <IonButton
                    expand="block"
                    onClick={() => getOTP()}
                    type="submit"
                    className="ion-no-margin"
                  >
                    Kirim OTP
                  </IonButton>
                )}
              </div>
            </IonCol>
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonModal isOpen={showOTP} cssClass="my-custom-class">
        <IonListHeader>
          <IonLabel>OTP Verification</IonLabel>
          <IonButton color="danger" onClick={() => setShowOTP(false)}>
            Batal
          </IonButton>
        </IonListHeader>
        <IonContent style={{ textAlign: "center" }}>
          <div style={{ marginTop: 60 }}>
            <small>Masukkan 4 digit kode OTP</small>
          </div>
          <OTPInput
            style={{ textAlign: "center", marginBottom: 32 }}
            length={4}
            className="otpContainer"
            inputClassName="otpInput"
            isNumberInput
            autoFocus
            onChangeOTP={(otp) => setOTP(otp)}
          />
          <div style={{ textAlign: "center" }}>
            <small>Tidak mendapat kode?</small>
          </div>
          {/* <IonButton className="ion-no-margin" color="light" size="small">
            Kirim Ulang
          </IonButton> */}
          <Suspense
            fallback={<IonSkeletonText animated style={{ height: 300 }} />}
          >
            <CountdownPhone action={() => getOTP()} />
          </Suspense>
        </IonContent>
        <div className="ion-padding">
          <IonButton
            expand="block"
            className="ion-no-margin"
            onClick={() => registerPhone()}
          >
            Daftar Sekarang
          </IonButton>
        </div>
      </IonModal>
    </IonPage>
  );
};

export default memo(Register);
