import {
  IonAlert,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRippleEffect,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonToast,
} from "@ionic/react";
import axios from "axios";
import React, { memo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Countdown from "react-countdown";
import NumberFormat from "react-number-format";
import "./Comps.css";

interface Props {
  info: any;
  onCancel: any;
}
interface CountRender {
  hours: any;
  minutes: any;
  seconds: any;
  completed: any;
}
const PaymentVA: React.FC<Props> = ({ info, onCancel }) => {
  const [showModal, setShowModal] = useState(false);
  const [banks, setBanks] = useState<any>(null);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [bankSelected, setBankSelected] = useState<any>(null);
  const [controllerSelected, setControllerSelected] = useState<any>(null);
  const [showToast, setShowToast] = useState<any>(null);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const handleAlert = (bank_code: any, controller: any) => {
    setBankSelected(bank_code);
    setControllerSelected(controller);
    setShowAlert(true);
  };
  const bankSelect = () => {
    Confirm(info.trxno, bankSelected, controllerSelected, 0);
    setShowModal(true);
  };
  const Confirm = async (
    trx: any,
    bank_code: any,
    controller: any,
    repay: number = 0
  ) => {
    await axios
      .get(
        `https://api.harnic.id/trx/${controller}/${trx}/${bank_code}/${repay}`
      )
      .then((res) => {
        if (res.data.success) {
          setPaymentData(res.data.data);
          setToastMsg(res.data.message);
        } else {
          setShowModal(false);
          setToastMsg("Gagal melakukan pembayaran");
        }
        setShowToast(true);
      })
      .catch((e) => {
        setToastMsg(e);
        setShowToast(true);
      });
  };
  const getBanks = () => {
    axios.get(`https://api.harnic.id/trx/getVABanks`).then((res) => {
      setBanks(res.data);
    });
  };
  React.useEffect(() => {
    getBanks();
  }, [info]);
  // Random component
  const Completionist = () => <div>Pembayaran Kadaluarsa</div>;

  // Renderer callback with condition
  const renderer: React.FC<CountRender> = ({
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div
          className="ion-text-center ion-activatable ripple-parent"
          style={{
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <span
            style={{
              fontSize: 50,
              color: "#555",
            }}
          >
            {minutes}:{seconds}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Konfirmasi Pembayaran"}
        message={
          "Metode pembayaran dengan Virtual Account tidak dapat diubah setelah dipilih"
        }
        buttons={[
          {
            text: "Batal",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Payment Canceled");
            },
          },
          {
            text: "Lanjutkan",
            handler: () => {
              bankSelect();
            },
          },
        ]}
      />
      <IonList>
        {banks ? (
          banks.map((i: any) => (
            <IonItem
              key={i.bank_id}
              onClick={() => handleAlert(i.bank_code, i.call_function)}
              // onClick={() => bankSelect(i.bank_code, i.call_function)}
              lines="none"
            >
              <IonCard style={{ width: "100%" }} className="mt-20 mb-20">
                <IonCardContent className="p-10 ion-text-center ion-activatable ripple-parent">
                  <IonRippleEffect></IonRippleEffect>
                  <img
                    alt="a"
                    src={
                      i.img
                        ? require(`../assets/images/banks/${i.img}`)
                        : require(`../assets/images/banks/nologo.jpg`)
                    }
                  />
                </IonCardContent>
              </IonCard>
            </IonItem>
          ))
        ) : (
          <>
            <IonItem lines="none">
              <IonCard style={{ width: "100%" }} className="mt-20 mb-20">
                <IonCardContent className="p-0 ion-text-center">
                  <IonSkeletonText
                    animated
                    style={{ width: "100%", height: "100px" }}
                  />
                </IonCardContent>
              </IonCard>
            </IonItem>
            <IonItem lines="none">
              <IonCard style={{ width: "100%" }} className="mt-20 mb-20">
                <IonCardContent className="p-0 ion-text-center">
                  <IonSkeletonText
                    animated
                    style={{ width: "100%", height: "100px" }}
                  />
                </IonCardContent>
              </IonCard>
            </IonItem>
            <IonItem lines="none">
              <IonCard style={{ width: "100%" }} className="mt-20 mb-20">
                <IonCardContent className="p-0 ion-text-center">
                  <IonSkeletonText
                    animated
                    style={{ width: "100%", height: "100px" }}
                  />
                </IonCardContent>
              </IonCard>
            </IonItem>
          </>
        )}
      </IonList>

      <IonModal isOpen={showModal} cssClass="my-custom-class">
        {paymentData ? (
          <IonContent>
            <IonListHeader>
              <IonLabel>Complete Payment</IonLabel>
              <IonButton color="danger" onClick={() => onCancel()}>
                Tutup
              </IonButton>
            </IonListHeader>
            <Countdown
              date={Date.parse(paymentData.time + " GMT+7") + 3600000}
              renderer={renderer}
            />
            <IonCard>
              <IonCardContent className="p-10 ion-text-center">
                <div>Silahkan transfer ke:</div>
                <div style={{ margin: 16 }}>
                  <small>{paymentData.va_bank.bank_name} Virtual Account</small>
                  <IonCardTitle>
                    {paymentData.rekno}
                    <CopyToClipboard
                      text={paymentData.rekno}
                      onCopy={() => {
                        setToastMsg("Disalin ke clipboard");
                        setShowToast(true);
                      }}
                    >
                      <IonBadge color="success" style={{ marginLeft: 4 }}>
                        SALIN
                      </IonBadge>
                    </CopyToClipboard>
                  </IonCardTitle>
                </div>
                <div style={{ margin: 16 }}>
                  <small>Dengan nominal:</small>
                  <IonCardTitle>
                    <NumberFormat
                      value={paymentData.netsales}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rp. "}
                    />
                    <CopyToClipboard
                      text={info.total}
                      onCopy={() => {
                        setToastMsg("Disalin ke clipboard");
                        setShowToast(true);
                      }}
                    >
                      <IonBadge color="success" style={{ marginLeft: 4 }}>
                        SALIN
                      </IonBadge>
                    </CopyToClipboard>
                  </IonCardTitle>
                </div>
              </IonCardContent>
            </IonCard>
            <div style={{ margin: 16 }}>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Menggunakan Mobile Banking:
              </span>
              <div
                style={{
                  fontSize: 11,
                }}
                dangerouslySetInnerHTML={{
                  __html: paymentData && paymentData.va_bank.guide_mobile,
                }}
              />
            </div>
            <div style={{ margin: 16 }}>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Menggunakan ATM:
              </span>
              <div
                style={{
                  fontSize: 11,
                }}
                dangerouslySetInnerHTML={{
                  __html: paymentData && paymentData.va_bank.guide_atm,
                }}
              />
            </div>
          </IonContent>
        ) : (
          <IonContent>
            <div className="ion-text-center" style={{ marginTop: "100px" }}>
              <div>
                <IonSpinner name="circular" style={{ alignSelf: "center" }} />
              </div>
              <IonText>Processing Secure Payment</IonText>
              <br />
              <small>Please wait</small>
            </div>
          </IonContent>
        )}
      </IonModal>
    </>
  );
};

export default memo(PaymentVA);
