import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import React, { memo, useState } from "react";
import { API } from "../helpers/api";
import "./Page.css";

interface LoginProps {
  match: any;
  history: any;
}
const VerifyEmail: React.FC<LoginProps> = ({ match, history }) => {
  const [progress, setProgress] = useState<any>(false);
  const [success, setSuccess] = useState<any>(false);
  const [msg, setMsg] = useState<any>(null);

  const verify = (code: any) => {
    setProgress(true);
    axios
      .get(`${API}/user/verify/${code}`)
      .then((res) => {
        setProgress(false);
        setSuccess(res.data.success);
        setMsg(res.data.message);
      })
      .catch((err) => {
        setProgress(false);
        setMsg(err);
      });
  };

  useIonViewWillEnter(() => {
    verify(match.params.code);
  }, [match.params.code]);

  return (
    <IonPage id="login">
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="4" className="p-0 m-0 justify-content-center ">
              {!progress && (
                <div
                  style={{
                    justifyContent: "center",
                    flex: 1,
                    textAlign: "center",
                    color: "#252158",
                    fontWeight: "bold",
                    paddingTop: 20,
                  }}
                >
                  <div>
                    <img
                      alt="empty-data"
                      src={require(success
                        ? "../assets/images/success.png"
                        : "../assets/images/failed.png")}
                      width="60%"
                    />
                  </div>
                  <IonText
                    style={{
                      fontSize: 17,
                    }}
                  >
                    {msg}
                  </IonText>
                  <div style={{ marginTop: 32 }}>
                    {success ? (
                      <IonButton
                        color="primary"
                        size="default"
                        onClick={() => history.push("/login")}
                      >
                        MASUK SEKARANG
                      </IonButton>
                    ) : (
                      <IonButton
                        color="primary"
                        size="default"
                        onClick={() => history.push("/home")}
                      >
                        KE HALAMAN UTAMA
                      </IonButton>
                    )}
                  </div>
                </div>
              )}
            </IonCol>
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default memo(VerifyEmail);
