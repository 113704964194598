import { RefresherEventDetail } from "@ionic/core";
import {
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import axios from "axios";
import { arrowUpOutline, chevronDownOutline, filter } from "ionicons/icons";
import qs from "qs";
import React, { memo, useEffect, useRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import ItemCard from "../../components/ItemCard";
import { API } from "../../helpers/api";

const ResultSearchItems = (props: any) => {
  const contentRef = useRef(null);
  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current.scrollToTop("300");
  };

  const { keyword, categories, brands } = props;

  const [loading, setLoading] = useState<any>(true);
  const [products, setProducts] = useState<any>([]);
  const [page, setPage] = useState<number>(1);

  const [filterCat, setFilterCat] = useState<any>([]);
  const [filterBrand, setFilterBrand] = useState<any>([]);

  const [filters, setFilters] = useState<boolean>(false);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
    false
  );

  const getProduct = async (_page: number) => {
    _page === 1 && setLoading(true);
    await axios
      .post(
        `${API}/product/find/${_page}/20`,
        qs.stringify({
          keyword: keyword,
          "category[]": categories,
          "brand[]": brands,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (_page === 1) {
          setProducts(res.data.data);

          setFilterCat(res.data.filter_categories);
          setFilterBrand(res.data.filter_brand);
        } else {
          setProducts([...products, ...res.data.data]);
        }
        setLoading(false);
        setDisableInfiniteScroll(res.data.data.length < 10);
        setPage(_page + 1);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const onRefresh2 = (event: CustomEvent<RefresherEventDetail>) => {
    setLoading(true);
    getProduct(1)
      .then(() => event.detail.complete())
      .catch(() => event.detail.complete());
  };

  const onRefresh = () => {
    setLoading(true);
    getProduct(1);
  };

  async function searchNext($event: CustomEvent<void>) {
    await getProduct(page);

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  useEffect(() => {
    onRefresh();
  }, [keyword, categories]);

  const dataMap = [...Array(18)];

  return (
    <IonContent ref={contentRef} scrollEvents={true} fullscreen>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh2}>
        <IonRefresherContent
          pullingIcon={chevronDownOutline}
          pullingText="Pull to refresh"
          refreshingSpinner="lines"
        ></IonRefresherContent>
      </IonRefresher>
      <IonGrid className="p-0 m-0">
        <IonRow className="p-0 m-0">
          <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
          <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
            {loading ? (
              <IonGrid>
                <IonRow>
                  {dataMap.map((i: any) => (
                    <IonCol key={i} size="6" sizeMd="4" sizeXl="2" sizeLg="3">
                      <IonCard className="m-2">
                        <IonSkeletonText
                          animated
                          className="ratio-square"
                          style={{ marginTop: 0 }}
                        />
                        <div className="product-caption">
                          <IonSkeletonText animated />
                          <IonSkeletonText animated style={{ width: "50%" }} />
                        </div>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            ) : products.length > 0 ? (
              <IonGrid>
                <IonRow>
                  {products.map((i: any, index: any) => (
                    <LazyLoadComponent
                      key={i.itemid + index}
                      placeholder={<Skeleton width="100%" height={200} />}
                    >
                      <IonCol
                        key={i.itemid}
                        size="6"
                        sizeMd="4"
                        sizeXl="2"
                        sizeLg="3"
                      >
                        <ItemCard i={i} history={props.history} />
                      </IonCol>
                    </LazyLoadComponent>
                  ))}
                  <IonInfiniteScroll
                    threshold="100px"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
                  >
                    <IonInfiniteScrollContent
                      loadingSpinner="lines"
                      loadingText="Loading more products"
                    ></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </IonRow>
              </IonGrid>
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  flex: 1,
                  textAlign: "center",
                  color: "#252158",
                  fontWeight: "bold",
                  paddingTop: 20,
                }}
              >
                <div>
                  <img
                    alt="empty-cart"
                    src={require("../../assets/images/not-found.png")}
                    width="80%"
                  />
                </div>
                <IonText
                  style={{
                    fontSize: 17,
                  }}
                >
                  Tidak Ditemukan
                </IonText>
              </div>
            )}
          </IonCol>
          <IonCol sizeLg="3" sizeXs="auto" className="p-0 m-0" />
        </IonRow>
      </IonGrid>
      <IonFab vertical="bottom" horizontal="start" slot="fixed">
        <IonFabButton onClick={() => setFilters(true)}>
          <IonIcon icon={filter} />
        </IonFabButton>
      </IonFab>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton onClick={() => scrollToTop()}>
          <IonIcon icon={arrowUpOutline} />
        </IonFabButton>
      </IonFab>
      <IonModal
        swipeToClose={true}
        cssClass="modal-sheet-filter"
        isOpen={filters}
        onWillDismiss={() => setFilters(false)}
      >
        <IonCol size="12" style={{ backgroundColor: "#1100bb" }}>
          <div className="ion-activatable ripple-parent">
            <IonGrid className="m-2">
              <IonRow>
                <IonCol size="12">
                  <IonText
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      marginLeft: 6,
                      color: "#fff",
                    }}
                  >
                    Brand
                  </IonText>
                  <div style={{ marginTop: 15 }}>
                    {filterBrand.map((i: any) => (
                      <IonChip
                        style={{ backgroundColor: "#fff" }}
                        onClick={() => {
                          setFilters(false);
                          // props.history.push(
                          //   "/resultsearch/brand/" + i.BrandID
                          // );
                          keyword !== ""
                            ? (window.location.href =
                                "/resultsearchbrand/" +
                                keyword +
                                "/keyword/" +
                                i.BrandID)
                            : (window.location.href =
                                "/resultsearchbrand/" +
                                categories +
                                "/cat/" +
                                i.BrandID);
                        }}
                      >
                        <IonLabel>{i.BrandName}</IonLabel>
                      </IonChip>
                    ))}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonCol>
      </IonModal>
    </IonContent>
  );
};
export default memo(ResultSearchItems);
