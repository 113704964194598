import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonInput,
  IonItem,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState } from "react";
import NumberFormat from "react-number-format";
import "./Comps.css";

interface Props {
  info: any;
  onCancel: any;
}
const PaymentCod: React.FC<Props> = ({ info, onCancel }) => {
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [codCash, setCodCash] = useState<number>(0);
  React.useEffect(() => {
    setCodCash(info.total);
  }, [info]);
  const Confirm = (trx: any) => {
    setProcessing(true);
    if (codCash < Number(info.total)) {
      setToastMsg("Uang yang disediakan tidak cukup");
      setProcessing(false);
      setShowToast(true);
    } else {
      axios
        .post(
          `https://api.harnic.id/trx/payWithCOD/${trx}`,
          qs.stringify({ cod_cash: codCash }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          setToastMsg(res.data.message);
          setProcessing(false);
          setShowToast(true);
          onCancel();
        })
        .catch((e) => {
          setToastMsg(e);
          setProcessing(false);
          setShowToast(true);
        });
    }
  };
  return (
    <>
      <IonCard>
        <IonCardContent className="ion-text-center">
          <div>Pastikan nomor penerima berikut selalu aktif:</div>
          <div style={{ margin: 16 }}>
            <small>{info.custname}</small>
            <IonCardTitle>{info.phone}</IonCardTitle>
          </div>
          <div style={{ margin: 16 }}>
            <small>Pembayaran yang harus disediakan:</small>
            <IonCardTitle>
              <NumberFormat
                value={info.total}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp. "}
              />
            </IonCardTitle>
          </div>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardContent className="ion-text-center">
          <div>Perlu kembalian? masukkan nominal yang anda miliki</div>
          <div style={{ margin: 16 }}>
            <IonItem>
              <IonInput
                value={codCash}
                type="number"
                placeholder="Nominal yang tersedia"
                onIonChange={(e) => {
                  setCodCash(Number(e.detail.value));
                }}
              ></IonInput>
            </IonItem>
          </div>
        </IonCardContent>
      </IonCard>
      <IonCard color="warning">
        <IonCardContent className="ion-text-center">
          <div>
            <div style={{ fontWeight: "bold" }}>
              Anda tidak perlu membayar biaya tambahan kepada kurir!
            </div>
            <br />
            <small>
              Apabila kurir kami meminta biaya tambahan, mohon untuk lapor ke
              nomor WhatsApp (<a href="tel:081310141393">0813-1014-1393</a>)
            </small>
          </div>
        </IonCardContent>
      </IonCard>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <div style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 4 }}>
        <IonButton
          disabled={processing}
          expand="block"
          onClick={() => {
            Confirm(info.trxno);
          }}
        >
          {processing ? <IonSpinner name="dots" /> : "MENGERTI"}
        </IonButton>
      </div>
    </>
  );
};

export default memo(PaymentCod);
