import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { addOutline } from "ionicons/icons";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import CreditCard from "../../components/CreditCard";
import SkeletonList from "../../components/SkeletonList";
import { API } from "../../helpers/api";
import MainTabs from "../../MainTabs";
import "../Page.css";

interface ListCreditCardProps extends RouteComponentProps<{ props: any }> {}

const ListCreditCard: React.FC<ListCreditCardProps> = ({ match, history }) => {
  const [creditCard, setCreditCard] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const getCreditCard = async () => {
    setLoading(true);

    axios
      .get(`${API}/user/${user_id}/card`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          token: user_token,
        },
      })
      .then((res) => {
        setLoading(false);
        setCreditCard(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useIonViewWillEnter(() => {
    getCreditCard();
  }, []);

  const _renderItem = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {creditCard &&
                creditCard.map((i: any, index: any) => (
                  <CreditCard
                    key={"card" + index}
                    card_num={i.card_num}
                    card_holder={i.card_holder}
                    card_exp={i.card_exp}
                    action={() => {
                      history.push("/user/creditcard/edit/" + i.card_id);
                    }}
                  />
                  // <IonList lines="full" key={i.card_id}>
                  //   <IonItem routerLink={"creditcard/edit/" + i.card_id}>
                  //     <IonIcon slot="start" icon={cardOutline} />
                  //     <IonLabel>
                  //       <div
                  //         style={{
                  //           fontSize: 18,
                  //           color: "#555",
                  //           fontWeight: "bold",
                  //         }}
                  //       >
                  //         <IonText>{i.card_holder}</IonText>
                  //       </div>
                  //       <div
                  //         style={{
                  //           fontSize: 15,
                  //           color: "#999",
                  //         }}
                  //       >
                  //         <IonText>{i.card_num}</IonText>
                  //       </div>
                  //       <div
                  //         style={{
                  //           fontSize: 12,
                  //           fontStyle: "italic",
                  //           color: "#999",
                  //         }}
                  //       >
                  //         <IonText>Exp : {i.card_exp}</IonText>
                  //       </div>
                  //     </IonLabel>
                  //   </IonItem>
                  // </IonList>
                ))}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => history.push("/user/creditcard/create")}>
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    );
  };
  const _emptyScreen = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  justifyContent: "center",
                  flex: 1,
                  textAlign: "center",
                  color: "#252158",
                  fontWeight: "bold",
                  paddingTop: 20,
                }}
              >
                <div>
                  <img
                    alt="empty-address"
                    src={require("../../assets/images/nodata.png")}
                    width="60%"
                  />
                </div>
                <IonText
                  style={{
                    fontSize: 17,
                  }}
                >
                  Belum ada kartu kredit
                </IonText>
                <div style={{ marginTop: 32 }}>
                  <IonButton
                    size="default"
                    onClick={() => history.push("/user/creditcard/create")}
                  >
                    TAMBAH SEKARANG
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  return (
    <IonPage id="listcreditcard">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Kartu Kredit</IonTitle>
        </IonToolbar>
      </IonHeader>
      {loading ? (
        <SkeletonList number={3} />
      ) : creditCard.length > 0 ? (
        _renderItem()
      ) : (
        _emptyScreen()
      )}
      <MainTabs />
    </IonPage>
  );
};

export default memo(ListCreditCard);
