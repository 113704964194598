import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonThumbnail,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import { addCircle, removeCircle } from "ionicons/icons";
import qs from "qs";
import React, { memo, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { RouteComponentProps } from "react-router";
import Loading from "../components/Loading";
import { API } from "../helpers/api";
import { checkLogin } from "../helpers/auth";
import MainTabs from "../MainTabs";

interface ProductBuyProps extends RouteComponentProps<{ id: any }> {}
declare const window: any;

const ProductBuy: React.FC<ProductBuyProps> = ({ match, history }) => {
  const contentRef = useRef(null);

  const [product, setProduct] = useState<any>([]);
  const [qty, setQty] = useState(1);
  const [note, setNote] = useState<any>("");
  const [productBuyLoading, setProductBuyLoading] = useState(false);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const getProduct = async (id: any) => {
    setProductBuyLoading(true);
    await axios
      .get(`${API}/product/get/${id}`)
      .then((res) => {
        setProductBuyLoading(false);
        setProduct(res.data);
      })
      .catch(() => {
        setProductBuyLoading(false);
      });
  };

  const AddToCart = (id: any, qty: number, note: any) => {
    setProductBuyLoading(true);
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .post(
        `${API}/cart/${user_id}`,
        qs.stringify({
          item_id: id,
          qty: qty,
          app_version: 999999999,
          note: note,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            token: user_token,
          },
        }
      )
      .then((res) => {})
      .catch((e) => {});
  };

  useIonViewDidEnter(() => {
    getProduct(match.params.id);
  }, [match.params.id]);

  const _productScreen = () => {
    return (
      <>
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {product.stock_num < 4 && product.stock_num > 0 && (
          <div
            className="ion-activatable ripple-parent"
            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
          >
            <IonCard className="m-2">
              <div className="product-caption">
                <p className="product-name">
                  <IonText style={{ color: "#555", fontWeight: "bold" }}>
                    Stok Akan Segera Habis
                  </IonText>
                  <br />
                  <IonText
                    style={{
                      color: "#555",
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    saat ini hanya tersedia {product.stock_num}
                  </IonText>
                </p>
              </div>
            </IonCard>
          </div>
        )}
        <IonList lines="full">
          <IonItem>
            <IonThumbnail slot="start">
              <IonImg src={product.main_pic} />
            </IonThumbnail>
            <IonLabel className="ion-text-warp">
              <IonText
                style={{ fontSize: 14, fontStyle: "normal", textAlign: "left" }}
              >
                {product.online_name}
              </IonText>
              <br />
              <IonText
                style={{
                  color: "#F29E1F",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginTop: 4,
                }}
              >
                {" "}
                <span style={{ paddingRight: 3 }}>{qty} x</span>
                <NumberFormat
                  value={parseInt(product.price)}
                  displayType={"text"}
                  thousandSeparator={true}
                  // prefix={"Rp. "}
                />
              </IonText>
              <br />
              <IonText>
                Subtotal : Rp.{" "}
                <NumberFormat
                  value={parseInt(product.price) * qty}
                  displayType={"text"}
                  thousandSeparator={true}
                  // prefix={"Rp. "}
                />
              </IonText>
              {product.maxOrder < 9999 && product.maxOrder > 0 && (
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#FEF4C5",
                    }}
                  >
                    <IonText
                      style={{
                        fontWeight: "bold",
                        fontSize: 10,
                        color: "#393836",
                      }}
                    >
                      BATAS PEMBELIAN: {product.maxOrder}
                    </IonText>
                  </div>
                </div>
              )}
            </IonLabel>
            <div>
              <IonGrid>
                <IonRow className="ion-align-items-center">
                  <IonIcon
                    style={{
                      color: "#F39F22",
                      fontSize: 25,
                      marginHorizontal: 5,
                      cursor: "pointer",
                    }}
                    icon={removeCircle}
                    onClick={() => {
                      if (qty > 0) {
                        setQty(qty - 1);
                      }
                    }}
                  />
                  <span
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <IonText style={{ paddingHorizontal: 5 }}>{qty}</IonText>
                  </span>
                  <IonIcon
                    style={{
                      color: "#F39F22",
                      fontSize: 25,
                      marginHorizontal: 5,
                      cursor: "pointer",
                    }}
                    icon={addCircle}
                    onClick={() => {
                      if (
                        qty < Number(product.maxOrder) ||
                        Number(product.maxOrder) === 0
                      ) {
                        if (qty < Number(product.stock_num)) {
                          setQty(qty + 1);
                        } else {
                          setToastMsg(
                            "Stock tersisa " + Number(product.stock_num)
                          );
                          setShowToast(true);
                        }
                      } else {
                        setToastMsg(
                          "Batas Pembelian " + Number(product.maxOrder)
                        );
                        setShowToast(true);
                      }
                    }}
                  />
                </IonRow>
              </IonGrid>
            </div>
          </IonItem>
        </IonList>

        <IonTextarea
          style={{ paddingLeft: 20, paddingTop: 10 }}
          placeholder="Catatan"
          value={note}
          onIonChange={(e) => setNote(e.detail.value)}
        />

        <div className="ion-padding">
          <IonButton
            expand="block"
            disabled={qty < 1 || product.stock_num < 1 ? true : false}
            onClick={() => {
              checkLogin().then((res) => {
                if (res) {
                  AddToCart(product.itemid, qty, note);
                  history.push("/cart");
                } else {
                  setToastMsg("Login Untuk Membeli Barang Ini");
                  setShowToast(true);
                  history.push("/login");
                }
              });
            }}
          >
            Tambah Keranjang
          </IonButton>
        </div>
      </>
    );
  };

  const _emptyScreen = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  justifyContent: "center",
                  flex: 1,
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <IonImg
                  src={require("../assets/images/empty_cart.png")}
                  style={{
                    resizeMode: "contain",
                  }}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonContent ref={contentRef} scrollEvents={true} fullscreen>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="1" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="10" className="p-0 m-0 justify-content-center">
              {productBuyLoading ? (
                <Loading />
              ) : product.length === 0 ? (
                _emptyScreen()
              ) : (
                _productScreen()
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(ProductBuy);
