import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState } from "react";
import SearchModal from "../../components/SearchModal";
import { API } from "../../helpers/api";
import "../Page.css";
import ResultSearchItems from "./ResultSearchItems";

declare const window: any;

const ResultSearchBrand = (props: any) => {
  const [filterCat, setFilterCat] = useState<any>([]);

  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<any>("all");

  // PARAMS
  const searchKeyword =
    props.match.params.key === "keyword" ? props.match.params.q : "";
  const searchBrand = props.match.params.id;
  const searchCategory =
    props.match.params.key === "cat" ? props.match.params.q : "";

  const fetchData = async () => {
    const url: string = `${API}/product/findcat/1/1`;
    await axios
      .post(
        url,
        qs.stringify({
          keyword: searchKeyword,
          "category[]": searchCategory,
          "brand[]": searchBrand,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setFilterCat(res.data.filter_categories);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useIonViewWillEnter(async () => {
    await fetchData();
  });

  return (
    <IonPage id="resultsearch">
      <IonToolbar style={{ paddingTop: 10 }}>
        <IonButtons slot="start">
          <IonButton
            style={{
              paddingTop: -5,
              paddingBottom: 5,
            }}
            onClick={() => {
              props.history.goBack();
            }}
          >
            <strong>Back </strong>
          </IonButton>
        </IonButtons>
        <IonButton
          color="light"
          expand="block"
          onClick={() => setVisible(true)}
          type="submit"
          style={{
            marginRight: 12,
            paddingTop: -5,
            // paddingBottom: 5,
          }}
          className="ion-no-margin"
        >
          <div
            style={{
              flex: 1,
              textAlign: "left",
            }}
          >
            <IonIcon icon="search-outline" />
            &nbsp;
            <IonText style={{ color: "#A0A1A3" }}>Cari Produk</IonText>
          </div>
        </IonButton>
      </IonToolbar>
      <IonToolbar>
        {filterCat.length > 0 && (
          <IonSegment
            value={value}
            mode="md"
            scrollable
            onIonChange={(e) => setValue(e.detail.value)}
          >
            <IonSegmentButton value="all">
              <IonLabel>All</IonLabel>
            </IonSegmentButton>
            {filterCat.map((cat: any) => (
              <IonSegmentButton value={cat.ec_category}>
                <IonLabel>{cat.cat_name}</IonLabel>
              </IonSegmentButton>
            ))}
          </IonSegment>
        )}
      </IonToolbar>

      {value === "all" ? (
        <ResultSearchItems
          keyword={searchKeyword}
          categories={searchCategory}
          brands={searchBrand}
          {...props}
        />
      ) : (
        <ResultSearchItems
          keyword={searchKeyword}
          categories={value}
          brands={searchBrand}
          {...props}
        />
      )}
      <IonModal isOpen={visible} onWillDismiss={() => setVisible(false)}>
        <SearchModal close={() => setVisible(false)} {...props} />
      </IonModal>
    </IonPage>
  );
};

export default memo(ResultSearchBrand);
