import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { addOutline } from "ionicons/icons";
import React, { memo, useState } from "react";
import * as Icon from "react-feather";
import { RouteComponentProps } from "react-router";
import SkeletonList from "../../components/SkeletonList";
import { API } from "../../helpers/api";
import MainTabs from "../../MainTabs";
import "../Page.css";

interface ListAlamatProps extends RouteComponentProps<{ props: any }> {}

const ListAlamat: React.FC<ListAlamatProps> = ({ match, history }) => {
  const [pengiriman, setPengiriman] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const getPengiriman = async () => {
    setLoading(true);

    axios
      .get(`${API}/shippings/${user_id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          token: user_token,
        },
      })
      .then((res) => {
        setLoading(false);
        setPengiriman(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useIonViewWillEnter(() => {
    getPengiriman();
  }, []);

  const _renderItem = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {pengiriman &&
                pengiriman.map((i: any) => (
                  <IonList lines="full" key={i.shipping_id}>
                    <IonItem routerLink={"shipping/edit/" + i.shipping_id}>
                      {/* <IonIcon slot="start" icon={mapOutline} /> */}
                      <Icon.MapPin />
                      <IonLabel>
                        <div
                          style={{
                            paddingLeft: 10,
                            fontSize: 18,
                            color: "#555",
                            fontWeight: "bold",
                          }}
                        >
                          <IonText>{i.shipping_name}</IonText>
                        </div>
                        <div
                          style={{
                            paddingLeft: 10,
                            fontSize: 12,
                            fontStyle: "italic",
                            color: "#999",
                          }}
                        >
                          <IonText>
                            {i.shipping_address +
                              ", " +
                              i.subdis_name +
                              ", " +
                              i.dis_name +
                              ", " +
                              i.city_name +
                              ", " +
                              i.prov_name +
                              " " +
                              i.zip_code}
                          </IonText>
                        </div>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                ))}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => history.push("/user/shipping/create")}>
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    );
  };
  const _emptyScreen = () => {
    return (
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  justifyContent: "center",
                  flex: 1,
                  textAlign: "center",
                  color: "#252158",
                  fontWeight: "bold",
                  paddingTop: 20,
                }}
              >
                <div>
                  <img
                    alt="empty-address"
                    src={require("../../assets/images/nodata.png")}
                    width="60%"
                  />
                </div>
                <IonText
                  style={{
                    fontSize: 17,
                  }}
                >
                  Belum ada alamat
                </IonText>
                <div style={{ marginTop: 32 }}>
                  <IonButton
                    size="default"
                    onClick={() => history.push("/user/shipping/create")}
                  >
                    TAMBAH SEKARANG
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  return (
    <IonPage id="Listalamat">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Alamat Kirim</IonTitle>
        </IonToolbar>
      </IonHeader>
      {loading ? (
        <SkeletonList number={3} />
      ) : pengiriman.length > 0 ? (
        _renderItem()
      ) : (
        _emptyScreen()
      )}
      <MainTabs />
    </IonPage>
  );
};

export default memo(ListAlamat);
