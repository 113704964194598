import {
  IonButton,
  IonButtons,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import {
  addCircle,
  createOutline,
  removeCircle,
  trashOutline,
} from "ionicons/icons";
import React, { memo, useState } from "react";
import NumberFormat from "react-number-format";
import { API } from "../../helpers/api";

declare const window: any;
const CartItem = (props: any) => {
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const [editItem, setEditItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qty, setQty] = useState<any>(props.i.qty);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const user_token = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("user_id");

  const editCart = async (item_id: any, qty: any) => {
    setLoading(true);
    axios
      .put(
        `${API}/cart/${user_id}/${item_id}`,
        {
          qty: qty,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: user_token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setEditItem(false);
        props.resetCoupon();
        props.refreshItem();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const deleteCart = async (item_id: any) => {
    setLoading(true);
    axios
      .delete(
        `${API}/cart/${user_id}/${item_id}`,

        {
          headers: {
            "Content-Type": "application/json",
            token: user_token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setEditItem(false);
        props.refreshItem();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonItem key={props.i.itemid.toString()}>
        <IonThumbnail slot="start">
          <IonImg
            style={{ cursor: "pointer" }}
            onClick={() => props.history.push("/product/" + props.i.itemid)}
            src={isChrome ? props.i.pic_url : props.i.pic_url_ios}
          />
        </IonThumbnail>
        <IonLabel
          className="ion-text-wrap"
          onClick={() => props.history.push("/product/" + props.i.itemid)}
        >
          <IonText
            style={{ fontSize: 12, wordWrap: "break-word", cursor: "pointer" }}
          >
            {props.i.online_name}
          </IonText>
          <br />
          <IonText slot="end" color="success" style={{ fontWeight: "bold" }}>
            {props.i.qty} x &nbsp;
            <NumberFormat
              value={props.i.price}
              displayType={"text"}
              thousandSeparator={true}
              // prefix={"Rp. "}
            />
          </IonText>
          <br />
          <IonText style={{ fontSize: 10 }}>
            Subtotal :
            <NumberFormat
              value={props.i.subtotal}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp. "}
            />
          </IonText>
          {parseInt(props.i.subtotal_for_discount) === 0 && (
            <div
              style={{
                flex: 1,
                borderRadius: 8,
                backgroundColor: "#fad7dd",
                textAlign: "center",
              }}
            >
              <IonText
                style={{
                  fontSize: 10,
                  color: "#e63757",
                }}
              >
                NO DISCOUNT
              </IonText>
            </div>
          )}
        </IonLabel>
        <div>
          <IonIcon
            style={{
              color: "#1100BB",
              fontSize: 25,
              marginHorizontal: 5,
              cursor: "pointer",
            }}
            onClick={() => {
              if (
                Number(props.i.qty) < Number(props.i.maxOrder) ||
                Number(props.i.maxOrder) === 0
              ) {
                if (Number(props.i.qty) === Number(props.i.stock)) {
                  setToastMsg("Stock tersisa " + Number(props.i.stock));
                  setShowToast(true);
                } else {
                  Number(props.i.qty) < Number(props.i.stock) &&
                    editCart(props.i.itemid, Number(props.i.qty) + 1);
                }
              } else {
                setToastMsg("Batas Pembelian " + Number(props.i.maxOrder));
                setShowToast(true);
              }
            }}
            icon={addCircle}
          />
          <br />
          {Number(props.i.qty) > 1 ? (
            <IonIcon
              style={{
                color: "#1100BB",
                fontSize: 25,
                marginHorizontal: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                Number(props.i.qty) > 0 &&
                  editCart(props.i.itemid, Number(props.i.qty) - 1);
              }}
              icon={removeCircle}
            />
          ) : (
            <IonIcon
              style={{
                color: "#ddd",
                fontSize: 25,
                marginHorizontal: 5,
              }}
              icon={removeCircle}
            />
          )}
          <br />
          <IonIcon
            style={{
              color: "#1100BB",
              fontSize: 25,
              marginHorizontal: 5,
              cursor: "pointer",
            }}
            onClick={() => {
              setQty(props.i.qty);
              setEditItem(true);
            }}
            icon={createOutline}
          />
        </div>
        <IonIcon
          style={{
            color: "#DC3545",
            fontSize: 25,
            marginHorizontal: 5,
            cursor: "pointer",
          }}
          icon={trashOutline}
          onClick={() => deleteCart(props.i.itemid)}
          slot="end"
        />
      </IonItem>

      <IonModal
        isOpen={editItem}
        cssClass="modal-sheet"
        swipeToClose
        backdropDismiss
      >
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton onClick={() => setEditItem(false)}>
              <IonIcon slot="icon-only" color="grey" name="close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonList>
          <IonItem lines="none">
            <IonGrid>
              <IonRow className="ion-justify-content-center ion-align-items-center">
                <IonButton
                  color="primary"
                  disabled={Number(qty) === 0}
                  onClick={() => Number(qty) > 0 && setQty(Number(qty) - 1)}
                >
                  -
                </IonButton>
                <IonText
                  style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    marginLeft: 16,
                    marginRight: 16,
                  }}
                >
                  {qty}
                </IonText>
                <IonButton
                  color="primary"
                  disabled={Number(qty) === Number(props.i.stock)}
                  onClick={() => {
                    if (
                      Number(qty) < Number(props.i.maxOrder) ||
                      Number(props.i.maxOrder) === 0
                    ) {
                      Number(qty) < Number(props.i.stock) &&
                        setQty(Number(qty) + 1);
                    } else {
                      setToastMsg(
                        "Batas Pembelian " + Number(props.i.maxOrder)
                      );
                      setShowToast(true);
                    }
                  }}
                >
                  +
                </IonButton>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
        <div className="ion-padding">
          {Number(qty) > 0 ? (
            <IonButton
              disabled={loading}
              color="primary"
              expand="block"
              onClick={() => editCart(props.i.itemid, qty)}
            >
              {loading ? <IonSpinner name="dots" /> : "SIMPAN"}
            </IonButton>
          ) : (
            <IonButton
              disabled={loading}
              color="danger"
              expand="block"
              onClick={() => deleteCart(props.i.itemid)}
            >
              {loading ? <IonSpinner name="dots" /> : "HAPUS"}
            </IonButton>
          )}
        </div>
      </IonModal>
    </>
  );
};

export default memo(CartItem);
