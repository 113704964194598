import React, { useContext } from "react";
import { IonTabBar, IonTabButton, IonLabel, IonBadge } from "@ionic/react";
import * as Icon from "react-feather";
import { AppContext } from "./contexts/AppContext";

const MainTabs: React.FC = () => {
  const user_id = localStorage.getItem("user_id");
  const [cartContext, setCartContext] = useContext(AppContext);

  return (
    <IonTabBar slot="bottom">
      <IonTabButton tab="home" href="/home">
        <Icon.Home />
        <IonLabel style={{ fontSize: 9 }}>Home</IonLabel>
      </IonTabButton>
      <IonTabButton tab="shopplist" href="/shoplist">
        <Icon.ShoppingBag />
        <IonLabel style={{ fontSize: 9 }}>Shopplist</IonLabel>
      </IonTabButton>
      {/* <IonTabButton tab="feed" href="/feed">
        <Icon.Copy />
        <IonLabel style={{ fontSize: 9 }}>Feed</IonLabel>
      </IonTabButton> */}
      <IonTabButton tab="cart" href="/cart">
        <span style={{ position: "relative" }}>
          <Icon.ShoppingCart />
          {cartContext.item_count > 0 && (
            <IonBadge color="warning" className="button-badge">
              {cartContext.item_count}
            </IonBadge>
          )}
        </span>
        <IonLabel style={{ fontSize: 9 }}>Cart</IonLabel>
      </IonTabButton>
      {user_id ? (
        <IonTabButton tab="user" href="/user">
          <Icon.User />
          <IonLabel style={{ fontSize: 9 }}>User</IonLabel>
        </IonTabButton>
      ) : (
        <IonTabButton tab="user" href="/login">
          <Icon.User />
          <IonLabel style={{ fontSize: 9 }}>Login</IonLabel>
        </IonTabButton>
      )}
    </IonTabBar>
  );
};

export default MainTabs;
