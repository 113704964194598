import {
  IonButtons,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonBackButton,
  IonSlides,
  IonSlide,
  IonGrid,
  IonContent,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
} from "@ionic/react";
import { arrowUpOutline } from "ionicons/icons";
import React, { memo, useState, useRef } from "react";
import ListTransaction from "./ListTransaction";
import "../Page.css";

const TransactionTab = (props: any) => {
  const contentRef = useRef(null);
  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current.scrollToTop("300");
  };
  const slider = useRef<HTMLIonSlidesElement>(null);
  const [value, setValue] = useState<any>(props.match.params.val);
  const slideOpts = {
    initialSlide: props.match.params.val,
    speed: 400,
    loop: false,
    pagination: {
      el: null,
    },
  };

  const handleSegmentChange = (e: any) => {
    setValue(e.detail.value);
    slider.current!.slideTo(e.detail.value);
  };

  const handleSlideChange = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    setValue("" + index);
  };

  return (
    <IonPage id="transactiontab">
      <IonToolbar style={{ paddingTop: 10 }}>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/user" />
        </IonButtons>
        <IonTitle>Belanja</IonTitle>
      </IonToolbar>
      <IonToolbar>
        <IonSegment
          value={value}
          mode="md"
          scrollable
          onIonChange={(e) => handleSegmentChange(e)}
        >
          <IonSegmentButton value="0">
            <IonLabel>Baru</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="1">
            <IonLabel>Disiapkan</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="2">
            <IonLabel>Dikirim</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="3">
            <IonLabel>Diterima</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="4">
            <IonLabel>Selesai</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="5">
            <IonLabel>Semua</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="6">
            <IonLabel>Batal</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      <IonContent ref={contentRef} scrollEvents={true} fullscreen>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <IonSlides
                pager={true}
                options={slideOpts}
                onIonSlideDidChange={(e) => handleSlideChange(e)}
                ref={slider}
              >
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {value === "0" && (
                          <ListTransaction status="0,1,2" {...props} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {value === "1" && (
                          <ListTransaction status="3" {...props} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {value === "2" && (
                          <ListTransaction status="4" {...props} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {value === "3" && (
                          <ListTransaction status="5" {...props} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {value === "4" && (
                          <ListTransaction status="6,7" {...props} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {value === "5" && (
                          <ListTransaction status={null} {...props} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
                <IonSlide>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        {value === "6" && (
                          <ListTransaction status="8,9" {...props} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
              </IonSlides>
            </IonCol>
            <IonCol sizeLg="3" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton onClick={() => scrollToTop()}>
          <IonIcon icon={arrowUpOutline} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default memo(TransactionTab);
