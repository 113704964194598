import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonLoading,
  IonModal,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonList,
  IonItem,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState } from "react";
import { Img } from "react-image";
import { API } from "../helpers/api";
import MainTabs from "../MainTabs";
import "./Page.css";

const ClaimGaransi = (props: any) => {
  const [cekModal, setCekModal] = useState(false);
  const [progress, setProgress] = useState<any>(false);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [kodeGaransi, setKodeGaransi] = useState<any>("");
  const [dataGaransi, setDataGaransi] = useState<any>([]);
  const [shippings, setShippings] = useState<any>([]);
  const [shippingSelected, setShippingSelected] = useState<any>(null);
  const [shippingLoading, setShippingLoading] = useState<any>(false);
  const [shippingDetail, setShippingDetail] = useState<any>([]);

  const addGaransi = async (code: any) => {
    setProgress(true);
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");

    axios
      .post(
        `${API}/user/${user_id}/warranty/${code}/claim`,
        qs.stringify({
          name: name,
          addr: `${shippingDetail.shipping_address},${shippingDetail.subdis_name},${shippingDetail.city_name},${shippingDetail.prov_name},${shippingDetail.zip_code}`,
        }),
        {
          headers: {
            token: user_token,
          },
        }
      )
      .then((res) => {
        setProgress(false);
        setDataGaransi(res.data);
        setCekModal(true);
      })
      .catch(() => {
        setProgress(false);
      });
  };

  const getShippings = () => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    setShippingLoading(true);
    axios
      .get(`${API}/shippings/${user_id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          token: user_token,
        },
      })
      .then((res) => {
        setShippingLoading(false);
        setShippings(res.data);
        setShippingSelected(res.data[0].shipping_id);
        setShippingDetail(res.data[0]);
      })
      .catch(() => {
        setShippingLoading(false);
        setShippings([]);
      });
  };

  const getShippingDetail = (id: number) => {
    const user_token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .get(`${API}/shipping/${user_id}/${id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          token: user_token,
        },
      })
      .then((res) => {
        setShippingDetail(res.data[0]);
      })
      .catch(() => {
        setShippings([]);
      });
  };

  useIonViewWillEnter(() => {
    getShippings();
  });

  return (
    <IonPage id="claimgaransi">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>Klaim Garansi</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <IonGrid
                style={{
                  backgroundColor: "#f7ec62",
                  flexDirection: "row",
                  marginTop: 10,
                  marginLeft: 15,
                  marginRight: 15,
                }}
              >
                <IonRow>
                  <IonCol>
                    <IonText
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        flex: 1,
                      }}
                    >
                      <strong>Klaim Kode Garansi Harnic</strong>
                      <br />
                      <small>
                        Anda dapat mengklaim kode garansi khusus produk merek
                        HARNIC dibawah ini, untuk mendapatkan diskon hingga 20 %
                      </small>
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                  marginLeft: 15,
                  marginRight: 15,
                }}
              >
                <IonRow>
                  <IonCol>
                    <Img
                      // key={index}
                      container={(children) => {
                        return <div className="ratio-square">{children}</div>;
                      }}
                      src={require("../assets/images/contoh_garansi.jpg")}
                      loader={
                        <IonSkeletonText
                          animated
                          className="ratio-square"
                          style={{ marginTop: -15 }}
                        />
                      }
                      unloader={
                        <img
                          src={require("../assets/images/default.png")}
                          alt="no-pic"
                          width="100%"
                        />
                      }
                      width="100%"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonList lines="full" className="ion-no-margin ion-padding">
                <IonItem>
                  <IonInput
                    placeholder="Nama"
                    type="text"
                    value={name}
                    onIonChange={(e) => {
                      setName(e.detail.value);
                    }}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel>Alamat</IonLabel>
                  <IonSelect
                    value={shippingSelected}
                    onIonChange={(e) => {
                      if (e.detail.value === 0) {
                        props.history.push("/user/shipping/create");
                      } else {
                        setShippingSelected(e.detail.value);
                        getShippingDetail(e.detail.value);
                      }
                    }}
                    interface="action-sheet"
                  >
                    <IonSelectOption key="new_addr" value={0}>
                      + Tambah Baru
                    </IonSelectOption>
                    {shippings.map((i: any) => (
                      <IonSelectOption
                        key={i.shipping_id + i.shipping_name}
                        value={i.shipping_id}
                      >
                        {i.shipping_name}, {i.shipping_address}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonInput
                    placeholder="Input Kode"
                    type="text"
                    value={kodeGaransi}
                    onIonChange={(e) => {
                      setKodeGaransi(e.detail.value);
                    }}
                  />
                </IonItem>
              </IonList>
              <div className="ion-padding">
                <IonButton
                  disabled={!name || !kodeGaransi ? true : false}
                  onClick={() => {
                    // setCekModal(true);
                    addGaransi(kodeGaransi);
                  }}
                  expand="block"
                >
                  Klaim Voucher
                </IonButton>
              </div>
            </IonCol>
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonModal
        isOpen={cekModal}
        cssClass="modal-sheet-claimgaransi"
        onWillDismiss={() => setCekModal(false)}
      >
        <IonToolbar style={{ paddingTop: 10 }}>
          <IonButtons slot="primary">
            <IonButton onClick={() => setCekModal(false)}>
              <IonIcon slot="icon-only" color="grey" name="close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {dataGaransi.status === false ? (
          <IonCol size="12">
            <div style={{ marginLeft: 20 }} className="ion-activable">
              <strong style={{ fontSize: 22, color: "#ee4036" }}>
                {dataGaransi.message}
              </strong>
            </div>
            <div style={{ marginTop: 10, marginLeft: 20, marginRight: 20 }}>
              <IonImg
                style={{ height: 230, width: "100%" }}
                src={require("../assets/images/contoh_garansi.jpg")}
              />
            </div>
            <div
              style={{
                backgroundColor: "#ffb0b0",
                flexDirection: "row",
                marginTop: 10,
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <IonText
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  flex: 1,
                  color: "#f4575c",
                }}
              >
                <small>
                  Pastikan kode yang anda masukkan sesuai dengan foto diatas,
                  kemudian coba lagi!
                </small>
              </IonText>
            </div>
            <div style={{ marginTop: 10 }}>
              <IonButton
                onClick={() => {
                  setCekModal(false);
                  setKodeGaransi("");
                }}
                color="dark"
                expand="block"
              >
                Coba Lagi
              </IonButton>
            </div>
          </IonCol>
        ) : (
          <IonCol size="12">
            <div style={{ marginLeft: 20 }} className="ion-activatable">
              <strong style={{ fontSize: 22, color: "#006838" }}>
                {dataGaransi.message}
              </strong>
            </div>
            {dataGaransi.data && (
              <div
                className="ion-activable"
                style={{ marginTop: 30 }}
                key={dataGaransi.data.warranty.warranty_id}
              >
                <IonCard
                  className="m-16"
                  style={{ backgroundColor: "#faaf40" }}
                >
                  <IonRippleEffect></IonRippleEffect>
                  <IonCardHeader>
                    <IonCardTitle style={{ fontSize: 20, textAlign: "center" }}>
                      {dataGaransi.data.vc_code}
                    </IonCardTitle>
                    <IonCardTitle style={{ fontSize: 13, textAlign: "center" }}>
                      {dataGaransi.data.warranty.product_name}
                    </IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <IonButton
                onClick={() => {
                  props.history.push("/user/voucher");
                  setCekModal(false);
                  setKodeGaransi("");
                }}
                expand="block"
              >
                Lihat Voucher Saya
              </IonButton>
            </div>
          </IonCol>
        )}
      </IonModal>
      <MainTabs />
    </IonPage>
  );
};

export default memo(ClaimGaransi);
