import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../helpers/api";
import { isValid } from "../helpers/passwordValidation";
import "./Page.css";

interface ResetPasswordProps extends RouteComponentProps<{ code: any }> {}

const ResetPassword: React.FC<ResetPasswordProps> = ({ match, history }) => {
  const [progress, setProgress] = useState<any>(false);
  const [password, setPassword] = useState<any>(null);
  const [confpassword, setConfPassword] = useState<any>(null);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const reset = (code: any) => {
    setProgress(true);
    axios
      .post(
        `${API}/user/reset_password/${code}`,
        qs.stringify({ password: confpassword }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setProgress(false);
        if (res.data.success) {
          setToastMsg(res.data.message);
          setShowToast(true);
          history.push("/login");
        } else {
          setToastMsg(res.data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        setProgress(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  return (
    <IonPage id="resetPassword">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={progress}
        onDidDismiss={() => setProgress(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="4" className="p-0 m-0 justify-content-center ">
              <div
                style={{
                  marginTop: 60,
                  paddingBottom: 16,
                  paddingLeft: 32,
                  paddingRight: 32,
                }}
                className="ion-text-center"
              >
                <img
                  alt="a"
                  width="50%"
                  src={require(`../assets/images/harnic.svg`)}
                />
              </div>
              <IonList lines="full" className="ion-no-margin ion-padding">
                <>
                  <IonItem>
                    <IonLabel position="floating">Password Baru</IonLabel>
                    <IonInput
                      placeholder="********"
                      type="password"
                      value={password}
                      onIonChange={(e) => {
                        setPassword(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Ulangi Password</IonLabel>
                    <IonInput
                      placeholder="********"
                      type="password"
                      value={confpassword}
                      onIonChange={(e) => {
                        setConfPassword(e.detail.value);
                      }}
                    ></IonInput>
                  </IonItem>
                  {isValid(password, confpassword) === false && (
                    <IonText
                      style={{
                        color: "#DC3545",
                        fontWeight: "bold",
                        marginLeft: 15,
                        fontSize: 13,
                      }}
                    >
                      Password Tidak Sama!
                    </IonText>
                  )}
                </>
              </IonList>

              <div className="ion-padding">
                <IonButton
                  disabled={
                    !password ||
                    !confpassword ||
                    isValid(password, confpassword) === false
                      ? true
                      : false
                  }
                  expand="block"
                  onClick={() => reset(match.params.code)}
                  type="submit"
                  className="ion-no-margin"
                >
                  Simpan
                </IonButton>
              </div>
            </IonCol>
            <IonCol sizeLg="4" sizeXs="auto" className="p-0 m-0" />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default memo(ResetPassword);
