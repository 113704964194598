export const transactionStep = (status: any) => {
  let step = 0;
  switch (Number(status)) {
    case 0:
      step = 0;
      break;
    case 1:
      step = 1;
      break;
    case 2:
      step = 2;
      break;
    case 3:
      step = 2;
      break;
    case 4:
      step = 3;
      break;
    case 5:
      step = 4;
      break;
    case 6:
      step = 5;
      break;
    case 7:
      step = 5;
      break;
    default:
      step = 0;
      break;
  }

  return step;
};
