import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonTextarea,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo, useState } from "react";
import PageComponent from "../components/PageComponent";
import { API } from "../helpers/api";
import "./Page.css";

interface PageProps {
  match: any;
  history: any;
}
declare const window: any;

const PageContent: React.FC<PageProps> = ({ match, history }) => {
  const [loading, setLoading] = useState<any>(false);
  const [name, setName] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [attachment, setAttachment] = useState<any>("");
  const [content, setContent] = useState<any>([]);
  const [showToast, setShowToast] = useState<any>(null);
  const [toastMsg, setToastMsg] = useState<any>(null);

  const sendFeedback = () => {
    setLoading(true);
    axios
      .post(
        `${API}/feedback`,
        qs.stringify({
          user_name: name,
          user_phone: phone,
          user_message: message,
          user_attachment: attachment || "",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setName("");
        setPhone("");
        setMessage("");
        // setAttachment("");
        setToastMsg(res.data.message);
        setShowToast(true);
      })
      .catch((err) => {
        setLoading(false);
        setToastMsg(err);
        setShowToast(true);
      });
  };

  const handleFile = (e: any) => {
    setAttachment(e.target.files[0].name);
  };

  const getContent = async (title: any) => {
    await axios
      .get(`${API}/info/getContent/${title}`)
      .then((response) => response.data)
      .then((data) => {
        setContent(data);
      })
      .catch((err) => {
        console.log("eror");
      });
  };

  useIonViewWillEnter(() => {
    getContent(match.params.title);
  }, []);

  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMsg}
        color="dark"
        position="top"
        duration={2000}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              {content && content.page_content && (
                <PageComponent info={content} />
              )}
              {content.page_url === "contact-us" && (
                <IonList>
                  <IonItem>
                    <IonLabel position="floating">Nama</IonLabel>
                    <IonInput
                      value={name}
                      onIonChange={(e) => {
                        setName(e.detail.value);
                      }}
                      type="text"
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Nomor HP</IonLabel>
                    <IonInput
                      value={phone}
                      onIonChange={(e) => {
                        setPhone(e.detail.value);
                      }}
                      type="number"
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Pesan</IonLabel>
                    <IonTextarea
                      value={message}
                      onIonChange={(e) => {
                        setMessage(e.detail.value);
                      }}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">File/Attachment</IonLabel>
                    <input
                      className="form-control"
                      style={{ marginLeft: 3, width: "95%", cursor: "pointer" }}
                      type="file"
                      // value={attachment}
                      onChange={(e) => {
                        handleFile(e);
                        // setAttachment(e.target.value);
                        // console.log(e.target.value);
                      }}
                    />
                  </IonItem>
                  <div className="ion-padding">
                    <IonButton
                      disabled={name === "" || phone === "" || message === ""}
                      type="submit"
                      expand="block"
                      className="ion-no-margin"
                      onClick={() => sendFeedback()}
                    >
                      Kirim
                    </IonButton>
                  </div>
                </IonList>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default memo(PageContent);
