import { RefresherEventDetail } from "@ionic/core";
import {
    IonButton,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSkeletonText,
    IonText,
    isPlatform,
    useIonViewDidEnter,
} from "@ionic/react";
import axios from "axios";
import {
    chevronDownOutline,
    chevronUpOutline,
    notifications,
} from "ionicons/icons";
import React, {
    lazy,
    memo,
    Suspense,
    useContext,
    useRef,
    useState,
} from "react";
import * as Icon from "react-feather";
import { AppContext, KeywordContext } from "../../contexts/AppContext";
import { APIV3 } from "../../helpers/api";
import MainTabs from "../../MainTabs";
import "../Page.css";

const Banners = lazy(() => import("../../components/home/v3/banners/index"));
const Icons = lazy(() => import("../../components/home/v3/icons/index"));
const Categories = lazy(
    () => import("../../components/home/v3/categories/index")
);
const Panels = lazy(() => import("../../components/home/v3/panels/index"));
const Footer = lazy(() => import("../../components/home/v3/footer/index"));

declare const window: any;

const Home = (props: any) => {
    const contentRef = useRef(null);
    const [cartContext, setCartContext] = useContext(AppContext);
    const [keyContext, setKeyContext] = useContext(KeywordContext);
    const scrollToTop = () => {
        // @ts-ignore
        contentRef.current.scrollToTop("300");
    };

    const [config, setConfig] = useState<any>([]);
    const [banners, setBanners] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [panels, setPanels] = useState<any>([]);

    function onRefresh(event: CustomEvent<RefresherEventDetail>) {
        getConfig().then(() => event.detail.complete());
    }

    const user_id = localStorage.getItem("user_id");

    const getConfig = async () => {
        const append_url = user_id ? "?user_id=" + user_id : "";
        await axios
            .get(`${APIV3}/configs${append_url}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setConfig(res.data.data);
                    setBanners(res.data.data.banners);
                    setCategories(res.data.data.categories);
                    setPanels(res.data.data.panels);
                    // if (user_id) {
                    //   setCartContext(res.data.data.cart);
                    // }
                    setKeyContext(res.data.data.keywords);
                } else {
                    console.log("gagal");
                }
            });
    };

    const desktop = isPlatform("desktop");

    useIonViewDidEnter(() => {
        getConfig();
    }, []);

    return (
        <IonPage id="home">
            <IonContent ref={contentRef} scrollEvents={true} fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
                    <IonRefresherContent
                        pullingIcon={chevronDownOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="lines"
                    ></IonRefresherContent>
                </IonRefresher>
                <IonGrid className="p-0 m-0">
                    <IonRow className="p-0 m-0">
                        <IonCol sizeLg="3" sizeXs="auto" className="p-0 m-0" />
                        <IonCol
                            sizeLg="6"
                            className="p-0 m-0 justify-content-center"
                        >
                            <Suspense
                                fallback={
                                    <IonSkeletonText
                                        animated
                                        style={{ height: 200 }}
                                    />
                                }
                            >
                                <Banners
                                    banners={banners}
                                    history={props.history}
                                />
                            </Suspense>
                            <Suspense
                                fallback={
                                    <IonSkeletonText
                                        animated
                                        style={{ height: 200 }}
                                    />
                                }
                            >
                                <Icons history={props.history} />
                            </Suspense>
                            <Suspense
                                fallback={
                                    <IonSkeletonText
                                        animated
                                        style={{ height: 200 }}
                                    />
                                }
                            >
                                <Categories
                                    categories={categories}
                                    history={props.history}
                                />
                            </Suspense>
                            <Suspense
                                fallback={
                                    <IonSkeletonText
                                        animated
                                        style={{ height: 200 }}
                                    />
                                }
                            >
                                <Panels
                                    panels={panels}
                                    history={props.history}
                                />
                            </Suspense>
                            <Suspense
                                fallback={
                                    <IonSkeletonText
                                        animated
                                        style={{ height: 200 }}
                                    />
                                }
                            >
                                <Footer />
                            </Suspense>
                        </IonCol>
                        <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
                    </IonRow>
                </IonGrid>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={() => scrollToTop()}>
                        <IonIcon icon={chevronUpOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>

            {!user_id && (
                <IonButton
                    style={{
                        position: "absolute",
                        bottom: 60,
                        left: desktop ? "40%" : "30%",
                        right: desktop ? "40%" : "30%",
                    }}
                    className="fab-h"
                    onClick={() => props.history.push("/auth")}
                    color="primary"
                >
                    MASUK/DAFTAR
                </IonButton>
            )}
            <IonButton
                color="light"
                expand="block"
                onClick={() => props.history.push("/search")}
                type="submit"
                style={{
                    position: "absolute",
                    marginTop: 10,
                    left: desktop ? "25%" : "2%",
                    right: desktop ? "30%" : "15%",
                    paddingBottom: 5,
                }}
                className="ion-no-margin"
            >
                <div
                    style={{
                        flex: 1,
                        textAlign: "left",
                    }}
                >
                    <IonIcon icon="search-outline" />
                    &nbsp;
                    <IonText style={{ color: "#A0A1A3" }}>Cari Produk</IonText>
                </div>
            </IonButton>
            <IonIcon
                icon={notifications}
                style={{
                    position: "absolute",
                    top: 12,
                    left: desktop ? "71%" : "87%",
                    cursor: "pointer",
                }}
                color="light"
                size="large"
                onClick={() => {
                    props.history.push("/user/notifications");
                }}
            />
            <MainTabs />
        </IonPage>
    );
};

export default memo(Home);
