import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonText,
} from "@ionic/react";
import * as Icon from "react-feather";
import ReactCreditCard from "@repay/react-credit-card";
import "@repay/react-credit-card/dist/react-credit-card.css";
import { FOCUS_TYPE } from "@repay/react-credit-card/dist/ReactCreditCard";
import axios from "axios";
import qs from "qs";
import React, { memo } from "react";
import { RouteComponentProps } from "react-router";
import { API } from "../../helpers/api";
import MainTabs from "../../MainTabs";
import "../Page.css";

interface AddCreditCardProps extends RouteComponentProps<{ props: any }> {}

const AddCreditCard: React.FC<AddCreditCardProps> = ({ match, history }) => {
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [values, setValues] = React.useState({
    name: "",
    number: "",
    expiration: "",
  });

  const handleChange = React.useCallback(
    (event) => {
      const { name, value } = event.target;
      setValues((v) => ({ ...v, [name]: value }));
    },
    [setValues]
  );

  const [focused, setFocus] = React.useState<FOCUS_TYPE | undefined>(undefined);
  const handleFocus = React.useCallback(
    (event) => setFocus(event.target.name as FOCUS_TYPE),
    [setFocus]
  );
  const handleBlur = React.useCallback(() => setFocus(undefined), [setFocus]);

  const saveCC = async (card_num: any, card_holder: any, card_exp: any) => {
    const user_id = localStorage.getItem("user_id");
    const user_token = localStorage.getItem("user_token");
    setProcessing(true);
    await axios
      .post(
        `${API}/user/${user_id}/card`,
        qs.stringify({
          card_num: card_num,
          card_holder: card_holder,
          card_exp: card_exp,
        }),
        {
          headers: {
            token: user_token,
          },
        }
      )
      .then((res) => {
        setProcessing(false);
        history.push("/user/creditcard");
      })
      .catch((e) => {
        setProcessing(false);
      });
  };

  useIonViewWillEnter(() => {
    //   getProvince();
  }, []);

  return (
    <IonPage id="addcreditcard">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/user" />
          </IonButtons>
          <IonTitle>Tambah Kartu Kredit</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="p-0 m-0">
          <IonRow className="p-0 m-0">
            <IonCol sizeLg="2" sizeXs="auto" className="p-0 m-0" />
            <IonCol sizeLg="8" className="p-0 m-0 justify-content-center ">
              <div style={{ padding: 16, width: 20 }}>
                <ReactCreditCard {...values} focused={focused} />
              </div>
              <IonList>
                <IonItem>
                  <IonInput
                    type="number"
                    name="number"
                    value={values.number}
                    placeholder="16 digit nomor kartu"
                    onIonChange={handleChange}
                    onIonFocus={handleFocus}
                    onIonBlur={handleBlur}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonInput
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Nama pemegang kartu"
                    onIonChange={handleChange}
                    onIonFocus={handleFocus}
                    onIonBlur={handleBlur}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonInput
                    type="number"
                    min="0"
                    max="4"
                    name="expiration"
                    value={values.expiration}
                    placeholder="Kadaluarsa MMYY"
                    onIonChange={handleChange}
                    onIonFocus={handleFocus}
                    onIonBlur={handleBlur}
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonList>
              <div
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 4,
                  paddingTop: 4,
                }}
              >
                <IonButton
                  disabled={
                    !values.number ||
                    !values.expiration ||
                    !values.name ||
                    processing
                  }
                  onClick={() =>
                    saveCC(
                      values.number,
                      values.name,
                      values.expiration.substring(0, 2) +
                        values.expiration.substring(2, 4)
                    )
                  }
                  className="fab-h"
                  expand="block"
                >
                  {processing ? (
                    <IonSpinner
                      name="circular"
                      style={{ alignSelf: "center" }}
                    />
                  ) : (
                    <>
                      <Icon.Save />
                      &nbsp;&nbsp;
                      <IonText>SIMPAN</IonText>
                    </>
                  )}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MainTabs />
    </IonPage>
  );
};

export default memo(AddCreditCard);
