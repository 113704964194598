import {
  IonCard,
  IonImg,
  IonRippleEffect,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import axios from "axios";
import qs from "qs";
import React, { memo } from "react";
import { Img } from "react-image";
import NumberFormat from "react-number-format";
import { API, version } from "../../helpers/api";
import BuyButtonCard from "../BuyButtonCard";

declare const window: any;

const CartItemRecomend = (props: any) => {
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const AddToCart = (item_id: any, qty: any, note: any, app_version: any) => {
    const token = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("user_id");
    axios
      .post(
        `${API}/cart/${user_id}`,
        qs.stringify({
          item_id: item_id,
          qty: qty,
          note: note,
          app_version: version,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            token: token,
          },
        }
      )
      .then((res) => {
        props.refreshItem();
      })
      .catch(() => {});
  };

  return (
    <div className="ion-activatable ripple-parent">
      <IonCard
        className="m-2"
        // onClick={() => props.history.push("/product/" + props.i.itemid)}
      >
        <IonRippleEffect></IonRippleEffect>
        {props.i.stock_loc === "0" && (
          <div
            className="ion-justify-content-center ion-align-items-center"
            style={{
              position: "absolute",
              backgroundColor: "rgb(0,0,0,0.3)",
              width: "100%",
              height: "100%",
              zIndex: 3,
              paddingTop: "30%",
            }}
          >
            <IonImg src={require("../../assets/images/habis.png")} />
          </div>
        )}
        <Img
          onClick={() => props.history.push("/product/" + props.i.itemid)}
          container={(children) => {
            return <div className="ratio-square">{children}</div>;
          }}
          src={isChrome ? props.i.pic_url : props.i.pic_url_ios}
          loader={
            <>
              {props.i.stock_loc === "0" && (
                <div
                  className="ion-justify-content-center ion-align-items-center"
                  style={{
                    position: "absolute",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    width: "100%",
                    height: "100%",
                    zIndex: 3,
                    paddingTop: "30%",
                    marginTop: 15,
                  }}
                >
                  {/* <IonImg src={require("../../assets/images/habis.png")} /> */}
                </div>
              )}
              <IonSkeletonText
                animated
                className="ratio-square"
                style={{ marginTop: -15 }}
              />
            </>
          }
          unloader={
            <img
              src={require("../../assets/images/default.png")}
              alt="no-pic"
              width="100%"
            />
          }
        />
        {props.i.info && <div className="ribbon-product">{props.i.info}</div>}
        <div
          className="product-caption"
          onClick={() => props.history.push("/product/" + props.i.itemid)}
        >
          {/* <p className="product-name">{props.i.online_name}</p>
          <p
            className="product-name"
            style={{ marginTop: 10, marginBottom: -10 }}
          >
            Review ({props.i.comment_count || 0})
          </p> */}
          <span className="price-regular">
            <NumberFormat
              value={props.i.price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp. "}
            />
          </span>
        </div>
        <div
          className="price"
          style={{ paddingLeft: 8, paddingRight: 8, height: 20 }}
        >
          {props.i.price_old > 0 && (
            <p style={{ marginTop: 0, marginBottom: -5 }}>
              <NumberFormat
                value={props.i.price_old}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rp. "}
                style={{
                  textDecoration: "line-through",
                  fontSize: 11,
                }}
              />
            </p>
          )}
        </div>
        <BuyButtonCard
          item={props.i}
          style={{
            flex: 1,
            paddingBottom: 5,
            textAlign: "center",
          }}
        />
      </IonCard>
    </div>
  );
};

export default memo(CartItemRecomend);
